import medusa from '@/assets/js/observer';

export default {
  watch: {
    currentPost(val) {
      if (val) {
        if (medusa.ref && medusa.ref.idList.includes('lazyload')) {
          medusa.ref.clearTarget('lazyload');
        }

        this.lazyObserve(this.$el);
      }
    },
  },
  mounted() {
    medusa.ref.addTarget({
      id: 'lazyload',
      threshold: 0.0,
      nodes: [],
      mode: 'once',
      callback: this.decode,
      autoremove: false,
      // offsets: `0px 0px ${window.innerHeight * 2}px 0px`,
    });
    this.$bus.$on('lazyload', () => {
      this.$nextTick(() => {
        if (medusa.ref && medusa.ref.idList.includes('lazyload')) {
          medusa.ref.clearTarget('lazyload');
        }

        this.lazyObserve(this.$el);
      });
    });
  },
  methods: {
    lazyObserve(el) {
      this.$nextTick(() => {
        const lazyLoadImgs = Array.from(
          el.querySelectorAll('figure.picture[data-lazy="false"] img.image'),
        );
        medusa.ref.pushToTarget('lazyload', lazyLoadImgs);
      });
    },
    decode(entry) {
      const { target, isIntersecting } = entry;
      if (isIntersecting) {
        target.src = target.dataset.src;

        target
          .decode()
          .then(() => {
            target.closest('figure').dataset.lazy = true;
          })
          .catch(() => {
            target.closest('figure').dataset.lazy = true;
          });
      }
    },
  },
};
