<template>
  <div
    v-if="product.price_html"
    :class="['price', { [`price--${theme}`]: theme }]"
  >
    <span v-html="product.price_html" />
  </div>
  <!--
    Per personalizzare prezzo
    <span>
    <del
      v-if="product.sale_price"
      v-html="parseCurrency(product.regular_price)"
    />
    <component
      :is="product.sale_price ? 'ins' : 'span'"
      v-html="parseCurrency(product.price)"
    />
  </span> -->
</template>

<script>
import { mapGetters } from 'vuex';

import { parseCurrency } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {},
  props: {
    product: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_shop;
    },
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss" scoped>
  div /deep/ del + ins {
    padding-left: var(--spacer-m);
  }

  .price {
    &--inline {
      column-gap: var(--spacer-s);
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
    }
  }
</style>
