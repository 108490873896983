var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{attrs:{"x":"m"}},[_c('main',{class:[
      _vm.post.acf.page_template === 'custom' ? 'main--page-custom grid' : false,
    ]},[(_vm.post.acf.page_template === 'custom')?[_c('Wrapper',{staticClass:"typo--l",attrs:{"boxed":""}},[_c('Spacer',{attrs:{"bottom":"m"}},[_vm._v(" "+_vm._s(_vm.post.title.rendered)+" ")])],1)]:_vm._e(),_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:`section--${parentIndex}`,attrs:{"layout":_vm.layout(section),"columns":section.layout,"isProductStack":_vm.isBlockProduct(section)}},_vm._l((_vm.blocks(section)),function(block,index){return _c('Spacer',{key:index,class:[
          'block',
          `block--${block.acf_fc_layout}`,
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null,
          { [`block--span-${block.columns}`]: block.columns },
        ],attrs:{"x":section.layout === 'default' ? block.block_spacer : false}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",attrs:{"data":{ ...block, sectionLayout: section.layout }}})],1)}),1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }