<template>
  <div>
    <transition name="fade">
      <div
        v-if="showCartCalc"
        @click="$store.commit('CLOSE_CART')"
      />
    </transition>
    <div class="cart">
      <transition name="slide">
        <div v-if="showCartCalc">
          <Spacer
            all="m"
            class="cart__wrapper"
          >
            <div class="cart__header" >
              <Grid
                justify="space-between"
                align="start"
                col="4"
              >
              <Column
                size="2"
              >
                <div class="typo--m">
                  {{ $labels.cart_added_message }}
                </div>
                </Column>
                <WcMenu class="cart__count"></WcMenu>
              </Grid>
            </div>
            <div class="cart__content">
              <Spacer
                v-if="isCartEmpty"
                y="m"
                class="cart__empty-state"
              >
                <p>
                  {{ $labels.your_cart_empty }}
                </p>
              </Spacer>
             <ProductCartThumb :cartItem="addedProduct" theme="side" v-if="addedProduct"></ProductCartThumb>
            </div>
            <Flex
              v-if="!isCartEmpty"
              class="typo--m"
              dir="column"
              row-gap="m"
            >
                <Cta
                  theme="button-boxed-light"
                  :data="{
                    title: $labels.view_cart,
                    url: '/cart',
                    size: 's',
                  }"
                />
                <Cta
                  theme="button-boxed"
                  :data="{
                    title: $labels.checkout,
                    url: '/checkout',
                    size: 's',
                  }"
                />
            </Flex>
          </Spacer>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';

import { parseCurrency, unparseCurrency } from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import CartList from '@/components/wc/cart-list';
import ProductCartThumb from '@/components/thumb/product-cart';
import WcMenu from '@/components/wc/wc-menu';


export default {
  name: 'Cart',
  components: {
    Cta,
    CartList,
    ProductCartThumb,
    WcMenu
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings', 'setMiniCart']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.taxDisplayCart === 'excl') {
        return this.parseCurrency(
          this.unparseCurrency(this.cart.totals.subtotal),
        );
      }
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal)
          + this.unparseCurrency(this.cart.totals.subtotal_tax),
      );
    },
    showCartCalc() {
      if (this.$route.name === 'Cart') {
        return false;
      }
      if (this.showCart) {
        // disableBodyScroll(document.querySelector('html'));
        return true;
      }
      // clearAllBodyScrollLocks();
      return false;
    },
    addedProduct() {
      console.log(this.setMiniCart);
      return this.setMiniCart
    }
  },
  watch: {
    $route() {
      // to
      // to.name === 'Checkout' &&
      if (this.showCart) {
        this.$store.commit('CLOSE_CART');
      }
    },
    showCartCalc() {
      if (this.showCartCalc) {
        setTimeout(() => {
          this.$store.commit('CLOSE_CART');
        }, 5000);
      }
    }
  },
  async mounted() {
    this.$store.dispatch('getCart');
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease-in-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.cart {
  position: fixed;
  z-index: 100;
  top: 0px;
  right: 0;
  width: 100%;
  @include mq(s) {
    width: calc(50% + 6px);
  }
  @include mq(m) {
    width: calc(25% + 7px);
  }
}

.cart__wrapper {
  background: var(--white);
  // width: 100vw;
  height: fit-content;
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content 1fr min-content;
  row-gap: 0;
  -webkit-box-shadow: -6px 0px 14px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -6px 0px 14px 0px rgba(0, 0, 0, 0.1);
  padding-top: 0px !important;
}
.cart__count {
  cursor: pointer;
  justify-self: end;
}
.cart__header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.cart__content {
  margin-bottom: 10px;
  max-height: calc(var(--vh, 1vh) * 100 - 120px);
  overflow: auto;
}
.cart__content::-webkit-scrollbar {
  display: none;
}

.cart__list {
  overflow: scroll;
  line-height: $unit * 2;
}

.cart__item-right {
  height: 100%;
}

.cart__remove-item {
  cursor: pointer;
  transition: all 0.25s $ease-custom;
  display: block;
  line-height: $unit * 1.5;
}

.no-touchevents {
  .cart__remove-item {
    opacity: 0;
    pointer-events: none;
  }
}

.cart__item {
  &:hover {
    .cart__remove-item {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.checkout__button{
  margin-top: auto;
  // background: red;
}
</style>
