<template>
  <Spacer x="m">
    <main
      :class="[
        post.acf.page_template === 'custom' ? 'main--page-custom grid' : false,
      ]"
    >
      <template v-if="post.acf.page_template === 'custom'">
        <Wrapper
          class="typo--l"
          boxed
        >
          <Spacer bottom="m">
            {{ post.title.rendered }}
          </Spacer>
        </Wrapper>
      </template>
      <Section
        v-for="(section, parentIndex) in post.acf.sections"
        :key="parentIndex"
        :layout="layout(section)"
        :columns="section.layout"
        :class="`section--${parentIndex}`"
        :isProductStack="isBlockProduct(section)"
      >
        <Spacer
          v-for="(block, index) in blocks(section)"
          :key="index"
          :x="section.layout === 'default' ? block.block_spacer : false"
          :class="[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
            { [`block--span-${block.columns}`]: block.columns },
          ]"
        >
          <component
            :is="components.get(block.acf_fc_layout)"
            :data="{ ...block, sectionLayout: section.layout }"
          />
        </Spacer>
      </Section>
    </main>
  </Spacer>
</template>

<script>
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';

export default {
  name: 'Page',
  components: {
    Section,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  methods: {
    layout(section) {
      const {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      } = section;
      return {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      };
    },
    blocks(section) {
      const { layout } = section;
      let value = section.blocks;

      if (layout && layout !== 'default') {
        value = section.blocks_three_columns;
      }

      return value;
    },
    isBlockProduct(section) {
        let value = true;
        let blocks = section.blocks_four_columns?.length ? section.blocks_four_columns : section.blocks_three_columns?.length ? section.blocks_three_columns : section.blocks?.length ? section.blocks : undefined;
        
        if(blocks) {
          for (var i = 0; i < blocks.length; i++) {
            if (blocks[i].acf_fc_layout !== 'product') {
              value = false;
            }
          }
        }
        else {
          value = false;
        }
        return value;
      }
  },
};
</script>

<style lang="scss">
.main--page-custom {
  --columns: 1;

  @include mq(m) {
    --columns: 2;
  }
}
.block--span-two {
  @include mq(s) {
    grid-column: span 2;
  }
}

.block--span-three {
  @include mq(s) {
    grid-column: span 3;
  }
}

.block--marquee {
  width: 100vw;
  position: relative;
  left: calc(var(--spacer-m) * -1);
  overflow: hidden;
  padding: 0;
}
</style>
