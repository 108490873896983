import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        title: this.$labels.shipping_address,
        accordion: {
          open: false,
          toggle: false,
        },
        sendLabel: 'save',
      },
      groups: {
        shipping: {
          visible: true,
          fields: {
            first_name: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'first_name',
              rules: 'required',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'last_name',
              rules: 'required',
              size: 6,
            },
            company: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'company',
            },
            address_1: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'address_1',
              size: 6,
              rules: 'required',
            },
            address_2: {
              type: 'text',
              model: '',
              label: 'address_2_placeholder',
              placeholder: 'address_2',
              size: 6,
            },
            city: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'city',
              rules: 'required',
              size: 6,
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              rules: 'required',
              options: {},
              size: 6,
              fn: () => {
                this.setStates();
              },
            },
            state: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'state',
              rules: 'required',
              options: {},
              size: 6,
            },
            postcode: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'postcode',
              rules: 'required',
              size: 6,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      customerShipping: 'customer',
      geoData: 'geoData',
    }),
  },
  watch: {
    customerShipping: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0 && this.groups.shipping) {
          this.groups.shipping.fields.first_name.model = val.shipping.first_name ? val.shipping.first_name : '';
          this.groups.shipping.fields.last_name.model = val.shipping.last_name ? val.shipping.last_name : '';
          this.groups.shipping.fields.company.model = val.shipping.company ? val.shipping.company : '';
          this.groups.shipping.fields.address_1.model = val.shipping.address_1 ? val.shipping.address_1 : '';
          this.groups.shipping.fields.address_2.model = val.shipping.address_2 ? val.shipping.address_2 : '';
          this.groups.shipping.fields.country.model = val.shipping.country ? val.shipping.country : '';
          this.groups.shipping.fields.city.model = val.shipping.city ? val.shipping.city : '';
          this.groups.shipping.fields.state.model = val.shipping.state ? val.shipping.state : '';
          this.groups.shipping.fields.postcode.model = val.shipping.postcode ? val.shipping.postcode : '';
        }
      },
    },
  },
  methods: {
    async init() {
      this.countries = this.geoData.countries;
      this.states = this.geoData.states;

      Object.assign(this.groups.shipping.fields.country, { ...this.groups.shipping.fields.country, options: this.countries, model: this.customerShipping && this.customerShipping.shipping ? this.customerShipping.shipping.country && this.customerShipping.shipping.country !== '' ? this.customerShipping.shipping.country : 'IT' : 'IT' });

      if (this.groups.shipping.fields.country.model) {
        Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customerShipping && this.customerShipping.shipping ? this.customerShipping.shipping.state : 'RM' });
      }
    },
    setStates() {
      Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customerShipping.shipping.state || 'RM' });
    },
    async callback() {
      const body = {
        shipping_first_name: this.groups.shipping.fields.first_name.model,
        shipping_last_name: this.groups.shipping.fields.last_name.model,
        shipping_company: this.groups.shipping.fields.company.model,
        shipping_address_1: this.groups.shipping.fields.address_1.model,
        shipping_address_2: this.groups.shipping.fields.address_2.model,
        shipping_country: this.groups.shipping.fields.country.model,
        shipping_city: this.groups.shipping.fields.city.model,
        shipping_state: this.groups.shipping.fields.state.model,
        shipping_postcode: this.groups.shipping.fields.postcode.model,
      };

      await this.$store.dispatch('editAddress', body);
    },
  },
};
