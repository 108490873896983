var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{attrs:{"y":"ml","boxed":""}},[_c('Grid',{attrs:{"grid":"1"}},[_c('Form',{staticClass:"form--single",attrs:{"id":"login"}}),_c('Social',{staticClass:"block-theme--default"}),_c('Spacer',{attrs:{"y":"m"}},[_c('Flex',{attrs:{"justify":"center","align":"center","dir":"column","rowGap":"m"}},[_c('Cta',{staticClass:"full-button",attrs:{"theme":"button-boxed-light","data":{
            title: _vm.$labels.lost_password,
            fn: _vm.toggleRecoverPassword,
          }}}),_c('Cta',{staticClass:"full-button",attrs:{"theme":"button-boxed-light","data":{
            title: _vm.$labels.register,
            url: '/register',
          }}})],1)],1),(_vm.showRecoverPassword)?_c('Form',{staticClass:"form--single",attrs:{"id":"recovery-password"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }