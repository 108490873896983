var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t-center"},[(_vm.data.title)?_c('Spacer',{attrs:{"bottom":{ default: 'l', m: 'xxxl' }}},[_c('Title',{attrs:{"data":{ value: _vm.data.title, size: 'xxl' }}})],1):_vm._e(),_c('div',{class:['marquee-overflow', !_vm.loaded ? 'marquee-loading' : false],style:({
      '--marquee-width': `${_vm.totalWidth}px`,
      '--marquee-duration': `${_vm.duration}s`,
      '--marquee-direction': _vm.direction > 0 ? 'reverse' : undefined,
    })},[_c('div',{class:[
        'marquee-wrapper',
        _vm.switchDuplicate ? 'marquee-wrapper-start' : false,
      ]},[_c('div',{ref:"marquee",class:['marquee', {'android': _vm.isAndroid}],attrs:{"data-direction":_vm.data.direction,"align":"center"}},_vm._l((_vm.wrappers),function(wrapper){return _c('div',{key:wrapper,staticClass:"item-wrapper"},_vm._l((_vm.data.items),function(item,index){return _c('div',{key:index,class:['item', `item--${item.type}`]},[_c('Spacer',{attrs:{"x":"xs"}},[(item.type === 'text')?_c('div',{domProps:{"innerHTML":_vm._s(item.text)}}):_c('Figure',{ref:"img",refInFor:true,attrs:{"skip-load":!_vm.switchDuplicate && !_vm.isResizing,"data":Object.assign(item.image, {
                    lazyload: false,
                    ratio: false,
                    object_fit: 'contain',
                    relative: true,
                  })}})],1)],1)}),0)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }