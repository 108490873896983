var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['thumb']},[_c('router-link',{staticClass:"thumb__link",attrs:{"to":_vm.data.link || _vm.data.permalink}},[(_vm.data.gds_featured_image)?_c('Media',{staticClass:"media thumb__image",attrs:{"data":{
        type: 'image',
        image: _vm.data.gds_featured_image,
        ratio: _vm.ratio,
        lazyload: true,
        object_fit: 'cover',
      }}}):_vm._e()],1),_c('div',{staticClass:"thumb__text"},[_c('router-link',{staticClass:"thumb__link",attrs:{"to":_vm.data.link || _vm.data.permalink}},[_c('div',{staticClass:"thumb__title-container"},[_c('Title',{attrs:{"data":{
            value: _vm.data.taxonomy || _vm.data.name ? _vm.data.name : _vm.data.title.rendered,
            size: 'm',
          }}}),(_vm.sellable && !_vm.$mq.isMobileXS && !_vm.$mq.isMobileS)?_c('div',{staticClass:"thumb__price"},[_c('Price',{attrs:{"theme":"inline","product":_vm.data}})],1):_vm._e()],1),(_vm.data.description)?_c('Richtext',{staticClass:"thumb__description",attrs:{"data":{ value: _vm.data.description }}}):_vm._e(),(_vm.sellable && (_vm.$mq.isMobileXS || _vm.$mq.isMobileS))?_c('div',{staticClass:"thumb__price-mobile"},[_c('Price',{attrs:{"theme":"inline","product":_vm.data}})],1):_vm._e()],1),_c('div',[(!_vm.hasVariations && _vm.sellable && _vm.addableToCart)?_c('Cta',{attrs:{"theme":"button-boxed","data":{
          title: _vm.$labels.add_to_cart,
          url: false,
          fn: _vm.addToCart,
        },"disabled":!_vm.addableToCart}}):(_vm.hasVariations)?_c('Cta',{attrs:{"theme":"button-boxed","data":{
          title: _vm.$labels.view_product,
          url: _vm.data.link || _vm.data.permalink,
        }}}):(!_vm.addableToCart)?_c('Cta',{attrs:{"theme":"button-boxed","data":{
          title: 'On request',
          url: 'info@nm3.xyz',
        }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }