<template>
  <Flex class="flex-no-wrap cart__item product-cart-thumb" column-gap="m">
    <Grid col="4" class="w-full">
      <div
        class="wc-product-image-background"
        :style="{
          backgroundImage: `url(${productInfo.product_image})`,
        }"
      />

      <Column size="2">
        <Flex justify="space-between" class="cart__item--info" column-gap="m2">
          <router-link
            :to="{
              name: 'SingleProduct',
              params: {
                slug: productInfo.slug,
              },
            }"
          >
            <div>
              {{ productInfo.product_name }}
            </div>
            <Richtext :data="{ value: productInfo.description }" />
          </router-link>
          <div class="cart__item--price" v-if="theme === 'complete'">
            <div v-html="getLineTotal(cartItem)" />
          </div>
          <div class="cart__item--price" v-else>
            <div v-html="productPrice" />
          </div>
        </Flex>

        <Flex v-if="theme === 'complete'" align="center">
          <Flex align="center" justify="between" class="cart__item--numbers">
            <Number
              :key="cartLoading"
              :data="{
                id: `number`,
                fn: numberChanged,
                itemKey: cartItem.key,
                readonly: cartLoading,
              }"
              :value="cartItem.quantity"
            />
            <!-- <div
              v-if="cartItem.is_deposit"
              v-html="cartItem.deposit_item_subtotal"
            /> -->
          </Flex>
        </Flex>
      </Column>
    </Grid>
  </Flex>
</template>

<script>
import { mapGetters } from "vuex";

import { parseCurrency, unparseCurrency } from "@/assets/js/utils-wc";

import Number from "@/components/form/fields/number";
import Richtext from "@/components/typo/richtext";

export default {
  name: "ProductCartThumb",
  components: {
    Number,
    Richtext,
  },
  props: {
    theme: {
      type: String,
      default: "complete",
    },
    cartItem: {
      type: Object,
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(["cart", "cartLoading", "wcSettings"]),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    productInfo() {
      let object;
      if (this.theme === "side") {
        object = {
          product_name: this.cartItem.name,
          description: this.cartItem.description,
          product_price: this.cartItem.roduct_price,
          product_image: this.cartItem.images[0].src,
          product_price: this.cartItem.price,
          slug: this.cartItem.slug,
        };
      } else {
        object = this.cartItem;
      }
      return object;
    },
    productPrice() {
      return this.parseCurrency(this.productInfo.product_price);
    },
    subtotal() {
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal) +
          this.unparseCurrency(this.cart.totals.subtotal_tax)
      );
    },
    generalSpacerX() {
      if (this.theme === "checkout") {
        return false;
      }
      return this.theme === "complete" ? "l" : "m";
    },
    generalSpacerY() {
      if (this.theme === "checkout") {
        return "s";
      }
      return false;
    },
  },
  methods: {
    updateCartItem(key, quantity) {
      const item = this.cart.items.find((cartItem) => cartItem.key === key);
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`)
        ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity
        : 0;
      this.$store.dispatch(
        "updateCartItem",
        Object.assign(item, {
          quantity,
          quantityDelta: quantity - prevQuantity,
        })
      );
    },
    numberChanged(val) {
      if (val[0]) {
        this.updateCartItem(val[0], val[1]);
      } else {
        // $event
        const { target } = val;
        this.updateCartItem(target.dataset.key, target.value);
      }
    },
    removeCartItem(item) {
      const { key } = item;
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`)
        ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity
        : 0;
      this.$store.dispatch(
        "removeCartItem",
        Object.assign(item, { quantityDelta: prevQuantity })
      );
    },
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === "excl") {
        return this.parseCurrency(cartItem.line_subtotal);
      }
      return this.parseCurrency(
        cartItem.line_subtotal + cartItem.line_subtotal_tax
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    getCartItemSpacerBottom(index) {
      if (this.theme === "complete") {
        return "l";
      }
      return index === this.cart.items.length - 1 ? false : "m";
    },
    getCartItemSpacerTop(index) {
      if (this.theme === "side" && index === 0) {
        return "m";
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.product-cart-thumb {
  .wc-product-image-background {
    aspect-ratio: 3/4;
    width: 100%;
    background-size: cover;
  }

  .w-full {
    width: 100%;
  }
}
</style>
