<template>
  <figure :class="[
    'picture',
    !data.ratio ? 'no-ratio' : false,
    data.relative ? 'picture-relative' : false,
    data.object_fit ? `picture-${data.object_fit}` : 'picture-cover',
    data.align ? `align--${data.align}` : false,
    theme ? `picture-${theme}` : false,
    imageHorizontal ? 'horizontal' : 'vertical',
  ]" :data-lazy="data.lazyload ? 'false' : null">
    <picture v-if="imageType !== 'svg'" :style="{ paddingBottom: data.ratio ? `${ratio}%` : false }">
      <img ref="image" class="image" :data-src="data.lazyload || skipLoad ? src : null"
        :src="!data.lazyload && !skipLoad ? src : ''" :alt="defaultImage.alt">
      <div v-if="data.lazyload" class="placeholder">
        <img v-if="defaultImage.sizes?.pixel" :src="defaultImage.sizes?.pixel">
      </div>
    </picture>
    <div v-else-if="imageType === 'inline-svg'" class="svg" v-html="data.svg" />
    <div v-else-if="imageType === 'svg'">
      <img ref="image" class="svg" :src="defaultImage.url" :alt="defaultImage.alt">
    </div>
    <Spacer v-if="data.caption !== ''" tag="figcaption" :all="{ default: 's', m: 'm' }" v-html="data.caption" />
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    skipLoad: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
      ratio: 0,
      windowInnerWidth: window.innerWidth,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    imageHorizontal() {
      return this.defaultImage.width > this.defaultImage.height;
    },
    ratioThreeCols() {
      // return (((600 * this.windowInnerWidth) - (16 * 10)) / ((400 * this.windowInnerWidth) - (15 * 10))) * 100;
      return (
        ((600 * this.windowInnerWidth - 60) / (400 * this.windowInnerWidth - 160)) * 100
      );
      // return (50 * this.windowInnerWidth) - ((5 / 4) * 10) * 100;
      // return ([((2 / 3) * (this.windowInnerWidth - 20)) * (3 / 4)] / (1 / 3) * (this.windowInnerWidth - 20));
      // const totalWidth = this.windowInnerWidth - 20;
      // const imageWidth = ((2 / 3) * totalWidth);
      // const imageHeight = imageWidth * (4 / 3);
      // return (imageHeight / imageWidth) * 100;
    },
    ratioFourCols() {
      return (((400 * this.windowInnerWidth) - (16 * 10)) / ((300 * this.windowInnerWidth) - (15 * 10))) * 100;
    },
  },
  mounted() {
    if (this.imageType === 'image') {
      this.currentSrc();
      this.setRatio();
      this.$bus.$on('windowResized', this.currentSrc);
      this.$bus.$on('windowResized', () => {
        this.windowInnerWidth = window.innerWidth;
        this.setRatio();
      });
    }

    if (!this.data.lazyload && !this.skipLoad) {
      this.load();
    }
  },
  beforeDestroy() {
    if (this.imageType === 'image') {
      this.$bus.$off('windowResized', this.currentSrc);
    }
  },
  methods: {
    currentSrc() {
      let src = this.defaultImage.sizes.l;
      const { sizes, width, height } = this.defaultImage;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const dimension = width >= height ? 'width' : 'height';
      const wrapper = dimension === 'width'
        ? this.$parent.$el.offsetWidth
        : this.$parent.$el.offsetWidth
        * (this.defaultImage.height / this.defaultImage.width);
      const max = wrapper >= sizes[`xxl-${dimension}`] ? wrapper : wrapper * ratio;

      const ranges = {
        // xxs: sizes[`xxs-${dimension}`],
        // xs: sizes[`xs-${dimension}`],
        s: sizes[`s-${dimension}`],
        // m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        // xl: sizes[`xl-${dimension}`],
        xxl: sizes[`xxl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'xxl';
      if (this.$mq.isMobile && this.data.mobile) {
        src = this.data.mobile.sizes[size];
        this.currentImg = 'mobile';
        this.setRatio();
      } else {
        src = this.defaultImage.sizes[size];
        this.currentImg = 'default';
        this.setRatio();
      }

      if (this.$el.dataset.lazy === 'true') {
        this.$refs.image.src = src;
      }

      this.src = src;
    },
    setRatio() {
      if (!this.data && !this.defaultImage) this.ratio = 0;

      if (typeof this.data.ratio === 'number') {
        this.ratio = this.data.ratio;
        // } else if (this.theme === 'gallery-product') {
        //   console.log('gallery-product');
        //   this.ratio = (4 / 3) * 100;
        //
        // } else if (this.theme === 'gallery') {
        // console.log('gallery');
        // if (this.imageHorizontal) {
        //   console.log('horizontal');
        //   this.ratio = (3 / 4) * 100;
        // } else {
        //   this.ratio = (3 / 2) * 100;
        // }
      } else if (this.$mq.isMobile) {
        if (this.imageHorizontal) {
          this.ratio = (2 / 3) * 100;
        } else {
          this.ratio = (4 / 3) * 100;
        }
      } else if (this.data.layout === 'three_columns' && !this.imageHorizontal) {
        this.ratio = this.ratioThreeCols;
      } else if (this.data.layout === 'three_columns' && this.imageHorizontal) {
        this.ratio = (3 / 4) * 100;
      } else if (this.data.layout === 'four_columns' && !this.imageHorizontal) {
        this.ratio = this.ratioFourCols;
      } else if (this.data.layout === 'four_columns' && this.imageHorizontal) {
        this.ratio = (3 / 4) * 100;
      } else if (this.imageHorizontal) {
        this.ratio = (2 / 3) * 100;
      } else {
        this.ratio = (4 / 3) * 100;
      }
    },
    async load() {
      const img = this.$refs.image;

      if (this.skipLoad && this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  // overflow: hidden;
  width: 100%;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &-gallery.horizontal {
    grid-column: span 4;

    @include mq(s) {
      grid-column: span 2;
    }
  }

  &-gallery.vertical {
    grid-column: span 2;

    @include mq(s) {
      grid-column: span 1;
    }
  }

  &.picture-gallery figcaption {
    text-align: right !important;
    right: 0;
    left: unset;
  }

  &.no-ratio {
    picture {
      height: 100%;
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.picture-relative {
      .image {
        position: relative;
        max-width: 100%;
      }
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.picture-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.3s $ease-custom 0.2s;
    will-change: opacity;
    // background: var(--grey);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      image-rendering: pixelated;
    }
  }

  figcaption {
    text-align: left;
    padding: 0;
    max-width: 75%;
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: translateY(100%);

    @include mq(m) {
      max-width: 50%;
    }

  }

  .image {
    visibility: hidden;

    &[src] {
      visibility: visible;
    }
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }
}

// .product figure {
//   height: unset;
// }
</style>
