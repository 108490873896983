<template>
  <Spacer
    y="m"
    :use-margin="true"
    class="pay-order"
  >
    <Wrapper
      boxed
    >
      <Grid
        col="12"
        row-gap="m"
      >
        <div class="typo--s border-bottom">
          {{ $labels.pay_for_order }}
        </div>
        <div v-if="order">
          <Wrapper
            v-if="!isOrderPayable"
            size="s"
          >
            <Grid
              col="12"
              class="block-theme--default"
            >
              <div class="t-center">
                {{ $labels.cannot_pay_for_order }}.
              </div>
              <Flex justify="center">
                <Cta
                  :data="{
                    title: $labels.go_to_orders,
                    url: '/my-account/orders',
                  }"
                  theme="button"
                />
              </Flex>
            </Grid>
          </Wrapper>
          <Grid
            v-else
            col="6"
            align="start"
          >
            <Grid
              col="12"
              class="block-theme--default"
            >
              {{ $labels.order_details }}
              <OrderHeader
                :order="order"
                theme="hide-actions"
              />
              <div class="separator--row" />
              <OrderDetails
                :order="order"
                theme="hide-actions"
              />
            </Grid>

            <Grid
              col="12"
              class="block-theme--default"
            >
              {{ $labels.payment_methods }}
              <Form
                id="pay"
                theme="plain"
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import OrderHeader from '@/components/wc/order-header';
import Cta from '@/components/typo/cta';
import OrderDetails from '@/components/wc/order-details';
import Form from '@/components/form';

import {
  stripeMountCard,
  availableOrderActions,
} from '@/assets/js/utils-wc';

export default {
  name: 'PayOrder',
  components: {
    OrderHeader,
    OrderDetails,
    Form,
    Cta,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas']),
    isOrderPayable() {
      if (this.order) {
        const orderActions = availableOrderActions[`wc-${this.order.status}`];
        return orderActions.includes('pay');
      }
      return false;
    },
  },
  async mounted() {
    const { data } = await this.$store.dispatch('getOrder', {
      key: this.$route.query.key,
      order_id: this.$route.params.orderId,
    });
    this.order = data;

    if (this.paymentGateways.stripe && this.paymentGateways.stripe.publishable_key) {
      const stripe = await loadStripe(this.paymentGateways.stripe.publishable_key, {
        locale: 'en', // TODO: get from WPML
      });
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas);
    }
  },
};
</script>

<style lang="scss" scoped>
.pay-order {
  margin-top: 30px;
  --columns: 12;

  ::v-deep {
    .form-footer > * {
      justify-content: flex-end;
    }

    .cta {
      border-bottom: 1px solid black;
      transition: border-bottom 0.2s ease-in-out;
      margin-top:10px;
      margin-bottom: 20px;
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
.border-bottom {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--black);
}
</style>
