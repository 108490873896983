<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div
      class="field field--radio"
      :class="classes"
    >
      <slot
        v-if="data.label"
        name="label"
      />
      <div
        v-for="(option, index) in data.options"
        :key="index"
        class="field-radio-option"
      >
        <label>
          <input
            :id="option.id"
            v-model="computedModel"
            type="radio"
            :name="data.id"
            :value="option.id"
            @change="data.fn ? data.fn(option.id) : false"
          >
          <div class="field-after-input" />
          <span v-html="option.title" />
        </label>
      </div>

      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Radio',
  components: {
    ValidationProvider,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
};
</script>

<style>

</style>
