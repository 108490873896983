<template>
  <Grid
    v-if="cart.totals"
    col="12"
    class="cart-totals-container"
  >
    <Coupon class="cart-summary-coupon" v-if="theme === 'checkout'"/>
    <div class="separator--row" v-if="theme === 'checkout'"/>
    <div :class="['cart-summary-details', {'summary-checkout': theme === 'checkout'}]">
      <Flex
        justify="space-between"
        class="t-right"
      >
        <div>{{ $labels.subtotal }}</div>
        <div v-html="subtotal" />
      </Flex>
      <Flex
        justify="space-between"
        class="t-right"
      >
        <div>
          <span v-if="theme === 'checkout'">{{ $labels.shipping }}</span>
          <span v-else>{{ $labels.shipping_exstimated }}</span>
        </div>
        <div>
          <span
            v-if="shippingAvailable"
            v-html="shipping"
          />
          <span
            v-else
            v-html="`To be defined`"
          />
        </div>
      </Flex>
      <Flex
        v-if="cart.coupons.length > 0"
        justify="space-between"
        class="t-right"
      >
        <div>{{ $labels.coupon }}</div>
        <div>
          -
          <span v-html="discount" />
        </div>
      </Flex>
      <!-- <Flex
        v-if="taxDisplayCart === 'excl'"
        justify="space-between"
        class="t-right"
      >
        <div>
          <span v-if="theme === 'checkout'">{{ $labels.vat }}</span>
          <span v-else>{{ $labels.vat_extimated }}</span>
        </div>
        <div>
          <span v-html="vat" />
        </div>
      </Flex> -->
    </div>
    <div class="separator--row" />
    <Flex
      justify="space-between"
      class="typo--m cart-summary-total"
    >
      <div>{{ $labels.total }}</div>
      <div v-html="parseCurrency(unparseCurrency(cart.totals.total))" />
    </Flex>
    <div class="separator--row hide-mobile" v-if="theme === 'cart'"/>
    <div class="cart-summary-info hide-mobile" v-if="theme === 'cart'">
      <Accordion :data="cartInfo" theme="underline"></Accordion>
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency, unparseCurrency, exclVatLabel } from '@/assets/js/utils-wc';
import Coupon from '@/components/wc/coupon';
import Cta from '@/components/typo/cta';
import Accordion from '@/components/blocks/accordion';
const { options } = __VUE_WORDPRESS__.state;




export default {
  name: 'CartTotals',
  components: {
    Coupon,
    Cta,
    Accordion
  },
  props: {
    theme: {
      type: String,
      default: 'checkout',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['cart', 'wcSettings']),
    shippingAvailable() {
      return Object.keys(this.cart.shipping_methods).map((method) => this.cart.shipping_methods[method].chosen_method);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)) + this.unparseCurrency((this.cart.totals.subtotal_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)))}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal_tax)))}`;
    },
    shipping() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)) + this.unparseCurrency((this.cart.totals.shipping_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)))}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_tax)))}`;
    },
    discount() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)) + this.unparseCurrency((this.cart.totals.discount_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)))}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_tax)))}`;
    },
    vat() {
      return this.parseCurrency(this.unparseCurrency(this.cart.totals.total_tax));
    },
    cartInfo() {
      let obj = {
        items: [
          {title_trigger: this.$labels.payment_shipping, rich_text: options.extra?.cart_payments_shipping?.value || '' }, 
          {title_trigger: this.$labels.terms_conditions, rich_text: options.extra?.cart_terms_conditions?.value || ''},
          {title_trigger: this.$labels.contacts, rich_text: options.extra?.cart_contacts?.value || ''}
        ]
      }
      return obj;
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)) + this.unparseCurrency((cartItem.line_subtotal_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)))}`;
      }
      return this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)) + this.unparseCurrency((cartItem.line_subtotal_tax)));
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-summary-details, .cart-totals-container {
  row-gap: 0;
}

.cart-summary-details, .cart-summary-info {
  padding: var(--spacer-s) 0 var(--spacer-m) 0;
}

.cart-summary-details {
  padding: 0 0 var(--spacer-m) 0; 
  &.summary-checkout {
    padding: var(--spacer-s) 0 var(--spacer-m) 0; 
  }
}
.cart-summary-total {
  padding: var(--spacer-s) 0 var(--spacer-m2) 0;

  @include mq(m) {
    padding: var(--spacer-s) 0 var(--spacer-m4) 0;
  }
}

.cart-summary-coupon {
  padding-bottom: var(--spacer-m2);

  @include mq(m) {
    padding-bottom: (--spacer-m3);
  }
}

.hide-mobile {
  display: none;
  @include mq(m) {
    display: block;
  }
}
</style>
