<template>
  <section>
    <Spacer
      bottom="l"
      top="s"
      class="product__text"
    >
      <Grid>
        <Flex class="bottom-item product__text--title typo--m">
          <div>{{ parent.name }}</div>
        </Flex>
        <Richtext
          class="bottom-item product__text--description"
          :data="{
            value: product.description ? product.description : post.description,
          }"
        />

        <div class="bottom-item product__text--info">
          <div v-if="post.acf.size">
            Size (cm): {{ post.acf.size }}
          </div>
          <div v-if="post.weight">
            Weight (kg): {{ post.weight }}
          </div>
          <div v-if="post.acf.material">
            Material: {{ post.acf.material }}
          </div>
        </div>
        <div
          v-if="hasVariations"
          class="bottom-item"
        >
          <div
            v-for="(attribute, index) in attributes"
            :key="index"
          >
            <Flex>
              <Select
                v-model="attribute.model"
                class="product__variation-selector"
                :data="getAttribute(attribute)"
                :model.sync="attribute.model"
              >
                <label
                  slot="label"
                  for="terms"
                >{{ attribute.name }}</label>
              </Select>
              <Icon
                v-if="attribute.model"
                name="close"
                theme="small"
                :fn="() => resetAttribute(attribute)"
              />
            </Flex>
          </div>
        </div>
        <!-- <div class="product__text--price" /> -->

        <!-- <Availability :product="product" /> -->
        <Flex
          v-if="product && post.categories[0].slug === 'shop'"
          class="bottom-item product__text--add"
        >
          <!-- <Number
            :data="{
              id: `number`,
              fn: numberChanged,
              itemKey: product.id,
              readonly: cartLoading || isOos,
            }"
            :value.sync="numberToBeAdded"
          /> -->
          <Price
            :product="product"
            class="price"
          />
          <Cta
            :data="{
              title: labelCart,
              url: false,
              fn: addToCart,
            }"
            :disabled="!addableToCart"
            theme="button"
          />
        </Flex>
      </Grid>
    </Spacer>

    <Flex class="grouped__names">
      <p
        v-for="(name, key) in groupedProducts"
        :key="key"
        class="grouped__name"
        :class="[key === post.slug ? 'active' : null]"
        @click="setCurrentProduct(key)"
      >
        {{ setGroupedName(name) }}
      </p>
    </Flex>
    <Spacer bottom="ml">
      <Figure
        class="media product__gallery"
        :data="{
          default: post.gallery[0],
          ratio: true,
          lazyload: true,
          object_fit: 'cover',
        }"
      />
    </Spacer>

    <Spacer
      v-if="post.acf.technical_drawings"
      bottom="ml"
      class="gallery product__technicals"
    >
      <Spacer y="m">
        <Title :data="{ value: 'Technical Drawings' }" />
      </Spacer>
      <Grid>
        <div
          v-for="(item, index) in post.acf.technical_drawings"
          :key="index"
          class="product__technicals--view"
        >
          <div class="caption">
            {{ item.caption }}
          </div>
          <Figure
            class="media product__gallery"
            :data="{
              default: item,
              ratio: true,
              lazyload: true,
              object_fit: 'contain',
            }"
          />
        </div>
      </Grid>
    </Spacer>

    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :columns="section.layout"
      :class="`section--${parentIndex}`"
      theme="single"
    >
      <div
        v-for="(block, index) in blocks(section)"
        :key="index"
        :class="[
          'block',
          `block--${block.acf_fc_layout}`,
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null,
          { [`block--span-${block.columns}`]: block.columns },
        ]"
      >
        <component
          :is="components.get(block.acf_fc_layout)"
          :data="{ ...block, sectionLayout: section.layout }"
        />
      </div>
    </Section>

    <div class="product__files">
      <a
        v-if="post.acf.product_sheet"
        :href="post.acf.product_sheet.url"
      >
        <span class="product__files--underlined">Product Sheet</span>
        <span class="arrowdown" />
      </a>
      <a
        v-if="post.acf.cad_file"
        target="_blank"
        :href="post.acf.cad_file.url"
      ><span class="product__files--underlined">CAD File</span>
        <span
          class="arrowdown"
        /></a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import gsap, { ScrollToPlugin } from 'gsap/all';

import isEqual from 'lodash.isempty';
import { addableToCart, isOos } from '@/assets/js/utils-wc';
import components from '@/assets/js/components';

import Figure from '@/components/media/figure';

import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Richtext from '@/components/typo/richtext.vue';
import Icon from '@/components/ui/icon.vue';
import Price from '@/components/wc/price.vue';
import Section from '@/components/section';
// import Availability from '@/components/wc/availability.vue';

// import Number from '@/components/form/fields/number';
import Select from '@/components/form/fields/select';

import { fetchVariations } from '@/api';

gsap.registerPlugin(ScrollToPlugin);

// N.B. testato fino a 2 attributi

export default {
  name: 'SingleGroupedProduct',
  components: {
    Title,
    Cta,
    Richtext,
    Icon,
    Price,
    // Availability,
    // Number,
    Select,
    Figure,
    Section,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    others: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      labelCart: this.$labels.add_to_cart,
      components,
    };
  },
  computed: {
    ...mapGetters(['cartLoading']),
    hasVariations() {
      return this.post.type === 'variable';
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }
      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      // this.variations.forEach((variation) => {
      //   this.post.gallery.push(variation.gds_featured_image);
      // });
      return [this.post.gds_featured_image].concat(
        this.post.gallery ? this.post.gallery : [],
      );
    },
    galleryFiltered() {
      return this.post.gallery.filter((item, index) => index > 0);
    },
    groupedProducts() {
      const current = {};
      current[this.post.slug] = this.post.name;
      const products = {
        ...this.others,
        ...current,
      };

      return Object.keys(products)
        .sort()
        .reduce((obj, key) => {
          obj[key] = products[key];
          return obj;
        }, {});
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      if (
        this.$refs['product-gallery']
        && this.$refs['product-gallery'].$children[0]
      ) {
        setTimeout(() => {
          this.$refs['product-gallery'].$children[0].gallery.selectCell(
            `[data-item-id="${this.selectedImage}"]`,
          );
        }, 100); // Await flickity loading
      }
    },
  },
  async created() {
    this.product = this.post;

    this.$store.commit('SET_CURRENT_POST', this.post);
    if (this.hasVariations) {
      this.$store.commit('SET_CART_LOADING', true);
      const { data: variations } = await fetchVariations({
        params: { slug: this.post.slug },
      });
      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: 'select',
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };
        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (
            this.variations.findIndex(
              (variation) => variation.attributes_slugs[attribute_slug]
                === this.post.attributes_slugs[attribute_slug][i],
            ) > -1
          ) {
            newAttribute.options[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
            newAttribute.originalOptions[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
          }
        });
        this.attributes.push(newAttribute);
      });
      this.setSelectedVariation();
      this.$store.commit('SET_CART_LOADING', false);
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.product);

    if (
      this.$store.state.route.from
      && this.$store.state.route.from.name === 'SingleGroupedProduct'
    ) {
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo: {
          y: this.$el,
          offsetY: 46, // header height
          autoKill: true,
        },
        duration: 0.6,
        ease: 'expo.out',
      });
    }
  },
  methods: {
    setGroupedName(name) {
      return name.replace(this.parent.name, '');
    },
    setCurrentProduct(val) {
      if (val !== this.$route.params.slug) {
        this.$router.replace({
          params: {
            groupedSlug: val,
          },
        });
      }
    },
    addToCart() {
      this.labelCart = this.$labels.added;
      this.$store
        .dispatch(
          'addToCart',
          Object.assign(this.post, {
            id: String(this.post.id),
            variation_id: this.selectedVariation,
            variation: this.variations.find(
              (variation) => this.selectedVariation === variation.id,
            ),
            quantity: this.numberToBeAdded,
            quantityDelta: this.numberToBeAdded,
          }),
        )
        .then(() => {
          this.labelCart = this.$labels.add_to_cart;
        });
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
        this.numberToBeAdded = val[1];
      } else {
        this.numberToBeAdded = val.target.value;
      }
      if (this.numberToBeAdded < 1) {
        this.numberToBeAdded = 1;
      }
      if (
        this.product.stock_quantity &&
        this.numberToBeAdded > this.product.stock_quantity
      ) {
        this.numberToBeAdded = this.product.stock_quantity;
      }
    },
    getAttribute(attribute) {
      const select = document.querySelector(`select#${attribute.id}`);
      const currentFixedOption = document.querySelector(".field.focus select");
      if (
        currentFixedOption &&
        select &&
        select.closest(".field") &&
        !select.closest(".field").classList.contains("focus")
      ) {
        // Re set original value
        const originalOptionsKeys = Object.keys(attribute.originalOptions);
        originalOptionsKeys.forEach((originalOptionsKey) => {
          if (!attribute.options[originalOptionsKey]) {
            attribute.options[originalOptionsKey] =
              attribute.originalOptions[originalOptionsKey];
          }
        });

        const currentFixedKey = currentFixedOption.getAttribute("id");
        const currentFixedValue = currentFixedOption.value;
        const optionsKeys = Object.keys(attribute.options);
        optionsKeys.forEach((optionsKey) => {
          const existingVariation = this.variations.filter((variation) => {
            return (
              variation.attributes_slugs[currentFixedKey] ===
                currentFixedValue &&
              variation.attributes_slugs[attribute.id] === optionsKey
            );
          });
          if (!existingVariation[0]) {
            delete attribute.options[optionsKey];
          }
        });
      }

      return attribute;
    },
    resetAttribute(attribute) {
      attribute.model = null;
      this.resetSelectedVariation();
    },
    resetSelectedVariation() {
      this.selectedVariation = null;
      this.selectedImage = null;
    },
    setSelectedVariation() {
      const queryVariations = Object.keys(this.$route.query);
      if (queryVariations.length === 0) {
        this.attributes.forEach((attribute) => {
          attribute.model = null;
        });
        this.resetSelectedVariation();
      } else {
        // Re-attribute model if reloading page
        queryVariations.forEach((queryVariation) => {
          this.attributes.forEach((attribute) => {
            if (attribute.id === queryVariation.replace("attribute_", "")) {
              attribute.model = this.$route.query[queryVariation];
            }
          });
        });

        const selectedVariation = this.variations.find((variation) => {
          let isRightVariation = false;
          let correctAttributes = 0;
          this.attributes.forEach((attribute) => {
            if (attribute.model === variation.attributes_slugs[attribute.id]) {
              correctAttributes += 1;
            }
          });
          if (correctAttributes === variation.attributes.length) {
            isRightVariation = true;
          }
          return isRightVariation;
        });
        if (selectedVariation) {
          this.selectedVariation = selectedVariation.id;
          this.selectedImage = selectedVariation.gds_featured_image
            ? selectedVariation.gds_featured_image.id
            : null;
          this.product = selectedVariation;
        } else {
          this.resetSelectedVariation();
        }
      }
      // Marketing
      this.$bus.$emit("selectItem", this.product);
    },
    updateSelectedVariation() {
      let attributesSelected = 0;
      const query = {};
      this.attributes.forEach((attribute) => {
        if (attribute.model) {
          query[`attribute_${attribute.id}`] = attribute.model;
          attributesSelected += 1;
        }
      });
      if (
        attributesSelected === this.attributes.length &&
        !isEqual(query, this.$route.query)
      ) {
        this.$router.replace({ query });
      }
    },
    layout(section) {
      const {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      } = section;
      return {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      };
    },
    blocks(section) {
      const { layout } = section;
      let value = section.blocks;

      if (layout !== "default") {
        value = section[`blocks_${layout}`];
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
.product__text {
  &--title {
    @include mq(s) {
      grid-column: span 2;
    }
  }
  &--add {
    display: grid;
    justify-content: space-between;
    row-gap: var(--row-gap-m);

    @include mq(l) {
      grid-auto-flow: column;
    }
  }
}
</style>
