<template>
  <div class="t-center">
    <Spacer
      v-if="data.title"
      :bottom="{ default: 'l', m: 'xxxl' }"
    >
      <Title :data="{ value: data.title, size: 'xxl' }" />
    </Spacer>
    <div
      :class="['marquee-overflow', !loaded ? 'marquee-loading' : false]"
      :style="{
        '--marquee-width': `${totalWidth}px`,
        '--marquee-duration': `${duration}s`,
        '--marquee-direction': direction > 0 ? 'reverse' : undefined,
      }"
    >
      <div
        :class="[
          'marquee-wrapper',
          switchDuplicate ? 'marquee-wrapper-start' : false,
        ]"
      >
        <div
          ref="marquee"
          :class="['marquee', {'android': isAndroid}]"
          :data-direction="data.direction"
          align="center"
        >
          <div
            v-for="wrapper in wrappers"
            :key="wrapper"
            class="item-wrapper"
          >
            <div
              v-for="(item, index) in data.items"
              :key="index"
              :class="['item', `item--${item.type}`]"
            >
              <Spacer x="xs">
                <div
                  v-if="item.type === 'text'"
                  v-html="item.text"
                />
                <Figure
                  v-else
                  ref="img"
                  :skip-load="!switchDuplicate && !isResizing"
                  :data="
                    Object.assign(item.image, {
                      lazyload: false,
                      ratio: false,
                      object_fit: 'contain',
                      relative: true,
                    })
                  "
                />
              </Spacer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Figure from '@/components/media/figure';

export default {
  name: 'Marquee',
  components: {
    Title,
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      direction: this.data.direction === 'ltr' ? -1 : 1,
      switchDuplicate: false,
      duplicateItems: [],
      totalWidth: 0,
      delta: 0,
      x: 0,
      speed: 20,
      isResizing: false,
      wrappers: 2,
    };
  },
  computed: {
    duration() {
      return ((this.speed / 10) * this.totalWidth) / 100;
    },
    isAndroid() {
      const regex = /Android/i;
      return regex.test(navigator.userAgent);
    }
  },
  watch: {
    loaded(val) {
      if (val) {
        this.setDuplicateItems();
      }
    },
    totalWidth(val) {
      if (val === 0) this.setTotalWidth();
    },
  },
  mounted() {
    this.promises().then(() => {
      this.setTotalWidth();
      this.loaded = true;
    });

    this.$bus.$on('windowResized', this.resize);
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    setTotalWidth() {
      if (
        this.$refs.marquee
        && this.$refs.marquee.children
      ) {
        this.totalWidth = this.$refs.marquee.children[0].offsetWidth;
      }
    },
    setDuplicateItems() {
      this.delta = Math.floor(window.innerWidth / this.totalWidth) + 2;
      this.wrappers = this.delta < 2 ? 2 : this.delta;
      this.start();
    },
    start() {
      this.switchDuplicate = true;
    },
    stop() {
      this.switchDuplicate = false;
    },
    resize() {
      this.switchDuplicate = false;
      this.loaded = false;
      this.isResizing = true;

      this.$nextTick(() => {
        this.setTotalWidth();
        this.loaded = true;
        this.isResizing = false;
      });
    },
    promises() {
      const promises = [];
      if (this.$refs.img && this.$refs.img.length > 0) {
        this.$refs.img.forEach((img) => {
          promises.push(img.load());
        });
      }

      return Promise.all(promises);
    }
  }
};
</script>

<style lang="scss" scoped>
.marquee {
  &-overflow {
    position: relative;
    overflow: hidden;
  }

  &-loading {
    opacity: 0;
  }

  &-wrapper {
    width: var(--marquee-width);
    white-space: nowrap;
  

    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    animation-duration: var(--marquee-duration);
    animation-direction: var(--marquee-direction, initial);

    &-start {
      animation-play-state: running;
    }

    display: flex;
  }

  flex-wrap: nowrap;
  white-space: nowrap;
  width: max-content;
  display: inline-block;

  .item-wrapper {
    white-space: nowrap;
    display: inline-block;
  }

  .item {
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;

    &--img {
      width: 200px;

      /deep/ img {
        max-height: 120px;
      }

      @include mq(m) {
        width: 300px;
      }
    }
  }
}

// .large-text .marquee.android .item .spacer > div {
//   line-height: 90%;
//     font-weight: normal;
//     font-size: 10vw;
//     letter-spacing: -0.02em;

//     @include mq(m) {
//       font-size: 4.3vw;
//     }
  
//     &::before {
//       content: "";
//       margin-bottom: 1px;
//       display: table;
//     }

//     &::after {
//       content: "";
//       margin-top: 5px;
//       display: table;
//     }
// }
.large-text .marquee .item .spacer > div {
    line-height: 1.2;
    font-weight: normal;
    font-size: 10vw;
    letter-spacing: -0.02em;

    @include mq(m) {
      font-size: 4.3vw;
    }
  
    &::before {
      content: "";
      margin-bottom: -0.2em;
      display: table;
    }
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
