<template>
  <Spacer
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :class="[
      layout.section_classes && layout.section_classes !== ''
        ? layout.section_classes.split(' ')
        : null,
      { [`layout--${layout.section_layout}`]: layout.section_layout },
      { ['product-stack']: isProductStack },
    ]"
    tag="section"
    :template="sectionSpacer"
  >
    <Wrapper
      :class="['section-grid', { [`section-grid--${columns}`]: columns }]"
    >
      <slot />
    </Wrapper>
  </Spacer>
</template>

<script>
export default {
  name: "Section",
  props: {
    layout: {
      type: Object,
      required: true,
    },
    columns: {
      type: String,
      default: "",
    },
    isProductStack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sectionSpacer() {
      let template = "section";

      if (this.layout.section_spacer === "s") template = "section-small";
      else if (this.layout.section_spacer === "m") template = "section-medium";
      else if (this.layout.section_spacer === "l") template = "section-large";
      else if (this.layout.section_spacer === "0")
        template = "section-no-space";

      return template;
    },
    sectionGrid() {
      let value = false;

      if (this.columns === "three_columns") {
        value = true;
      } else if (this.columns === "four_columns") {
        value = true;
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
.section-grid {
  align-items: start;
  column-gap: var(--column-gap-m);
  display: grid;
  row-gap: var(--row-gap-m);
  grid-auto-columns: minmax(0, 1fr);

  &--three_columns {
    @include mq(s) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &--four_columns {
    @include mq(m) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &--empty {
      @include mq(s) {
        grid-column: span 2;
      }
    }
  }

  .block--empty {
    display: none;

    @include mq(s) {
      display: grid;
    }
  }
}


.product-stack {
  .section-grid--four_columns {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include mq(m) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    &--empty {
      @include mq(s) {
        grid-column: span 2;
      }
    }
  }

  .block--product {
    height: 100%;
  }
}
</style>
