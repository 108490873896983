import Accordion from '@/components/blocks/accordion';
import Archive from '@/components/blocks/archive';
import Content from '@/components/blocks/content';
import Custom from '@/components/blocks/custom';
import Hero from '@/components/blocks/hero';
import List from '@/components/blocks/list';
import Marquee from '@/components/blocks/marquee';
import Media from '@/components/blocks/media';
import Newsletter from '@/components/blocks/newsletter';
import Product from '@/components/blocks/product';
import Repeater from '@/components/blocks/repeater';
import Search from '@/components/blocks/search';

const components = new Map();

components.set('accordion', Accordion);
components.set('archive', Archive);
components.set('content', Content);
components.set('empty', 'div');
components.set('hero', Hero);
components.set('list', List);
components.set('marquee', Marquee);
components.set('media', Media);
components.set('newsletter', Newsletter);
components.set('placeholder', Custom);
components.set('product', Product);
components.set('repeater', Repeater);
components.set('search', Search);

export default components;
