<template>
  <div :class="['thumb']">
    <router-link class="thumb__link" :to="data.link || data.permalink">
      <Media
        v-if="data.gds_featured_image"
        class="media thumb__image"
        :data="{
          type: 'image',
          image: data.gds_featured_image,
          ratio: ratio,
          lazyload: true,
          object_fit: 'cover',
        }"
      />
    </router-link>
    <div class="thumb__text">
      <router-link class="thumb__link" :to="data.link || data.permalink">
        <div class="thumb__title-container">
          <Title
            :data="{
              value: data.taxonomy || data.name ? data.name : data.title.rendered,
              size: 'm',
            }"
          />
          <div v-if="sellable && !$mq.isMobileXS && !$mq.isMobileS" class="thumb__price">
            <Price theme="inline" :product="data" />
          </div>
        </div>
        <Richtext
          v-if="data.description"
          :data="{ value: data.description }"
          class="thumb__description"
        />
        <div v-if="sellable && ($mq.isMobileXS || $mq.isMobileS)" class="thumb__price-mobile">
          <Price theme="inline" :product="data" />
        </div>
      </router-link>

      <div>
        <Cta
          v-if="!hasVariations && sellable && addableToCart"
          theme="button-boxed"
          :data="{
            title: $labels.add_to_cart,
            url: false,
            fn: addToCart,
          }"
          :disabled="!addableToCart"
        />
        <Cta
          v-else-if="hasVariations"
          theme="button-boxed"
          :data="{
            title: $labels.view_product,
            url: data.link || data.permalink,
          }"
        />
        <Cta
          v-else-if="!addableToCart"
          theme="button-boxed"
          :data="{
            title: 'On request',
            url: 'info@nm3.xyz',
          }"
        />
      </div>
    </div>

    <!-- <Flex justify="center">

      <Cta
        v-else
        theme="button"
        :data="{
          title: $labels.select_option,
          url: data.link || data.permalink,
        }"
      />
    </Flex> -->
  </div>
</template>

<script>
import { addableToCart, isOos } from "@/assets/js/utils-wc";

import Media from "@/components/blocks/media";
import Cta from "@/components/typo/cta";
import Title from "@/components/typo/title";
import Richtext from "@/components/typo/richtext";

import Price from "@/components/wc/price.vue";
// import Availability from '@/components/wc/availability.vue';

export default {
  name: "ProductThumb",
  components: {
    Cta,
    Media,
    Title,
    Richtext,
    Price,
    // Availability,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "link",
    },
    theme: {
      type: String,
      default: "",
    },
    sectionLayout: {
      type: String,
      default: "",
    },
  },
  computed: {
    isOos() {
      return isOos(this.data);
    },
    hasVariations() {
      return this.data.type === "variable";
    },
    addableToCart() {
      if (!addableToCart(this.data) || isOos(this.data)) {
        return false;
      }
      return true;
    },
    sellable() {
      let value = false;

      if (this.data.gds_taxonomies.product_cat.some((category) => category.slug === "shop")) {
        value = true;
      }
      return value;
    },
    ratio() {
      let value = (4 / 3) * 100;

      if (this.theme === "block" && this.sectionLayout === "default") {
        value = (9 / 16) * 100;
      }

      if (this.sectionLayout === "three_columns" && this.data.columns === "two") {
        value = true;
      }

      return value;
    },
  },
  methods: {
    addToCart() {
      this.$store.dispatch("addToCart", {
        ...this.data,
        id: String(this.data.id),
        quantity: 1,
        quantityDelta: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: var(--column-gap-m);
  min-height: 100%;
  grid-template-rows: auto 1fr;

  > * {
    grid-column: 1 / -1;
  }

  &__text {
    gap: var(--column-gap-m);
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    margin-top: var(--spacer-s);
    row-gap: var(--spacer-m2);
    grid-template-rows: 1fr auto;

    // @include mq("m") {
    //   grid-auto-flow: column;
    // }
  }

  &__title-container {
    display: grid;
    grid-auto-flow: column;
    // grid-auto-rows: min-content;
    column-gap: var(--spacer-m);
    grid-template-columns: auto auto;
  }

  &__price {
    margin-top: 0;
  }

  &__price-mobile {
    margin-top: var(--spacer-m);
  }
}
</style>
