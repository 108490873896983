<template>
  <div class="block-theme--default">
    <AccordionWrapper>
      <AccordionItem>
        <template slot="accordion-trigger">
          <div class="form-wrapper__header">
            <div class="form-wrapper__title">
              {{ $labels.saved_cards }}
            </div>
          </div>
        </template>
        <template slot="accordion-content">
          <Spacer
            v-if="!customerSavedMethodsSorted[0]"
            bottom="m"
          >
            {{ $labels.no_saved_card }}.
          </Spacer>
          <Grid
            v-for="(savedMethod, index) in customerSavedMethodsSorted"
            v-else
            :key="index"
            grid="12"
          >
            <Column
              :size="{
                default: 12,
                s: 6,
              }"
              class="saved-cards__grid-element-left"
            >
              <Spacer
                :bottom="{
                  default: 'xs',
                  s: 'm',
                }"
              >
                {{ savedMethod.method.brand }}
                {{ $labels.ending_in }}
                {{ savedMethod.method.last4 }}
                ({{ $labels.expires }} {{ savedMethod.expires }})
              </Spacer>
            </Column>
            <Column
              :size="{
                default: 12,
                s: 6,
              }"
            >
              <Spacer
                :bottom="{
                  default: 'l',
                  s: 'm',
                }"
              >
                <Flex
                  align="center"
                  justify="end"
                  :dir="{
                    default: 'column',
                    s: 'row',
                  }"
                >
                  <Spacer
                    v-for="(action, i) in getAvailableActions(savedMethod)"
                    :key="i"
                    :right="
                      i !== getAvailableActions(savedMethod).length - 1 &&
                        !$mq.isMobileS
                        ? 'm'
                        : false
                    "
                    :bottom="
                      i !== getAvailableActions(savedMethod).length - 1 &&
                        $mq.isMobileS
                        ? 'm'
                        : false
                    "
                  >
                    <Cta
                      v-if="action === 'delete'"
                      :data="{
                        title: $labels.delete,
                        fn: () => openModal(savedMethod),
                      }"
                      theme="warning"
                    />
                    <Cta
                      v-if="action === 'make-default'"
                      :data="{
                        title: $labels.make_default,
                        fn: () => makeDefaultCard(savedMethod),
                      }"
                    />
                  </Spacer>
                </Flex>
              </Spacer>
            </Column>
          </Grid>
          <!-- <hr> -->
          <Spacer
            bottom="m"
            class="typo--m"
          >
            {{ $labels.add_new_card }}
          </Spacer>
          <Grid
            grid="12"
            align="center"
          >
            <Column
              :size="{
                default: 12,
                s: 8,
              }"
              class="saved-cards__grid-element-left"
            >
              <div id="card-element">
                <!-- Elements will create input elements here -->
              </div>
              <p
                id="card-errors"
                role="alert"
              >
                <!-- Error messages will be shown in this element -->
              </p>
            </Column>
            <Column
              :size="{
                default: 12,
                s: 4,
              }"
              class="saved-cards__grid-element-right"
            >
              <Spacer
                :top="$mq.isMobileS ? 'm' : false"
              >
                <Cta :data="{ title: $labels.add, fn: addCard }" theme="button-boxed"/>
              </Spacer>
            </Column>
          </Grid>
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import orderBy from 'lodash.orderby';

import { loadStripe } from '@stripe/stripe-js';
import {
  stripeMountCard,
  addCardToCustomerSavedMethods,
} from '@/assets/js/utils-wc';

import { setDefaultPaymentSource, deletePaymentSource } from '@/api';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import Cta from '@/components/typo/cta';

export default {
  name: 'CartList',
  components: {
    AccordionWrapper,
    AccordionItem,
    Cta,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas', 'customerSavedMethods']),
    customerSavedMethodsSorted() {
      return orderBy(this.customerSavedMethods, ['is_default'], ['desc']);
    },
  },
  watch: {},
  async mounted() {
    if (
      this.paymentGateways.stripe
      && this.paymentGateways.stripe.publishable_key
    ) {
      const stripe = await loadStripe(
        this.paymentGateways.stripe.publishable_key,
        {
          locale: 'en', // TODO: get from WPML
        },
      );
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas, {
        order_id: this.$route.params.orderId,
        key: this.$route.query.key,
      });
    }
  },
  methods: {
    async addCard() {
      this.$store.commit('SET_CART_LOADING', true);
      await addCardToCustomerSavedMethods(this.$store, {
        stripeInstance: this.paymentMetas.stripe,
      });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    getAvailableActions(method) {
      const actions = ['delete'];
      if (!method.is_default) {
        actions.unshift('make-default');
      }
      return actions;
    },
    async deleteCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      await deletePaymentSource({ payment_source_id: card.token_key });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    async makeDefaultCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      await setDefaultPaymentSource({ payment_source_id: card.token_key });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    openModal(card) {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelCars',
        content: {
          richtext: this.$labels.cancel_card_dialog,
          cta: {
            title: this.$labels.cancel_card,
          },
        },
        fn: () => this.deleteCard(card),
      });
    },
  },
};
</script>

<style lang="scss">
.saved-cards__grid-element-right {
  @include mq(s) {
    text-align: right;
    .cta {
      display: inline-block !important;
    }
  }
}
</style>
