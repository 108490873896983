import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        title: this.$labels.account_details,
        accordion: {
          open: false,
          toggle: false,
        },
        sendLabel: 'save',
      },
      groups: {
        editAccount: {
          visible: true,
          fields: {
            first_name: {
              type: 'text',
              model: null,
              label: null,
              placeholder: 'first_name',
              rules: 'required',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: null,
              label: null,
              placeholder: 'last_name',
              rules: 'required',
              size: 6,
            },
            display_name: {
              type: 'text',
              model: null,
              label: null,
              placeholder: 'display_name',
              size: 6,
            },
            email: {
              type: 'email',
              model: null,
              label: null,
              placeholder: 'email',
              rules: 'required|email',
              readonly: true,
              size: 6,
            },
            old_password: {
              type: 'password',
              model: null,
              label: null,
              placeholder: 'old_password',
              rules: 'required_if:password',
              vid: 'old_password',
            },
            password: {
              type: 'password',
              model: null,
              label: 'password_placeholder',
              placeholder: 'password',
              rules: 'min:6|required_if:old_password',
              vid: 'password',
              size: 6,
            },
            repeatPassword: {
              type: 'password',
              model: null,
              label: null,
              placeholder: 'repeat_password',
              id: 'repeat_password',
              rules: 'required_if:password|confirmed:password',
              vid: 'repeat_password',
              size: 6,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['customer', 'user']),
  },
  watch: {
    customer: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.groups.editAccount.fields.first_name.model = val.first_name && val.first_name !== '' ? val.first_name : null;
          this.groups.editAccount.fields.last_name.model = val.last_name && val.last_name !== '' ? val.last_name : null;
          this.groups.editAccount.fields.display_name.model = this.user.display_name && this.user.display_name !== '' ? this.user.display_name : null;
          this.groups.editAccount.fields.email.model = this.user.user_email && this.user.user_email !== '' ? this.user.user_email : null;
        }
      },
    },
  },
  methods: {
    init() {

    },
    async callback() {
      const body = {
        first_name: this.groups.editAccount.fields.first_name.model,
        last_name: this.groups.editAccount.fields.last_name.model,
        display_name: this.groups.editAccount.fields.display_name.model,
        email: this.groups.editAccount.fields.email.model,
        old_password: this.groups.editAccount.fields.old_password.model,
        password: this.groups.editAccount.fields.password.model,
        repeatPassword: this.groups.editAccount.fields.repeatPassword.model,
      };

      await this.$store.dispatch('editUser', body);
    },
  },
};
