var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"section",class:[
    _vm.layout.section_classes && _vm.layout.section_classes !== ''
      ? _vm.layout.section_classes.split(' ')
      : null,
    { [`layout--${_vm.layout.section_layout}`]: _vm.layout.section_layout },
    { ['product-stack']: _vm.isProductStack },
  ],attrs:{"id":_vm.layout.section_id ? _vm.layout.section_id : null,"tag":"section","template":_vm.sectionSpacer}},[_c('Wrapper',{class:['section-grid', { [`section-grid--${_vm.columns}`]: _vm.columns }]},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }