<template>
  <Spacer
    y="ml"
    boxed
  >
    <!-- <div class="t-center typo--ml title">
      {{ $labels.login }}
    </div> -->

    <Grid grid="1">
      <Form
        id="login"
        class="form--single"
      />
      <Social class="block-theme--default" />

      <Spacer y="m">
        <Flex
          justify="center"
          align="center"
          dir="column"
          rowGap="m"
        >
          <Cta
            class="full-button"
            theme="button-boxed-light"
            :data="{
              title: $labels.lost_password,
              fn: toggleRecoverPassword,
            }"
          />
          <Cta
            class="full-button"
            theme="button-boxed-light"
            :data="{
              title: $labels.register,
              url: '/register',
            }"
          />
        </Flex>
      </Spacer>
      <Form
        v-if="showRecoverPassword"
        id="recovery-password"
        class="form--single"
      />
    </Grid>
  </Spacer>
</template>

<script>
import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import Social from './login-social';

export default {
  name: 'Login',
  components: {
    Form,
    Social,
    Cta,
  },
  data() {
    return {
      showRecoverPassword: false,
    };
  },
  methods: {
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.title{
  margin-bottom: 10px;
}
.spacer{
  width: calc(100vw - 20px);
  margin: auto;
  @include mq(xs){
    width: auto;
    margin: 0;
  }
}
</style>
