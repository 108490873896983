<template>
  <AccordionWrapper class="block-theme--default">
    <AccordionItem :data-order="order.id">
      <template slot="accordion-trigger">
        <Grid
          class="chevron"
          grid="12"
        >
          <Column
            :size="{
              default: 6,
              xs: 3,
            }"
            :class="{ 'typo--m': $mq.isMobileXS }"
          >
            <!-- <span v-if="$mq.isMobileXS">{{ $labels.order }}</span> -->
            {{ $labels.order }} #{{ order.id }}
          </Column>
          <Column
            v-if="$mq.isMobileXS"
            size="6"
            class="typo--m"
          >
            <div>
              {{ orderStatus }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div>
              {{ parseDate(order.created_date) }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <Flex
              align="center"
              class="flex-no-wrap"
            >
              <div>
                {{ orderStatus }}
              </div>
              <Spacer
                v-if="orderActions[0]"
                left="xs"
              >
                <Icon
                  name="alert"
                  size="xs"
                />
              </Spacer>
            </Flex>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div
              v-html="getOrderTotal(order)"
            />
          </Column>
        </Grid>
      </template>
      <template slot="accordion-content">
        <Grid
          col="12"
          row-gap="m"
        >
          <div v-if="$mq.isMobileXS">
            <OrderHeader
              :order="order"
              :theme="theme"
            />
            <div class="separator--row" />
          </div>
          <OrderDetails
            :order="order"
            :theme="theme"
          />
        </Grid>
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseDate,
  parseCurrency,
  availableOrderActions,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';
import Icon from '@/components/ui/icon';

export default {
  name: 'Order',
  components: {
    AccordionWrapper,
    AccordionItem,
    OrderHeader,
    OrderDetails,
    Icon,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      parseCurrency,
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[
        `wc-${this.order.status}`
      ];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {},
  methods: {
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    getOrderTotal(order) {
      return getOrderTotal(order, this.wcSettings);
    },
    // closeOtherOrders() {
    //   document.querySelectorAll('.accordion__trigger--active').forEach((accordion) => {
    //     console.log(Number(accordion.parentElement.dataset.order), this.order.id);
    //     if (Number(accordion.parentElement.dataset.order) !== Number(this.order.id)) {
    //       accordion.click();
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.block-theme--default {
  border-bottom: 1px solid var(--black);
  padding-bottom: 8px;

  --columns: 12;
}

::v-deep {
  .accordion__content > *:last-child {
    padding-bottom: 50px;

    @include mq(m){
      padding-bottom: 100px;
    }
  }
}
</style>
