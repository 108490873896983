var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{class:['archive', _vm.data.archive]},[(_vm.$route.params.slug === 'works' || _vm.$route.meta.slug === 'works')?_c('div',{ref:"filters",staticClass:"filters filters-works"},[_c('Cta',{staticClass:"typo--xs",attrs:{"theme":"filter","data":{ title: _vm.$labels.all_cta, url: '/works/' }}}),_vm._l((_vm.filteredCategories),function(category){return (category.slug !== 'uncategorized')?_c('Cta',{key:category.id,ref:category.slug,refInFor:true,staticClass:"typo--xs",attrs:{"theme":"filter","data":{
        title: category.name,
        url: `/works/category/${category.slug}`,
      }},nativeOn:{"click":function($event){return _vm.filter(category.slug, $event.target)}}}):_vm._e()})],2):_vm._e(),(_vm.data.title)?_c('Spacer',{attrs:{"y":"m"}},[_c('Title',{attrs:{"data":{ value: _vm.data.title }}})],1):_vm._e(),(_vm.items.length > 0)?_c('Grid',{attrs:{"col":"1","row-gap":_vm.template !== 'related' ? (_vm.$mq.isMobileS ? 'm3' : 'l') : 'ml'}},_vm._l(((_vm.$route.params.slug === 'works' || _vm.$route.meta.slug === 'works') &&
      _vm.filteredItems
      ? _vm.filteredItems
      : _vm.items),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c(_vm.thumb(item.type),{tag:"component",attrs:{"data":item}})],1)}),0):_c('div',[_c('Skeleton',{attrs:{"items":_vm.skeletonItems,"cols":_vm.gridCol,"thumb-component":_vm.type}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.data.infinite_scroll),expression:"items && data.infinite_scroll"}],ref:"infinite",staticClass:"infinite"}),(_vm.data.cta)?_c('Cta',{attrs:{"data":_vm.data.cta}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }