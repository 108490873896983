var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"cartpage",attrs:{"use-margin":true}},[(!_vm.firstLoading)?_c('Wrapper',{attrs:{"boxed":""}},[_c('Grid',{attrs:{"col":"12","row-gap":"m"}},[(_vm.isCartEmpty && !_vm.cartLoading)?_c('Wrapper',{attrs:{"size":"s"}},[_c('Spacer',{staticClass:"block-theme--default",attrs:{"y":"l"}},[_c('Spacer',{staticClass:"empty-face typo--l t-center",attrs:{"bottom":"ml"}},[_vm._v("   ༼つ ◕_◕ ༽つ ")]),_c('div',{staticClass:"t-center"},[_c('Spacer',{attrs:{"top":"m","bottom":"m"}},[_vm._v(" "+_vm._s(_vm.$labels.your_cart_empty)+" ")])],1),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{staticClass:"back-button",attrs:{"theme":"button","data":{
                title: _vm.$labels.go_to_shop,
                url: '/shop',
              }}})],1)],1)],1):_c('div',[_c('Grid',{staticClass:"cart-main-grid",attrs:{"col":{
            default: 12,
            m: 3,
          },"align":"start"}},[_c('Column',{staticClass:"cart-product-list",attrs:{"size":"2"}},[_c('Grid',{staticClass:"block-theme--default block-theme--left",attrs:{"col":{
              default: 12,
              s: 6,
            }}},[_c('CartList',{attrs:{"theme":"complete"}})],1),_c('div',{staticClass:"cart-info hide-desktop"},[_c('Accordion',{attrs:{"data":_vm.cartInfo,"theme":"underline"}})],1),_c('Cta',{staticClass:"back-button",attrs:{"theme":"button","data":{
                title: _vm.$labels.go_to_shop,
                url: '/shop',
              }}})],1),_c('Column',{staticClass:"cart__summary",attrs:{"size":"1"}},[_c('Grid',{staticClass:"block-theme--default block-theme--right",staticStyle:{"row-gap":"0"},attrs:{"col":"12"}},[_c('Grid',{staticClass:"block-theme--default",attrs:{"col":"12","align":"start"}},[_c('CartTotals',{attrs:{"theme":"cart"}})],1),_c('Flex',{attrs:{"justify":"end","align":"end"}},[_c('Cta',{attrs:{"theme":"button-boxed w-full","data":{
                  title: _vm.$labels.go_to_checkout,
                  url: '/checkout',
                }}})],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }