<template>
  <div ref="gallery" :class="['gallery', { [`gallery--${theme}`]: theme }]">
    <div v-for="(item, index) in data" :key="index" class="item" :data-item-id="item.id">
      <Figure :ref="index" :data="Object.assign(item, { lazyload: false, object_fit: data.object_fit })"
        :theme="theme ? `gallery--${theme}` : 'gallery'" />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');
require('flickity-fade');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => { },
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        fade: true,
        contain: 'true',
        prevNextButtons: true,
        selectedAttraction: 1,
        friction: 1,
        fade: true,
        pageDots: false,
        arrowShape: 'M10.2455 45.9594C8.01396 48.191 8.01396 51.809 10.2455 54.0406L46.611 90.4061C48.8426 92.6377 52.4607 92.6377 54.6922 90.4061C56.9238 88.1745 56.9238 84.5564 54.6922 82.3249L22.3674 50L54.6922 17.6751C56.9238 15.4436 56.9238 11.8255 54.6922 9.5939C52.4607 7.36234 48.8426 7.36234 46.611 9.5939L10.2455 45.9594ZM14.2861 55.7143H85.7147V44.2857H14.2861V55.7143Z',
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss">
.main {
  position: relative;
}

.gallery {
  overflow-x: clip;
  overflow-y: visible;
  width: 100%;
  height: calc(75vw - (3 / 4 * var(--spacer-m)));
  margin-bottom: var(--spacer-m2);

  @include mq(s) {
    height: calc(50vw - (5 / 4 * var(--spacer-m)));
  }

  .flickity-enabled.is-draggable {
    height: 100%;
  }

  .flickity-viewport {
    overflow: visible;
    height: 100% !important;
  }

  .item {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: var(--spacer-m);
    height: 100%;
    width: 100%;

    @include mq(m) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .flickity-button {
    background: transparent;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .flickity-prev-next-button {
    top: unset;
    transform: none;
    width: 24px;
    height: 24px;
    z-index: 9;
  }

  .flickity-prev-next-button.previous {
    left: -6px;
    right: unset;
  }

  .flickity-prev-next-button.next {
    left: 22px;
    right: unset;
  }

  &--product {
    width: 100%;
    height: calc((100vw - 2 * var(--spacer-m))/ 3 * 4);
    margin-bottom: 0;


    .item {
      display: block;
    }

    .flickity-viewport {
      overflow: visible;
      height: 100%;
    }

    .flickity-prev-next-button.previous {
      right: 22px;
      left: unset;
      bottom: -5px;
      transform: translateY(100%);
    }

    .flickity-prev-next-button.next {
      right: -6px;
      left: unset;
      bottom: -5px;
      transform: translateY(100%);
    }
  }
}
</style>
