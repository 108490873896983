var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cta',
    `cta-theme--${_vm.theme}`,
    _vm.data.size ? `typo--${_vm.data.size}` : null,
    _vm.disabled ? 'cta-disabled' : null,
    {'android': _vm.isAndroid }
  ]},[(_vm.data.fn)?[_c('button',{on:{"click":function($event){!_vm.disabled ? _vm.data.fn() : null}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.modal)?[_c('button',{on:{"click":_vm.openModal}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.hash)?[_c('button',{on:{"click":_vm.goTo}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.external)?[_c('a',{attrs:{"href":_vm.data.url,"target":_vm.data.target,"download":_vm.download_name}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.email)?[_c('a',{attrs:{"href":`mailto:${_vm.data.url}`}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{attrs:{"to":_vm.url}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }