<template>
  <nav>
    <Cta
      v-for="item in navigation"
      :key="item.id"
      theme="difference"
      :class="[...item.css]"
      :data="{
        url: item.url,
        title: item.content,
        target: item.target,
      }"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
  nav {
    column-gap: var(--column-gap-m);
    display: grid;
    font-weight: bold;

    @include mq(s) {
      grid-auto-flow: column;
    }
  }

  .cta {
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.4;
    }
  }

  .menu__mobile {
    .flex {
      flex-direction: column;
      row-gap: 0;
    }
    .cta {
      transition: opacity 0.2s ease;
      margin: 0;
    }
  }
</style>
