<template>
  <Grid
    col="12"
    class="order-details"
  >
    <Flex
      v-for="(orderItem, index) in order.items"
      :key="index"
      justify="space-between"
    >
      <Flex column-gap="m">
        <div
          class="wc-product-image wc-product-image--l"
          :style="{
            backgroundImage: `url(${orderItem.product.gds_featured_image ? orderItem.product.gds_featured_image.sizes.medium : ''})`
          }"
        />
        <div>
          <del v-if="orderItem.qty_refunded_for_item < 0">{{ orderItem.quantity - orderItem.qty_refunded_for_item }}</del>
          {{ orderItem.quantity }} x <router-link
            :to="orderItem.product.permalink"
          >
            {{ orderItem.item_name }}
          </router-link>
        </div>
      </Flex>
      <div
        class="t-right"
        v-html="getTaxedValueLine(orderItem)"
      />
    </Flex>
    <div class="separator--row" />
    <Flex
      justify="space-between"
    >
      <div>{{ $labels.subtotal }}</div>
      <div
        class="t-right"
        v-html="subtotal"
      />
    </Flex>
    <Flex
      justify="space-between"
    >
      <div>{{ $labels.shipping }}</div>
      <div
        class="t-right"
        v-html="shipping"
      />
    </Flex>
    <Flex
      v-if="order.discount_total > 0"
      justify="space-between"
    >
      <div>{{ $labels.discount }}</div>
      <div>-<span v-html="discount" /></div>
    </Flex>
    <Flex
      v-if="taxDisplayCart === 'excl'"
      justify="space-between"
    >
      <div>{{ $labels.vat }}</div>
      <div v-html="vat" />
    </Flex>
    <Flex
      v-if="order.total_refunded > 0"
      justify="space-between"
    >
      <div>{{ $labels.refunded }}</div>
      <div>-<span v-html="parseCurrency(order.total_refunded)" /></div>
    </Flex>
    <Flex
      v-if="paymentGateway"
      justify="space-between"
    >
      <div>
        <div>{{ $labels.payment_method }}</div>
        <BanksDetails v-if="order.payment_method === 'bacs'" />
      </div>
      <div>
        {{ paymentGateway }}
      </div>
    </Flex>
    <div class="separator--row" />
    <Flex
      justify="space-between"
      class="typo--m"
    >
      <div>{{ $labels.total }}</div>
      <div v-html="total" />
    </Flex>
    <Spacer top="m">
      <div
        top="m"
        class="separator--row"
      />
    </Spacer>

    <Grid>
      <Column size="6">
        <Spacer
          bottom="m"
          class="typo--m"
        >
          {{ $labels.billing_address }}
        </Spacer>
        {{ order.billing.first_name }} {{ order.billing.last_name }}
        <br>
        <span v-if="order.billing.company">
          {{ order.billing.company }}
          <br>
        </span>
        {{ order.billing.address_1 }}
        <br>
        <span v-if="order.billing.address_2">
          {{ order.billing.address_2 }}
          <br>
        </span>
        {{ order.billing.postcode }}
        <br>
        {{ order.billing.city }}, {{ geoData.states ? geoData.states[order.billing.country][order.billing.state] : order.billing.state }}
        <br>
        {{ geoData.countries ? geoData.countries[order.billing.country] : order.billing.country }}
        <br>
        <a :href="'mailto:'+order.billing.email">{{ order.billing.email }}</a>
        <br>
        <a :href="'tel:'+order.billing.phone">{{ order.billing.phone }}</a>
        <br>
      </Column>
      <Column size="6">
        <Spacer
          bottom="m"
          class="typo--m"
        >
          {{ $labels.shipping_address }}
        </Spacer>
        {{ order.shipping.first_name }} {{ order.shipping.last_name }}
        <br>
        <span v-if="order.shipping.company">
          {{ order.shipping.company }}
          <br>
        </span>
        {{ order.shipping.address_1 }}
        <br>
        <span v-if="order.shipping.address_2">
          {{ order.shipping.address_2 }}
          <br>
        </span>
        {{ order.shipping.postcode }}
        <br>
        {{ order.shipping.city }}, {{ geoData.states ? geoData.states[order.shipping.country][order.shipping.state] : order.shipping.state }}
        <br>
        {{ geoData.countries ? geoData.countries[order.shipping.country] : order.shipping.country }}
        <br>
      </Column>
    </Grid>
    <Spacer
      v-if="order.customer_note || (order.notes && order.notes[0])"
      top="m"
    >
      <div class="separator--row" />
    </Spacer>
    <Spacer
      v-if="order.customer_note"
    >
      <div class="typo--m">
        {{ $labels.additional_information }}
      </div>
      <Spacer y="xs">
        {{ order.customer_note }}
      </Spacer>
    </Spacer>
    <Spacer
      v-if="order.notes && order.notes[0]"
      :top="order.customer_note ? 'm' : false"
    >
      <Spacer
        y="xs"
        class="typo--m"
      >
        {{ $labels.order_updates }}
      </Spacer>
      <div
        v-for="(note) in order.notes"
        :key="note.id"
      >
        <Spacer y="xs">
          <div>{{ parseDateTime(note.date_created.date) }}</div>
          <div>{{ note.content }}</div>
        </Spacer>
      </div>
    </Spacer>
    <Spacer
      v-if="orderActions && orderActions[0] && theme === 'list'"
      top="m"
    >
      <Flex
        justify="center"
        align="center"
        column-gap="m"
      >
        <div
          v-for="(orderAction, i) in orderActions"
          :key="i"
        >
          <Cta
            v-if="orderAction === 'pay'"
            theme="button"
            :data="{
              title: $labels.go_to_payment,
              url: { name: 'PayOrder', params: {
                orderId: order.id,
              }, query: {
                pay_for_order: true,
                key: order.key
              }},
            }"
          />
          <Cta
            v-if="orderAction === 'cancel'"
            theme="warning"
            :data="{
              title: $labels.cancel_order,
              fn: openModal
            }"
          />
        </div>
      </Flex>
    </Spacer>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseCurrency, unparseCurrency, parseDateTime, availableOrderActions, getOrderTotal,
} from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import BanksDetails from '@/components/wc/banks-details.vue';

export default {
  name: 'OrderDetails',
  components: {
    Cta,
    BanksDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways', 'geoData']),
    total() {
      return getOrderTotal(this.order, this.wcSettings);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.order) {
        let linesTotals = 0;
        let linesTaxs = 0;
        this.order.items.forEach((element) => {
          linesTotals += Number(element.total);
          linesTaxs += Number(element.total_tax);
        });
        if (this.taxDisplayCart === 'excl') {
          return `${this.parseCurrency(Number(linesTotals))}`;
        }
        return this.parseCurrency(Number(linesTotals) + Number(linesTaxs));
      }
      return null;
    },
    discount() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.discount_total));
        }
        return this.parseCurrency(Number(this.order.discount_total) + Number(this.order.discount_tax));
      }
      return null;
    },
    shipping() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.shipping_total));
        }
        return this.parseCurrency(Number(this.order.shipping_total) + Number(this.order.shipping_tax));
      }
      return null;
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return this.order.payment_method.charAt(0).toUpperCase() + this.order.payment_method.slice(1);
      }
      return null;
    },
    vat() {
      return this.parseCurrency(Number(this.order.total_tax));
    },
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[`wc-${this.order.status}`];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {

  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.order.currency);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    getTaxedValueLine(row) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(Number(row.total) + Number(row.total_tax));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(Number(row.total))}`;
      }
      return `${this.parseCurrency(Number(row.total))} + ${this.parseCurrency(Number(row.total_tax))} VAT`;
    },
    parseDateTime(date) {
      return parseDateTime(date, this.wcSettings);
    },
    async cancelOrder() {
      await this.$store.dispatch('cancelOrder', {
        order_id: this.order.id,
        key: this.order.key,
      });
      await this.$store.dispatch('getOrders');
    },
    openModal() {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelOrder',
        content: {
          richtext: this.$labels.cancel_order_dialog,
          cta: {
            title: this.$labels.cancel_order,
          },
        },
        fn: this.cancelOrder,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-details {
  --columns: 12;
}
</style>
