<template>
  <Spacer x="m">
    <main class="project">
      <div class="project-title">
        <Spacer bottom="m" v-if="post.excerpt.rendered">
          <Richtext :data="{ value: post.excerpt.rendered, size: 'xl' }" />
        </Spacer>
        <Spacer bottom="m" v-if="post.title.rendered || post.gds_taxonomies.categories">
          <Flex dir="row" column-gap="m">
            <Title :data="{
              value: post.title.rendered,
              size: 'xs'
            }" />
            <div v-for="category in post.gds_taxonomies.categories">
              <div v-if="category.name !== 'Uncategorized'" class="typo--xs">
                {{ category.name }}
              </div>
            </div>
          </Flex>
        </Spacer>
      </div>

      <Section v-for="(section, parentIndex) in post.acf.sections" :key="parentIndex" :layout="layout(section)"
        :columns="section.layout" :class="`section--${parentIndex}`" :isProductStack="isBlockProduct(section)">
        <Spacer v-for="(block, index) in blocks(section)" :key="index"
          :x="section.layout === 'default' ? block.block_spacer : false" :class="[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
            { [`block--span-${block.columns}`]: block.columns },
          ]">
          <component :is="components.get(block.acf_fc_layout)" :data="{ ...block, sectionLayout: section.layout }" />
        </Spacer>
      </Section>

      <div class="project-related">
        <Spacer template="section-large" />
        <Archive template="related" :data="{
          title: 'Related works',
          archive_type: 'archive',
          archive: 'project',
          infinite_scroll: false,
          exclude: [post.id],
          max_posts: 3,
          archive_category: false,
          layout: [
            {
              media_query: 'default',
              layout_size: '6',
              layout_type: 'grid',
            },
            {
              media_query: 'm',
              layout_size: '4',
              layout_type: 'grid',
            },
          ],
        }" />
      </div>
    </main>
  </Spacer>
</template>
  
<script>
import data from '@/mixins/data';
import components from '@/assets/js/components';
import Section from '@/components/section';
import Archive from '@/components/blocks/archive';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';


export default {
  name: 'SingleProject',
  components: {
    Archive,
    Section,
    Title,
    Richtext
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  computed: {
    ratio() {
      const value = (4 / 3) * 100;
      return value;
    },
  },
  methods: {
    layout(section) {
      const {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      } = section;
      return {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      };
    },
    blocks(section) {
      const { layout } = section;
      let value = section.blocks;

      if (layout && layout !== 'default') {
        value = section.blocks_three_columns;
      }

      return value;
    },
    isBlockProduct(section) {
      let value = true;
      let blocks = section.blocks ? section.blocks : section.blocks_three_columns.length ? section.blocks_three_columns : section.blocks_four_columns.length ? section.blocks_four_columns : undefined;
      if (blocks) {
        for (var i = 0; i < blocks.length; i++) {
          if (blocks[i].acf_fc_layout !== 'product') {
            value = false;
          }
        }
      }
      else {
        value = false;
      }
      return value;
    }
  },
};
</script>

<style lang="scss">
.project {
  .project-title {
    mix-blend-mode: difference;
    position: sticky;
    top: 30px;
    background: transparent;
    color: white;
    z-index: 2;
  }

  .project-related {
    @include mq($until: m) {
      .item:last-child {
        display: none;
      }
    }
  }
}
</style>


  