import { render, staticRenderFns } from "./archive.vue?vue&type=template&id=5ecf1671&scoped=true"
import script from "./archive.vue?vue&type=script&lang=js"
export * from "./archive.vue?vue&type=script&lang=js"
import style0 from "./archive.vue?vue&type=style&index=0&id=5ecf1671&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecf1671",
  null
  
)

export default component.exports