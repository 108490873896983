var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Spacer',{staticClass:"checkout__header",attrs:{"x":"m"}},[_c('Flex',{attrs:{"justify":"space-between","align":"center"}},[_c('router-link',{staticClass:"checkout__back typo--label-m",attrs:{"to":"/cart"}},[_c('Flex',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$labels.back_to_cart)+" ")])],1),_vm._v(" "+_vm._s(_vm.$labels.checkout)+" ")],1)],1),(!_vm.firstLoading)?_c('Wrapper',{staticClass:"checkout__content",attrs:{"boxed":""}},[_c('Grid',{attrs:{"align":"start","col":{
            default: 12,
            m: 3,
          }}},[_c('Column',{attrs:{"size":"2"}},[_c('Grid',{staticClass:"checkout__left",attrs:{"col":"12"}},[(_vm.notice)?_c('div',{staticClass:"notice",domProps:{"innerHTML":_vm._s(_vm.notice)}}):_vm._e(),_c('div',{staticClass:"account"},[_c('AccordionWrapper',[_c('AccordionItem',{staticClass:"block-theme--default"},[_c('template',{slot:"accordion-trigger"},[_c('div',[(_vm.user)?_c('div',{staticClass:"chevron"},[_vm._v(" "+_vm._s(_vm.$labels.welcome_back)+" "+_vm._s(_vm.userNiceName)+". ")]):_c('div',{staticClass:"chevron"},[_vm._v(" "+_vm._s(_vm.$labels.do_you_have_account)+" ")])])]),_c('template',{slot:"accordion-content"},[(_vm.user)?_c('div',[_c('Spacer',[_vm._v(" "+_vm._s(_vm.$labels.logged_in_with_mail)+" "),_c('span',[_vm._v(_vm._s(_vm.user.user_email))]),_vm._v(". ")])],1):_c('Grid',{staticClass:"grid-login",attrs:{"grid":"1","row-gap":"m"}},[_c('Form',{attrs:{"id":"login","theme":"login-checkout"}}),_c('LoginSocial'),_c('Spacer',[_c('Grid',{staticClass:"grid-login",attrs:{"grid":"1","row-gap":"m"}},[_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{staticClass:"full-button",attrs:{"theme":"button-boxed-light","data":{
                            title: _vm.$labels.lost_password,
                            fn: _vm.toggleRecoverPassword,
                          }}})],1),(_vm.showRecoverPassword)?_c('Form',{attrs:{"id":"recovery-password"}}):_vm._e()],1)],1)],1)],1)],2)],1)],1),_c('Form',{ref:"form",staticClass:"form--multi",attrs:{"id":"checkout"}})],1)],1),_c('div',{staticClass:"checkout__right"},[_c('CheckoutOrder')],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }