<template>
  <Spacer
    y="ml"
    class="min-height"
  >
    <nav class="menu-user">
      <Flex
        column-gap="s"
        justify="center"
      >
        <Cta
          theme="button"
          :data="{
            title: $labels.account_details,
            url: {name: 'EditAccount'},
          }"
        />
        <Cta
          theme="button"
          :data="{
            title: $labels.orders,
            url: {name: 'Orders'},
          }"
        />
        <Cta
          theme="button"
          :data="{
            title: $labels.logout,
            url: {name: 'Account'},
            fn: logout
          }"
        />
      </Flex>
    </nav>
  </Spacer>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  name: 'MenuUser',
  components: {
    Cta,
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.title{
  margin-bottom: 10px;
}
.min-height{
  padding-bottom: 30px;
}
</style>
