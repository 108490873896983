<template>
  <Grid
    v-if="orders && orders[0]"
    col="12"
  >
    <Order
      v-for="order in orders"
      :key="order.order_id"
      :order="order"
      theme="list"
    />
  </Grid>

  <Grid
    v-else-if="!loading"
    col="12"
    class="block-theme--default"
  >
    <div class="no-order">
      {{ $labels.no_order }}
    </div>
    <br>
    <Flex justify="center">
      <Cta
        :data="{
          title: `${$labels.go_to} ${$labels.shop}`,
          url: '/shop',
        }"
        theme="button"
        class="button-goback"
      />
    </Flex>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Order from '@/components/wc/order';
import Cta from '@/components/typo/cta';

export default {
  name: 'Orders',
  components: {
    Order,
    Cta,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'orders']),
  },
  async mounted() {
    await this.$store.dispatch('getOrders');
    this.loading = false;
  },
};
</script>
<style lang="scss">
  .no-order {
    text-align: center;
  }
  .button-goback {
    text-decoration: underline;
  }
</style>
