export default {
  data() {
    return {
      settings: {
        sendLabel: 'add_coupon',
      },
      groups: {
        coupon: {
          visible: true,
          fields: {
            coupon: {
              size: 12,
              fields: {
                coupon: {
                  type: 'text',
                  model: null,
                  label: 'Coupon',
                  placeholder: 'insert_coupon',
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    init() {
    },
    async callback() {
      this.$store.dispatch('addCoupon', {
        coupon: this.groups.coupon.fields.coupon.model,
      });
    },
  },
};
