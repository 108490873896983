<template>
  <component
    :is="components[data.type]"
    v-if="data[data.type]"
    :data="Object.assign(data[data.type], { lazyload: data.lazyload, ratio: data.ratio, object_fit: data.object_fit ? 'cover' : 'contain', columns: data.columns, layout: data.sectionLayout })"
  />
</template>

<script>
import { Figure, Video, Gallery } from "@/components/media";

export default {
  name: "MediaSection",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: {
        default: Figure,
        image: Figure,
        video: Video,
        gallery: Gallery,
      },
    };
  },
};
</script>

<style></style>
