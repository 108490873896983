<template>
  <div :class="['thumb']">
    <router-link
      class="thumb__link"
      :to="data.link || data.permalink"
    >
      <Media
        v-if="data.gds_featured_image"
        class="media thumb__image"
        :data="{
          type: 'image',
          image: data.gds_featured_image,
          ratio: ratio,
          lazyload: true,
          object_fit: 'cover',
        }"
      />
    </router-link>
    <div class="thumb__text">
      <router-link
        class="thumb__link"
        :to="data.link || data.permalink"
      >
      <Flex dir="row" column-gap="m">
        <div class="thumb__title-container">
          <Title
            :data="{
              value: data.taxonomy || data.name ? data.name : data.title.rendered,
              size:'xs'
            }"
          />
        </div>
        <div v-for="category in data.gds_taxonomies.categories">
          <div v-if="category.name !== 'Uncategorized'" class="typo--xs">
            {{ category.name }}
          </div>
        </div>
      </Flex>
        <Spacer top="xxs">
          <Richtext
            v-if="data.excerpt"
            :data="{ value: data.excerpt.rendered, size: 'l' }"
            class="thumb__description"
          />
        </Spacer>
      </router-link>
    </div>
  </div>
</template>

<script>
import { isOos } from '@/assets/js/utils-wc';


import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'ProjectThumb',
  components: {
    Media,
    Title,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'link',
    },
    theme: {
      type: String,
      default: '',
    },
    sectionLayout: {
      type: String,
      default: '',
    },
  },
  computed: {
    isOos() {
      return isOos(this.data);
    },
    ratio() {
      let value = (5 / 4) * 100;

      return value;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .thumb {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: var(--column-gap-m);
    min-height: 100%;
    grid-template-rows: auto 1fr;

    > * {
      grid-column: 1 / -1;
    }

    &__text {
      gap: var(--column-gap-m);
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      margin-top: var(--spacer-s);
      row-gap: var(--spacer-m2);
      grid-template-rows: 1fr auto;

    }

    &__title-container {
      display: grid;
      grid-auto-flow: column;
      // grid-auto-rows: min-content;
      column-gap: var(--spacer-m2);
      grid-template-columns: auto auto;
    }
  }
  </style>
