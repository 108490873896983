<template>
  <Spacer
    class="thank-you"
    :use-margin="true"
    bottom="m"
  >
    <Wrapper
      v-if="order"
      boxed
    >
      <Grid
        grid="12"
        class="thank-you-grid"
        :col="{ default: 12, m: 6 }"
      >
        <div class="typo--l">
          <Spacer bottom="m">
            {{ order.status !== 'failed' ? $labels.thank_you : $labels.thank_you_error }}
          </Spacer>
        </div>
        <Grid
          class="block-theme--default"
          grid="1"
        >
          <Grid>
            <div>
              <Spacer bottom="m">
                {{ order.status !== 'failed' ? $labels.order_received : $labels.order_failed }}.
              </Spacer>
              <OrderHeader :order="order" />
            </div>
          </Grid>
          <Grid>
            <div>
              <OrderDetails :order="order" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';

export default {
  name: 'ThankYou',
  components: {
    OrderDetails,
    OrderHeader,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways']),
    total() {
      return this.parseCurrency(Number(this.order.total));
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return this.order.payment_method.charAt(0).toUpperCase() + this.order.payment_method.slice(1);
      }
      return null;
    },
  },
  async mounted() {
    this.$store.dispatch('clearCart');
    const { data } = await this.$store.dispatch('getOrder', {
      key: this.$route.query.key,
      order_id: this.$route.params.orderId,
    });
    this.order = data;
    this.$store.dispatch('loadCustomerData');
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.thank-you {
  margin-top: 30px;

  .block-theme--default {
    @include mq($until: m) {
      margin-top: 10px;
    }

    & > :first-child {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--black);
    }
  }

  .thank-you-grid > * {
    border-top: 1px solid var(--black);
    padding-top: 8px;
  }
}
</style>
