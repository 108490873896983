<template>
  <Spacer :class="['archive', data.archive]">
    <div v-if="$route.params.slug === 'works' || $route.meta.slug === 'works'" ref="filters" class="filters filters-works">
      <Cta theme="filter" class="typo--xs" :data="{ title: $labels.all_cta, url: '/works/' }" />
      <Cta v-for="category in filteredCategories" v-if="category.slug !== 'uncategorized'" class="typo--xs"
        :ref="category.slug" :key="category.id" theme="filter" :data="{
          title: category.name,
          url: `/works/category/${category.slug}`,
        }" @click.native="filter(category.slug, $event.target)" />
    </div>
    <Spacer v-if="data.title" y="m">
      <Title :data="{ value: data.title }" />
    </Spacer>
    <Grid v-if="items.length > 0" col="1" :row-gap="template !== 'related' ? ($mq.isMobileS ? 'm3' : 'l') : 'ml'">
      <div v-for="item in ($route.params.slug === 'works' || $route.meta.slug === 'works') &&
        filteredItems
        ? filteredItems
        : items" :key="item.id" class="item">
        <component :is="thumb(item.type)" :data="item" />
      </div>
    </Grid>
    <div v-else>
      <Skeleton :items="skeletonItems" :cols="gridCol" :thumb-component="type" />
    </div>

    <div v-show="items && data.infinite_scroll" ref="infinite" class="infinite" />

    <Cta v-if="data.cta" :data="data.cta" />
  </Spacer>
</template>

<script>
import archive from '@/mixins/archive';
import { mapGetters } from 'vuex';

import Thumb from '@/components/thumb';
import ThumbProduct from '@/components/thumb/product';
import ThumbProject from '@/components/thumb/project';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Skeleton from '@/components/ui/skeleton';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('product', ThumbProduct);
thumbs.set('simple', ThumbProduct);
thumbs.set('variable', ThumbProduct);
thumbs.set('subscription', ThumbProduct);
thumbs.set('grouped', ThumbProduct);
thumbs.set('project', ThumbProject);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    Skeleton,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
    template: {
      type: String,
      default: () => { },
    },
  },
  data() {
    return {
      filteredItems: null,
    };
  },
  watch: {
    $route(value) {
      if (value.params.slug === 'works') {
        this.resetFilters();
      }
    },
  },
  computed: {
    ...mapGetters(['projectCategories']),
    filteredCategories() {
      const categories = { ...this.projectCategories };
      if (categories.shop) {
        delete categories.shop;
      }
      if (categories.hidden) {
        delete categories.hidden;
      }

      return Object.values(categories);
    },
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
              layout_size === 'auto' ? (12 / this.items.length).toString() : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          m: 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
  methods: {
    thumb(key) {
      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
    filter(category, target) {
      const element = this.$refs.filters;
      element.scrollTo({
        left: target.getBoundingClientRect().x - 10,
        behavior: 'smooth',
      });

      this.filteredItems = this.items.filter((item) => item.gds_taxonomies.categories?.some((productCategory) => productCategory.slug === category));
      this.$bus.$emit('lazyload');
    },
    resetFilters() {
      const element = this.$refs.filters;
      element.scrollTo({
        left: -10,
        behavior: 'smooth',
      });
      this.filteredItems = null;
      this.$bus.$emit('lazyload');
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  visibility: hidden;
}

.item {
  grid-column: span 6;
  position: relative;

  @include mq(m) {
    grid-column: span 4;
  }
}

.archive.product {
  .item {
    grid-column: span 6;

    @include mq(m) {
      grid-column: span 3;
    }
  }
}

.filters {
  column-gap: var(--column-gap-m);
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  overflow-x: auto;
  padding-bottom: var(--spacer-m);
  max-width: unset;
  margin-left: auto;
  margin-right: auto;

  @include mq(m) {
    max-width: max-content;
  }
}

.filters-works {
  color: black;
  background-color: white;
  position: sticky;
  top: 30px;
  z-index: 1;

  @include mq(m) {
    color: white;
    background-color: transparent;
    mix-blend-mode: difference;
  }
}
</style>
