<template>
  <header
    v-show="!$route.meta.hideHeader"
    :class="[
      $route.name === 'Index' ? 'header__home' : null,
      scrolled ? 'header__scroll' : false,
      menuOpen ? 'header__menu' : false,
    ]"
  >
    <Spacer
      x="m"
      y="s"
    >
      <Flex justify="space-between">
        <Cta
          class="site-name"
          :data="{ title: $store.state.wp.site.name, url: '/' }"
        />
        <div class="menu__desktop menu__desktop--nav">
          <Menu />
        </div>
        <div class="menu__desktop">
          <WcMenu />
        </div>
        <div
          class="button__mobile"
          @click="openMenu"
        >
          <span v-if="!menuOpen">Menu</span>
          <span v-if="menuOpen">Close</span>
        </div>
        <div
          v-if="menuOpen"
          class="menu__mobile"
        >
          <Menu />
          <WcMenu />
        </div>
      </Flex>
    </Spacer>
  </header>
</template>

<script>
import Menu from '@/components/ui/menu';
import WcMenu from '@/components/wc/wc-menu';
import Cta from '@/components/typo/cta';

export default {
  name: 'Header',
  components: {
    Menu,
    WcMenu,
    Cta,
  },
  data() {
    return {
      menuOpen: false,
      scrolled: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.menuOpen = false;
      }
    },
  },
  mounted() {
    // const headerHome = document.querySelector('.header__home');
    // if (headerHome) {
    //   this.changeHeader();
    //   document.addEventListener('scroll', this.changeHeader);
    // }

    this.$aion.add(this.checkScroll, 'checkScroll', true);
  },
  beforeDestroy() {
    this.$aion.remove('checkScroll');
  },
  methods: {
    checkScroll() {
      if (this.$mq.isMobileS) {
        this.scrolled = window.pageYOffset > 0;
      }
    },
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
    changeHeader() {
      const currentScrollPos = window.pageYOffset;
      const bigLogo = document.querySelector('.nm3__logo');
      const headerHome = document.querySelector('.header__home');
      if (headerHome) {
        if (currentScrollPos > bigLogo.offsetHeight + 20) {
          headerHome.classList.add('header__home--full');
        } else {
          headerHome.classList.remove('header__home--full');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;

  width: 100%;
  z-index: 100;
  // background: white;
  @include mq(s) {
    color: white;
    background: rgba(0, 0, 0, 0);
    mix-blend-mode: difference;
  }

  &.header__scroll {
    @include mq($until: s) {
      background: white;
    }
  }

  &.header__menu {
    @include mq($until: s) {
      background: white;
    }
  }
}

.menu__desktop {
  display: none;
  @include mq(s) {
    display: block;
  }
  &--nav {
    @include mq(s) {
      position: fixed;
      pointer-events: none;
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      justify-content: center;
      nav {
        pointer-events: auto;
      }
      .cta {
        pointer-events: auto;
      }
    }
  }
}
.button__mobile {
  cursor: pointer;
  @include mq(s) {
    display: none;
  }
}

.menu__mobile {
  @extend %typo--menu-mobile;
  background: white;
  position: absolute;
  mix-blend-mode: revert;
  height: calc(var(--vh, 1vh) * 100);
  left: 0px;
  top: 30px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  @include mq(s) {
    display: none;
  }

  ::v-deep .cta {
    a,
    button {
      font-weight: 400;
    }

    .router-link-active {
      border-bottom: initial;
    }
  }
}
</style>
