<template>
  <Spacer
    :use-margin="true"
    class="cartpage"
  >
    <Wrapper
      v-if="!firstLoading"
      boxed
    >
      <Grid
        col="12"
        row-gap="m"
      >
        <!-- <div class="cart__label t-center typo--m">
          {{ $labels.your_cart }}
        </div> -->
        <!-- Cart -->
        <Wrapper
          v-if="isCartEmpty && !cartLoading"
          size="s"
        >
          <Spacer
            y="l"
            class="block-theme--default"
          >
            <Spacer
              class="empty-face typo--l t-center"
              bottom="ml"
            >
                ༼つ ◕_◕ ༽つ
            </Spacer>
            <div class="t-center">
              <Spacer
                top="m"
                bottom="m"
              >
                {{ $labels.your_cart_empty }}
              </Spacer>
            </div>
            <Flex justify="center">
              <Cta
                class="back-button"
                theme="button"
                :data="{
                  title: $labels.go_to_shop,
                  url: '/shop',
                }"
              />
            </Flex>
          </Spacer>
        </Wrapper>
        <div v-else>
          <Grid
            :col="{
              default: 12,
              m: 3,
            }"
            align="start"
            class="cart-main-grid"
          >
          <Column size="2" class="cart-product-list" >
            <Grid
              :col="{
                default: 12,
                s: 6,
              }"
              class="block-theme--default block-theme--left"
            >
              <CartList theme="complete" />
            </Grid>
            <div class="cart-info hide-desktop">
              <Accordion :data="cartInfo" theme="underline"></Accordion>
            </div>
            <Cta
                class="back-button"
                theme="button"
                :data="{
                  title: $labels.go_to_shop,
                  url: '/shop',
                }"
              />
          </Column>

          <Column size="1" class="cart__summary">

            <Grid
              col="12"
              class="block-theme--default block-theme--right"
              style="row-gap: 0;"
            >
              <Grid
                col="12"
                class="block-theme--default"
                align="start"
              >
                <CartTotals theme="cart" />
              </Grid>
              <Flex
                justify="end"
                align="end"
              >
                <Cta
                  theme="button-boxed w-full"
                  :data="{
                    title: $labels.go_to_checkout,
                    url: '/checkout',
                  }"
                />
              </Flex>
            </Grid>
          </Column>
          </Grid>
        </div>
        <!-- <div class="block-theme--default">
          <div>
            {{ $labels.may_be_interested_in }}
          </div>
          <div>
            <Grid>
              <Column
                v-for="(item, index) in products"
                :key="index"
                :size="{
                  default: 12,
                  s: 6,
                  m: 3,
                }"
              >
                <ThumbProduct :data="item" />
              </Column>
            </Grid>
          </div>
        </div> -->
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';


// import {
//   cancelOrder,
// } from '@/api';

import Cta from '@/components/typo/cta';
import CartList from '@/components/wc/cart-list';
import CartTotals from '@/components/wc/cart-totals';
import Accordion from '@/components/blocks/accordion';
const { options } = __VUE_WORDPRESS__.state;



export default {
  name: 'Cart',
  components: {
    Cta,
    CartList,
    CartTotals,
    Accordion
  },
  data() {
    return {
      firstLoading: true,
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    cartInfo() {
      let obj = {
        items: [
        {title_trigger: this.$labels.payment_shipping, rich_text: options.extra?.cart_payments_shipping?.value || '' }, 
        {title_trigger: this.$labels.terms_conditions, rich_text: options.extra?.cart_terms_conditions?.value || ''},
        {title_trigger: this.$labels.contacts, rich_text: options.extra?.cart_contacts?.value || ''}
        ]
      }
      return obj;
    }
  },
  watch: {
    cartLoading() {
      if (this.cartLoading === false) {
        this.firstLoading = false;
      }
    },
  },
  async mounted() {
    // Ordine da cancellare
    if (
      this.$route.query.cancel_order === 'true'
      && this.$route.query.order
      && this.$route.query.order_id
    ) {
      await this.$store.dispatch('cancelOrder', {
        order_id: this.$route.query.order_id,
        key: this.$route.query.order,
      });
    }
    // Altrimenti mostra carrello<
    await this.$store.dispatch('getCart');
    const request = {
      type: 'product',
      params: {
        // include: [this.cart.items.map((x) => x.product_id)],
        per_page: 100,
        offset: 0,
        lang: this.$store.state.lang,
      },
    };
    this.products = await this.$store.dispatch('getItems', request);
    // Marketing
    this.$bus.$emit('viewCart', this.cart);
  },
};
</script>

<style lang="scss">
.cartpage{
  margin-top: 30px;
}

.cart__label{
  text-align: left;
}

.border__bottom {
  padding-bottom: 8px;
  border-bottom: 1px solid black;
}

.block-theme--right {
  @include mq(m) {
    top: 30px;
    position: sticky;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100 - 30px);
  }
}

.block-theme--left,
.block-theme--right {
  border-top: 1px solid black;
  padding: var(--spacer-m) 0;
}
</style>

<style lang="scss" scoped>

.cart-main-grid {
  column-gap: 0;
  @include mq(m) {
    column-gap: var(--column-gap-m);
  }
}

.cart-product-list {
  padding-bottom: calc(var(--vh, 1vh) * 50);
  @include mq(m) {
    padding-bottom: unset;
  }
}

.block-theme--left {
  border-bottom: 1px solid black;
}
.button__border {
  @include mq($until: m) {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.cart__summary {
  position: fixed;
  bottom: 0;
  width: calc(100% - 2 * var(--spacer-m));
  grid-column-start: 1;
  left:  var(--spacer-m);
  right: unset;
  background-color: var(--white);

  @include mq(m) {
    grid-column-start: 4;
    right: var(--column-gap-m);
    left: unset;
    width: calc(25% -  var(--column-gap-m));
  }
}

.w-full {
  width: 100%;
}

.back-button, .cart-info {
  margin: var(--spacer-s) 0;
}

.back-button {
  text-decoration: underline;
}

.hide-desktop {
  display: block;
  @include mq(m) {
    display: none;
  }
}
</style>
