<template>
  <Thumb
    v-if="product"
    theme="block"
    :section-layout="data.sectionLayout"
    :data="{ ...product, columns: data.columns }"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Thumb from '@/components/thumb/product';

export default {
  components: {
    Thumb,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      product: null,
    };
  },
  computed: {
    ...mapGetters(['lang']),
  },
  mounted() {
    if (this.data.post.slug) {
      this.$store
        .dispatch('getSingleBySlug', {
          type: 'product',
          slug: this.data.post.slug,
          lang: this.lang,
        })
        .then((product) => {
          this.$bus.$emit('lazyload');
          this.product = product;
        });
    }
  },
};
</script>

<style></style>
