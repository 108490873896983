<template>
  <nav>
    <Flex gap>
      <!-- <Cta
        :data="{
          title: !user ? $labels.login : $labels.account,
          url: { name: 'EditAccount' },
        }"
      /> -->
      <Cta
        :data="{
          title: cart && cart.items ? `${$labels.cart} (${cart.item_count})` : $labels.cart,
          url: '/cart'
        }"
      />
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'WCMenu',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['user', 'cart']),
  }
};
</script>

<style lang="scss" scoped>
.menu__mobile {
.flex {
    flex-direction: column;
    row-gap: 0;
  }
  .cta {
    transition: opacity 0.2s ease;
    margin: 0;
  }
}
</style>
