var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Flex',{staticClass:"flex-no-wrap cart__item product-cart-thumb",attrs:{"column-gap":"m"}},[_c('Grid',{staticClass:"w-full",attrs:{"col":"4"}},[_c('div',{staticClass:"wc-product-image-background",style:({
        backgroundImage: `url(${_vm.productInfo.product_image})`,
      })}),_c('Column',{attrs:{"size":"2"}},[_c('Flex',{staticClass:"cart__item--info",attrs:{"justify":"space-between","column-gap":"m2"}},[_c('router-link',{attrs:{"to":{
            name: 'SingleProduct',
            params: {
              slug: _vm.productInfo.slug,
            },
          }}},[_c('div',[_vm._v(" "+_vm._s(_vm.productInfo.product_name)+" ")]),_c('Richtext',{attrs:{"data":{ value: _vm.productInfo.description }}})],1),(_vm.theme === 'complete')?_c('div',{staticClass:"cart__item--price"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLineTotal(_vm.cartItem))}})]):_c('div',{staticClass:"cart__item--price"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.productPrice)}})])],1),(_vm.theme === 'complete')?_c('Flex',{attrs:{"align":"center"}},[_c('Flex',{staticClass:"cart__item--numbers",attrs:{"align":"center","justify":"between"}},[_c('Number',{key:_vm.cartLoading,attrs:{"data":{
              id: `number`,
              fn: _vm.numberChanged,
              itemKey: _vm.cartItem.key,
              readonly: _vm.cartLoading,
            },"value":_vm.cartItem.quantity}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }