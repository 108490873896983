<template>
  <div>
    <AccordionWrapper>
      <AccordionItem :show="cart.coupons.length > 0">
        <template
          slot="accordion-trigger"
        >
          <div class="form-group__header typo--m">
            {{ $labels.title_coupon }}
          </div>
        </template>
        <template
          slot="accordion-content"
        >
          <Spacer
            :bottom="cart.coupons.length > 0 ? 's' : false"
          >
            <Form
              id="coupon"
              theme="inline"
            />
          </Spacer>
          <div
            v-if="cart.coupons.length > 0"
            class="coupons"
          >
            <div
              v-for="coupon in cart.coupons"
              :key="coupon.coupon"
              class="coupon"
            >
              <Spacer
                x="m"
                y="s"
                v-html="coupon.coupon"
              />
              <!-- <Spacer
                right="xs"
                :left="false"
                y="xs"
              > -->
              <Icon
                name="close"
                size="s"
                theme="invert"
                :fn="() => removeCoupon(coupon.coupon)"
              />
              <!-- </Spacer> -->
            </div>
          </div>
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/form';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import Icon from '@/components/ui/icon';

export default {
  name: 'Coupon',
  components: {
    Form,
    AccordionWrapper,
    AccordionItem,
    Icon,
  },
  computed: {
    ...mapGetters(['cart']),
  },
  methods: {
    async removeCoupon(coupon) {
      this.$store.dispatch('removeCoupon', {
        coupon,
      });
    },
  },
};
</script>

<style lang="scss">
.coupon {
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius);
  background: var(--black);
  color: var(--white);
}
</style>
