var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{attrs:{"x":"m"}},[(!_vm.loading)?_c('main',{staticClass:"product"},[(_vm.galleryImages)?_c('Spacer',{staticClass:"gallery-product__images",attrs:{"bottom":"m"}},[_c('Gallery',{attrs:{"data":_vm.galleryImages,"ratio":_vm.ratio,"theme":"product"}})],1):_vm._e(),(_vm.post.type !== 'grouped')?[_c('div',{staticClass:"main-container"},[_c('Grid',{attrs:{"col":_vm.$mq.isMobileS ? '12' : '6'}},[_c('Spacer',{staticClass:"product__text",attrs:{"bottom":"l"}},[_c('Flex',{staticClass:"sticky-top",attrs:{"dir":"column"}},[_c('Spacer',{attrs:{"bottom":_vm.$mq.isMobileS ? 'm3' : 'ml'}},[_c('Flex',{attrs:{"dir":"column","row-gap":"m"}},[_c('Flex',{attrs:{"dir":"column"}},[_c('Title',{staticClass:"bottom-item product__text--title",attrs:{"data":{ value: _vm.post.name, size: 'xl' }}}),_c('Richtext',{staticClass:"bottom-item product__text--description",attrs:{"data":{
                        value: _vm.product.description ? _vm.product.description : _vm.post.description,
                        size: 'xl',
                      }}})],1),_c('Price',{staticClass:"price",class:_vm.post.categories[0].slug === 'shop' ? 'price--inquire' : null,attrs:{"product":_vm.product}})],1)],1),_c('div',{staticClass:"add-to-cart"},[(_vm.hasVariations)?_c('div',{staticClass:"bottom-item"},_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index},[_c('Flex',[_c('Select',{staticClass:"product__variation-selector",attrs:{"data":_vm.getAttribute(attribute),"model":attribute.model},on:{"update:model":function($event){return _vm.$set(attribute, "model", $event)}},model:{value:(attribute.model),callback:function ($$v) {_vm.$set(attribute, "model", $$v)},expression:"attribute.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(attribute.name))])])],1)],1)}),0):_vm._e(),(_vm.addableToCart || _vm.hasVariations)?_c('Cta',{attrs:{"data":{
                    title: !_vm.addableToCart && _vm.selectedVariation ? _vm.$labels.out_of_stock : _vm.labelCart,
                    url: false,
                    fn: _vm.addToCart,
                  },"disabled":!_vm.addableToCart,"theme":"button-boxed"}}):(!_vm.addableToCart)?_c('Cta',{attrs:{"theme":"button-boxed","data":{
                    title: _vm.$labels.contact_us,
                    url: 'info@nm3.xyz',
                  }}}):_vm._e(),_c('Cta',{attrs:{"data":{
                    title: _vm.$labels.view_catalog,
                    url: _vm.productCatalog,
                    target: '_blank',
                  },"theme":"button-boxed-light","download_name":"NM3 Product Catalog"}})],1),_c('Spacer',{staticClass:"product-info",attrs:{"top":_vm.$mq.isMobileS ? 'zero' : 'm4',"bottom":_vm.$mq.isMobileS ? 'm3' : 'zero'}},[_c('Accordion',{attrs:{"data":_vm.productInfo,"theme":"underline"}})],1)],1)],1)],1),(!_vm.$mq.isMobileS)?_c('Grid',{attrs:{"col":"12","row-gap":"m"}},_vm._l((_vm.post.acf.product_images),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-item-id":item.id}},[_c('Figure',{ref:index,refInFor:true,attrs:{"data":{ default: item, ratio: true, object_fit: 'cover' }}})],1)}),0):_vm._e()],1),_c('div',{staticClass:"product__files"},[(_vm.post.acf.product_sheet)?_c('a',{attrs:{"href":_vm.post.acf.product_sheet.url}},[_c('span',{staticClass:"product__files--underlined"},[_vm._v("Product Sheet")]),_c('span',{staticClass:"arrowdown"})]):_vm._e()])]:_vm._e(),(_vm.post.type === 'grouped')?[_c('router-view',{key:_vm.$route.fullPath,attrs:{"post":_vm.groupedProducts[_vm.$route.params.groupedSlug],"others":_vm.groupedProductsFiltered,"parent":_vm.post}})]:_vm._e(),_c('div',{staticClass:"related"},[_c('Spacer',{staticClass:"desktop-only",attrs:{"template":"section-large"}}),_c('Archive',{attrs:{"template":"related","data":{
          title: 'Related products',
          archive_type: 'archive',
          archive: 'product',
          product: _vm.post.upsell_ids && _vm.post.upsell_ids.length > 0 ? _vm.post.upsell_ids : undefined,
          max_posts: 4,
          exclude: _vm.$route.params.groupedSlug
            ? _vm.groupedProducts[_vm.$route.params.groupedSlug].id
            : [_vm.post.id],
          archive_category:
            _vm.post.upsell_ids && _vm.post.upsell_ids.length > 0
              ? undefined
              : {
                  taxonomy: 'product_cat',
                  term_id: _vm.post.categories[0].id,
                },
          layout: [
            {
              media_query: 'default',
              layout_size: '6',
              layout_type: 'grid',
            },
            {
              media_query: 'm',
              layout_size: '3',
              layout_type: 'grid',
            },
          ],
        }}})],1)],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }