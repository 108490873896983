var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{attrs:{"x":"m"}},[_c('main',{staticClass:"project"},[_c('div',{staticClass:"project-title"},[(_vm.post.excerpt.rendered)?_c('Spacer',{attrs:{"bottom":"m"}},[_c('Richtext',{attrs:{"data":{ value: _vm.post.excerpt.rendered, size: 'xl' }}})],1):_vm._e(),(_vm.post.title.rendered || _vm.post.gds_taxonomies.categories)?_c('Spacer',{attrs:{"bottom":"m"}},[_c('Flex',{attrs:{"dir":"row","column-gap":"m"}},[_c('Title',{attrs:{"data":{
            value: _vm.post.title.rendered,
            size: 'xs'
          }}}),_vm._l((_vm.post.gds_taxonomies.categories),function(category){return _c('div',[(category.name !== 'Uncategorized')?_c('div',{staticClass:"typo--xs"},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e()])})],2)],1):_vm._e()],1),_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:`section--${parentIndex}`,attrs:{"layout":_vm.layout(section),"columns":section.layout,"isProductStack":_vm.isBlockProduct(section)}},_vm._l((_vm.blocks(section)),function(block,index){return _c('Spacer',{key:index,class:[
          'block',
          `block--${block.acf_fc_layout}`,
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null,
          { [`block--span-${block.columns}`]: block.columns },
        ],attrs:{"x":section.layout === 'default' ? block.block_spacer : false}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",attrs:{"data":{ ...block, sectionLayout: section.layout }}})],1)}),1)}),_c('div',{staticClass:"project-related"},[_c('Spacer',{attrs:{"template":"section-large"}}),_c('Archive',{attrs:{"template":"related","data":{
        title: 'Related works',
        archive_type: 'archive',
        archive: 'project',
        infinite_scroll: false,
        exclude: [_vm.post.id],
        max_posts: 3,
        archive_category: false,
        layout: [
          {
            media_query: 'default',
            layout_size: '6',
            layout_type: 'grid',
          },
          {
            media_query: 'm',
            layout_size: '4',
            layout_type: 'grid',
          },
        ],
      }}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }