<template>
  <Spacer
    y="m"
    :use-margin="true"
  >
    <Wrapper
      v-if="user"
      size="l"
      boxed
    >
      <Grid
        col="12"
        row-gap="m"
      >
        <MenuUser />
        <router-view :key="$route.fullPath" />
      </Grid>
    </Wrapper>
    <Wrapper
      v-else
      size="xs"
    >
      <Spacer
        :use-margin="true"
      >
        <Grid
          col="12"
          row-gap="m"
        >
          <Login />
        </Grid>
      </Spacer>
    </Wrapper>
    <div />
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import MenuUser from '@/components/wc/menu-user';
import Login from '@/components/wc/login';

export default {
  name: 'Account',
  components: {
    MenuUser,
    Login,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['user', 'authLoading']),
  },
};
</script>
