<template>
  <Spacer>
    <Label
      v-if="data.label && data.label !== ''"
      :data="{
        value: data.label,
        tag: data.tag_label,
        size: data.size_label,
      }"
    />
    <Spacer v-if="data.image?.default" bottom="m">
      <Figure :data="{ ...data.image, ratio: true, lazyload: true }" />
    </Spacer>
    <Spacer v-if="data.title && data.title !== ''" bottom="s">
      <Title
        v-if="data.title && data.title !== ''"
        :data="{
          value: data.title,
          tag: data.tag_title,
          size: data.size_title,
        }"
      />
    </Spacer>
    <RichText
      v-if="data.rich_text && data.rich_text !== ''"
      :data="{
        value: data.rich_text,
        size: data.size_rich_text,
      }"
    />
    <div>
      <Cta
        v-for="(item, index) in data.ctas"
        :key="index"
        :data="item.cta"
        :theme="data.cta_layout"
      />
    </div>
  </Spacer>
</template>

<script>
import Figure from "@/components/media/figure";
import Title from "@/components/typo/title";
import Label from "@/components/typo/label";
import RichText from "@/components/typo/richtext";
import Cta from "@/components/typo/cta";

export default {
  name: "Content",
  components: {
    Figure,
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.block--content {
  h1 {
    max-width: 1300px;
  }
  p {
    max-width: 620px;
  }
}
</style>
