/* eslint-disable no-undef */

import Cart from '@/views/wc/Cart.vue';
import Checkout from '@/views/wc/Checkout.vue';
import ThankYou from '@/views/wc/ThankYou.vue';
import Account from '@/views/wc/account/Index.vue';
import Orders from '@/views/wc/account/Orders.vue';
import Register from '@/views/wc/account/Register.vue';
import SocialLoggedIn from '@/views/wc/account/SocialLoggedIn.vue';
import EditAccount from '@/views/wc/account/EditAccount.vue';
import PayOrder from '@/views/wc/PayOrder.vue';
import SingleProduct from '@/views/wc/SingleProduct.vue';
import SingleGroupedProduct from '@/views/wc/SingleGroupedProduct.vue';

const { postTypes } = __VUE_WORDPRESS__.state;

const permalink = postTypes.product.rewrite.slug.replace(/^\/+/g, '');
const type = postTypes.product.rest_base;

const wcRoutes = [
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      customView: true,
      hideFooter: true,
    },
  },
  // /cart/?cancel_order=true&order=wc_order_wIm66dL0hWe5a&order_id=165&redirect&_wpnonce=9d9f754376
  {
    path: '/checkout/order-pay/:orderId/',
    name: 'PayOrder',
    component: PayOrder,
    meta: {
      customView: true,
      fetchCountry: true,
      hideFooter: true,
    },
  },
  // /checkout/order-pay/260/?pay_for_order=true&key=wc_order_CeFdYg7esp6RQ
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      customView: true,
      fetchCountry: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/checkout/order-received/:orderId/',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      customView: true,
      fetchCountry: true,
      hideFooter: true,
    },
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register,
    meta: {
      customView: true,
    },
  },
  {
    path: '/social-logged-in/',
    name: 'SocialLoggedIn',
    component: SocialLoggedIn,
    meta: {
      customView: true,
    },
  },
  {
    path: '/my-account/',
    name: 'Account',
    component: Account,
    meta: {
      customView: true,
      fetchCountry: true,
    },
    children: [
      {
        path: '/',
        name: 'EditAccount',
        component: EditAccount,
        meta: {
          customView: true,
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: {
          customView: true,
          userPage: true,
          fetchCountry: true,
        },
      },
    ],
  },
  {
    path: `/${permalink}/:slug`,
    name: 'SingleProduct',
    component: SingleProduct,
    meta: {
      type,
    },
    children: [
      {
        path: ':groupedSlug',
        name: 'SingleGroupedProduct',
        component: SingleGroupedProduct,
        meta: {
          type,
        },
      },
    ],
  },
];

export default wcRoutes;
