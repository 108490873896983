<template>
  <Grid
    col="12"
    row-gap="s"
    class="max-width"
  >
    <Form
      id="edit-account"
      :name="$labels.profile"
      class="form--single"
    />
    <Form
      id="edit-billing-address"
      :name="$labels.billing_address"
      class="form--single"
    />
    <Form
      id="edit-shipping-address"
      :name="$labels.shipping_address"
      class="form--single"
    />
    <div
      v-if="paymentGateways && paymentGateways.stripe && paymentGateways.stripe.publishable_key"
    >
      <SavedCards />
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';
import SavedCards from '@/components/wc/saved-cards';

export default {
  name: 'EditAccount',
  components: {
    Form,
    SavedCards,
  },
  computed: {
    ...mapGetters(['user', 'paymentGateways']),
  },
  mounted() {
    if (this.user) {
      // Ricarica dati se utente cambia al checkout
      this.$store.dispatch('loadCustomerData');
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.form--single{
  padding-bottom: var(--spacer-m);
  border-bottom: 1px solid black;

  ::v-deep form.form {
    padding-bottom: 50px;
    padding-top: var(--spacer-m);


    @include mq(m){
      padding-bottom: 100px;
    }
  }
}
.max-width{
  /* max-width: calc(100vw - 20px);
  margin-left: 10px; */
  // margin-right: auto;
  @include mq(s){
      margin-left: 0px;
    max-width: auto;
  }
}
</style>
