<template>
  <Spacer x="m">
    <main v-if="!loading" class="product">
      <Spacer v-if="galleryImages" bottom="m" class="gallery-product__images">
        <Gallery :data="galleryImages" :ratio="ratio" theme="product" />
      </Spacer>

      <template v-if="post.type !== 'grouped'">
        <div class="main-container">
          <Grid :col="$mq.isMobileS ? '12' : '6'">
            <Spacer bottom="l" class="product__text">
              <Flex class="sticky-top" dir="column">
                <Spacer :bottom="$mq.isMobileS ? 'm3' : 'ml'">
                  <Flex dir="column" row-gap="m">
                    <Flex dir="column">
                      <Title
                        class="bottom-item product__text--title"
                        :data="{ value: post.name, size: 'xl' }"
                      />
                      <Richtext
                        class="bottom-item product__text--description"
                        :data="{
                          value: product.description ? product.description : post.description,
                          size: 'xl',
                        }"
                      />
                    </Flex>
                    <Price
                      :product="product"
                      class="price"
                      :class="post.categories[0].slug === 'shop' ? 'price--inquire' : null"
                    />
                  </Flex>
                </Spacer>

                <div class="add-to-cart">
                  <div v-if="hasVariations" class="bottom-item">
                    <div v-for="(attribute, index) in attributes" :key="index">
                      <Flex>
                        <Select
                          v-model="attribute.model"
                          class="product__variation-selector"
                          :data="getAttribute(attribute)"
                          :model.sync="attribute.model"
                        >
                          <label slot="label" for="terms">{{ attribute.name }}</label>
                        </Select>
                        <!-- <Icon
                        v-if="attribute.model"
                        name="close"
                        theme="small"
                        :fn="() => resetAttribute(attribute)"
                      /> -->
                      </Flex>
                    </div>
                  </div>

                  <Cta
                    v-if="addableToCart || hasVariations"
                    :data="{
                      title: !addableToCart && selectedVariation ? $labels.out_of_stock : labelCart,
                      url: false,
                      fn: addToCart,
                    }"
                    :disabled="!addableToCart"
                    theme="button-boxed"
                  />
                  <Cta
                    v-else-if="!addableToCart"
                    theme="button-boxed"
                    :data="{
                      title: $labels.contact_us,
                      url: 'info@nm3.xyz',
                    }"
                  />
                  <Cta
                    :data="{
                      title: $labels.view_catalog,
                      url: productCatalog,
                      target: '_blank',
                    }"
                    theme="button-boxed-light"
                    download_name="NM3 Product Catalog"
                  />
                </div>
                <!-- <Richtext
                  class="bottom-item product__text--categories"
                  :data="{
                    value: categories,
                  }"
                /> -->
                <Spacer
                  :top="$mq.isMobileS ? 'zero' : 'm4'"
                  :bottom="$mq.isMobileS ? 'm3' : 'zero'"
                  class="product-info"
                >
                  <Accordion :data="productInfo" theme="underline"></Accordion>
                </Spacer>

                <!-- <Availability :product="product" /> -->
                <!-- <Flex
                  v-if="product && post.categories[0].slug === 'shop'"
                  class="bottom-item product__text--add"
                > -->
                <!-- <Number
                :data="{
                  id: `number`,
                  fn: numberChanged,
                  itemKey: product.id,
                  readonly: cartLoading || isOos,
                }"
                :value.sync="numberToBeAdded"
              /> -->
                <!-- <div v-if="post.categories[0].slug !== 'shop'">
                <a
                  :href="`mailto:info@nm3.xyz?subject=Inquire about: ${post.name}`"
                  target="_blank"
                >
                  Inquire about</a>
              </div> -->
                <!-- </Flex> -->
              </Flex>
            </Spacer>
          </Grid>

          <Grid v-if="!$mq.isMobileS" col="12" row-gap="m">
            <div
              v-for="(item, index) in post.acf.product_images"
              :key="index"
              class="item"
              :data-item-id="item.id"
            >
              <Figure :ref="index" :data="{ default: item, ratio: true, object_fit: 'cover' }" />
            </div>
          </Grid>
        </div>

        <div class="product__files">
          <a v-if="post.acf.product_sheet" :href="post.acf.product_sheet.url">
            <span class="product__files--underlined">Product Sheet</span>
            <span class="arrowdown" />
          </a>
          <!-- <a
            v-if="post.acf.cad_file"
            target="_blank"
            :href="post.acf.cad_file.url"
          ><span class="product__files--underlined">CAD File</span> <span
            class="arrowdown"
          /></a> -->
        </div>
        <!-- <Spacer :top="$mq.isMobileS ? 'm' : 'l'" v-if="post.acf.sections">
          <Section
            v-for="(section, parentIndex) in post.acf.sections"
            :key="parentIndex"
            :layout="layout(section)"
            :columns="section.layout"
            :class="`section--${parentIndex}`"
            theme="single"
          >
            <div
              v-for="(block, index) in blocks(section)"
              :key="index"
              :class="[
                'block',
                `block--${block.acf_fc_layout}`,
                block.block_classes && block.block_classes !== ''
                  ? block.block_classes.split(' ')
                  : null,
                { [`block--span-${block.columns}`]: block.columns },
              ]"
            >
              <component
                :is="components.get(block.acf_fc_layout)"
                :data="{ ...block, sectionLayout: section.layout }"
              />
            </div>
          </Section>
        </Spacer> -->
      </template>

      <template v-if="post.type === 'grouped'">
        <router-view
          :key="$route.fullPath"
          :post="groupedProducts[$route.params.groupedSlug]"
          :others="groupedProductsFiltered"
          :parent="post"
        />
      </template>

      <div class="related">
        <Spacer template="section-large" class="desktop-only" />
        <Archive
          template="related"
          :data="{
            title: 'Related products',
            archive_type: 'archive',
            archive: 'product',
            product: post.upsell_ids && post.upsell_ids.length > 0 ? post.upsell_ids : undefined,
            max_posts: 4,
            exclude: $route.params.groupedSlug
              ? groupedProducts[$route.params.groupedSlug].id
              : [post.id],
            archive_category:
              post.upsell_ids && post.upsell_ids.length > 0
                ? undefined
                : {
                    taxonomy: 'product_cat',
                    term_id: post.categories[0].id,
                  },
            layout: [
              {
                media_query: 'default',
                layout_size: '6',
                layout_type: 'grid',
              },
              {
                media_query: 'm',
                layout_size: '3',
                layout_type: 'grid',
              },
            ],
          }"
        />
      </div>
    </main>
  </Spacer>
</template>

<script>
import { mapGetters } from "vuex";
import isEqual from "lodash.isempty";
import { addableToCart, isOos } from "@/assets/js/utils-wc";
const { options } = __VUE_WORDPRESS__.state;

import data from "@/mixins/data";
import components from "@/assets/js/components";

// import Media from '@/components/blocks/media';
import Figure from "@/components/media/figure";
import Section from "@/components/section";
import Archive from "@/components/blocks/archive";
import Title from "@/components/typo/title";
import Cta from "@/components/typo/cta";
import Richtext from "@/components/typo/richtext.vue";
import Icon from "@/components/ui/icon.vue";
import Price from "@/components/wc/price.vue";
import Accordion from "@/components/blocks/accordion";
// import Availability from '@/components/wc/availability.vue';

// import Number from '@/components/form/fields/number';
import Select from "@/components/form/fields/select";

import { fetchVariations, fetchProductById } from "@/api";
import Gallery from "../../components/media/gallery.vue";

// N.B. testato fino a 2 attributi

export default {
  name: "SingleProduct",
  components: {
    // Media,
    Title,
    Cta,
    Richtext,
    Icon,
    Price,
    // Availability,
    // Number,
    Select,
    Figure,
    Archive,
    Section,
    Gallery,
    Accordion,
  },
  mixins: [data],
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      groupedProducts: {},
      labelCart: this.$labels.add_to_cart,
      components,
    };
  },
  computed: {
    ...mapGetters(["cartLoading"]),
    hasVariations() {
      return this.post.type === "variable";
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }

      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      this.variations.forEach((variation) => {
        this.post.gallery.push(variation.gds_featured_image);
      });
      return [this.post.gds_featured_image].concat(this.post.gallery ? this.post.gallery : []);
    },
    galleryFiltered() {
      return this.post.gallery.filter((item, index) => index > 0);
    },
    galleryImages() {
      return this.$mq.isMobile &&
        this.post.acf.gallery_mobile &&
        this.post.acf.gallery_mobile.length
        ? this.post.acf.gallery_mobile
        : this.post.acf.product_images;
    },
    groupedProductsFiltered() {
      const obj = {};
      Object.keys(this.groupedProducts).forEach((key) => {
        if (key !== this.$route.params.groupedSlug) {
          obj[key] = this.groupedProducts[key].name;
        }
      });

      return obj;
    },
    categories() {
      let value = "";

      if (this.post && this.post.gds_taxonomies.product_cat) {
        const projectCategories = [];
        this.post.gds_taxonomies.product_cat.forEach((category) =>
          projectCategories.push(category.name)
        );
        value = projectCategories.join(", ");
      }

      return value;
    },
    ratio() {
      const value = (4 / 3) * 100;
      return value;
    },
    productInfo() {
      let obj = {
        items: [
          {
            title_trigger: this.$labels.details,
            rich_text: `<div>${this.setProductDetails()}</div>`,
          },
          {
            title_trigger: this.$labels.shipping,
            rich_text: this.post.acf.shipping || options.extra?.product_shipping?.value,
          },
          {
            title_trigger: this.$labels.care_maintenance,
            rich_text:
              this.post.acf.care_and_maintenance || options.extra?.product_maintenance?.value,
          },
        ],
        activeItemIndex: 0,
      };
      return obj;
    },
    productCatalog() {
      let catalog_url = this.post.acf.catalog.url || options?.product_catalog?.url;
      return catalog_url;
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      if (this.$refs["product-gallery"] && this.$refs["product-gallery"].$children[0]) {
        setTimeout(() => {
          this.$refs["product-gallery"].$children[0].gallery.selectCell(
            `[data-item-id="${this.selectedImage}"]`
          );
        }, 100); // Await flickity loading
      }
    },
  },
  async created() {
    this.product = this.post;
    if (this.hasVariations) {
      this.$store.commit("SET_CART_LOADING", true);
      const { data: variations } = await fetchVariations({
        params: { slug: this.post.slug },
      });
      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: "select",
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };

        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (
            this.variations.findIndex(
              (variation) =>
                variation.attributes_slugs[attribute_slug] ===
                this.post.attributes_slugs[attribute_slug][i]
            ) > -1
          ) {
            newAttribute.options[this.post.attributes_slugs[attribute_slug][i]] = option;
            newAttribute.originalOptions[this.post.attributes_slugs[attribute_slug][i]] = option;
          }
        });

        this.attributes.push(newAttribute);
      });
      this.setSelectedVariation();
      this.$store.commit("SET_CART_LOADING", false);
      this.loading = false;
    } else if (
      this.product.type === "grouped" &&
      this.product.grouped_products &&
      this.product.grouped_products.length > 0
    ) {
      const promises = [];
      this.product.grouped_products.forEach((id) => {
        promises.push(
          fetchProductById({
            params: { id },
          })
        );
      });

      const groupedProducts = await Promise.all(promises);
      if (this.$route.name !== "SingleGroupedProduct") {
        this.$router.replace({
          path: groupedProducts[0].data[0].slug,
        });
      }
      groupedProducts.forEach((gProduct) => {
        const { slug } = gProduct.data[0];
        // eslint-disable-next-line prefer-destructuring
        this.groupedProducts[slug] = gProduct.data[0];
      });

      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit("viewItem", this.product);
  },
  methods: {
    addToCart() {
      this.labelCart = this.$labels.added;
      this.$store
        .dispatch(
          "addToCart",
          Object.assign(this.post, {
            id: String(this.post.id),
            variation_id: this.selectedVariation,
            variation: this.variations.find((variation) => this.selectedVariation === variation.id),
            quantity: this.numberToBeAdded,
            quantityDelta: this.numberToBeAdded,
          })
        )
        .then(() => {
          this.labelCart = this.$labels.add_to_cart;
        });
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
        this.numberToBeAdded = val[1];
      } else {
        this.numberToBeAdded = val.target.value;
      }
      if (this.numberToBeAdded < 1) {
        this.numberToBeAdded = 1;
      }
      if (this.product.stock_quantity && this.numberToBeAdded > this.product.stock_quantity) {
        this.numberToBeAdded = this.product.stock_quantity;
      }
    },
    getAttribute(attribute) {
      const select = document.querySelector(`select#${attribute.id}`);
      const currentFixedOption = document.querySelector(".field.focus select");
      if (
        currentFixedOption &&
        select &&
        select.closest(".field") &&
        !select.closest(".field").classList.contains("focus")
      ) {
        // Re set original value
        const originalOptionsKeys = Object.keys(attribute.originalOptions);
        originalOptionsKeys.forEach((originalOptionsKey) => {
          if (!attribute.options[originalOptionsKey]) {
            attribute.options[originalOptionsKey] = attribute.originalOptions[originalOptionsKey];
          }
        });

        const currentFixedKey = currentFixedOption.getAttribute("id");
        const currentFixedValue = currentFixedOption.value;
        const optionsKeys = Object.keys(attribute.options);
        optionsKeys.forEach((optionsKey) => {
          const existingVariation = this.variations.filter((variation) => {
            return (
              variation.attributes_slugs[currentFixedKey] === currentFixedValue &&
              variation.attributes_slugs[attribute.id] === optionsKey
            );
          });
          if (!existingVariation[0]) {
            delete attribute.options[optionsKey];
          }
        });
      }

      return attribute;
    },
    resetAttribute(attribute) {
      attribute.model = null;
      this.resetSelectedVariation();
    },
    resetSelectedVariation() {
      this.selectedVariation = null;
      this.selectedImage = null;
    },
    setSelectedVariation() {
      const queryVariations = Object.keys(this.$route.query);
      if (queryVariations.length === 0) {
        this.attributes.forEach((attribute) => {
          attribute.model = null;
        });
        this.resetSelectedVariation();
      } else {
        // Re-attribute model if reloading page
        queryVariations.forEach((queryVariation) => {
          this.attributes.forEach((attribute) => {
            if (attribute.id === queryVariation.replace("attribute_", "")) {
              attribute.model = this.$route.query[queryVariation];
            }
          });
        });

        const selectedVariation = this.variations.find((variation) => {
          let isRightVariation = false;
          let correctAttributes = 0;
          this.attributes.forEach((attribute) => {
            if (attribute.model === variation.attributes_slugs[attribute.id]) {
              correctAttributes += 1;
            }
          });
          if (correctAttributes === variation.attributes.length) {
            isRightVariation = true;
          }
          return isRightVariation;
        });
        if (selectedVariation) {
          this.selectedVariation = selectedVariation.id;
          this.selectedImage = selectedVariation.gds_featured_image
            ? selectedVariation.gds_featured_image.id
            : null;
          this.product = selectedVariation;
        } else {
          this.resetSelectedVariation();
        }
      }
      // Marketing
      this.$bus.$emit("selectItem", this.product);
    },
    updateSelectedVariation() {
      let attributesSelected = 0;
      const query = {};
      this.attributes.forEach((attribute) => {
        if (attribute.model) {
          query[`attribute_${attribute.id}`] = attribute.model;
          attributesSelected += 1;
        }
      });
      if (attributesSelected === this.attributes.length && !isEqual(query, this.$route.query)) {
        this.$router.replace({ query });
      }
    },
    layout(section) {
      const { section_classes, section_layout, section_id, section_spacer } = section;
      return {
        section_classes,
        section_layout,
        section_id,
        section_spacer,
      };
    },
    blocks(section) {
      const { layout } = section;
      let value = section.blocks;

      if (layout !== "default") {
        value = section[`blocks_${layout}`];
      }

      return value;
    },
    setProductDetails() {
      let size = {
        label: "Size (cm):",
        value: this.post.acf.size,
      };
      let weight = {
        label: "Weight (kg):",
        value: this.post.weight,
      };
      let edition = {
        label: "Edition:",
        value: this.post.acf.edition,
      };
      let material = {
        label: "Material:",
        value: this.post.acf.material,
      };

      let fields = [size, weight, edition, material];

      let nonNullFields = fields.filter((el) => {
        if (el.value) {
          return el;
        }
      });

      let htmlArray = [];
      nonNullFields.forEach((el) => {
        let row = `<p>${el.label} ${el.value}</p>`;
        htmlArray.push(row);
      });

      let text = htmlArray.join(" ");
      return text;
    },
  },
};
</script>

<style lang="scss">
.main-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--column-gap-m);

  @include mq(m) {
    grid-template-columns: 1fr 1fr;
  }
}

.sticky-top {
  position: relative;
  row-gap: 0;
  column-gap: 0;

  @include mq(m) {
    position: sticky;
    top: calc(var(--spacer-m) * 3);
  }
}

.product__variation-selector {
  min-width: 200px;
  width: 100%;
}

.gallery-product__images {
  @include mq(m) {
    display: none;
  }
}

// .product__gallery {
//   &.product__technicals.gallery {
//     .item {
//       width: 100vw;
//     }
//   }
// }

.product {
  &__cover {
    figcaption {
      display: none;
    }
  }

  // &__technicals {
  //   margin: 10px 0;
  //   &--view {
  //     background: var(--overlay-black-01);
  //     grid-column: span 6;
  //     @include mq(m) {
  //       grid-column: span 2;
  //     }
  //   }
  //   .caption {
  //     margin-bottom: 10px;
  //   }
  // }
  &__text {
    .grid {
      row-gap: 20px;
    }

    // @include mq(m) {
    //   grid-column: span 3;
    // }

    .bottom-item {
      grid-column: 1 / -1;

      @include mq(s) {
        grid-column: span 2;
      }

      @include mq(l) {
        grid-column: span 1;
      }
    }

    &--title {
      @include mq(s) {
        grid-column: 1 / 2;
      }

      select {
        // appearance: none;
        padding: 0;
        width: 100%;
        border: 0;
        @extend %typo--m;
        font-family: $sans;
      }
    }

    &--categories {
      display: none;

      @include mq(l) {
        display: initial;
      }
    }

    &--add.bottom-item {
      grid-column: 1 / -1;

      @include mq(m) {
        grid-column-start: -3;
      }

      @include mq(l) {
        grid-column-start: initial;
      }

      .price {
        transform: translateY(1px);
        margin-right: 15px;

        &--inquire {
          margin-right: 0;
        }
      }
    }
  }

  &__files {
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 15px;
      display: flex;
      align-items: flex-end;
      transition: opacity 0.2s ease;

      .arrowdown {
        display: inline-block;
        width: 15px;
        height: 15px;
        background-image: url("~@/assets/img/arrow.svg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 3px;
        border-bottom: 1px solid black;
      }

      &:hover {
        opacity: 0.4;
      }
    }

    &--underlined {
      border-bottom: 1px solid black;
    }
  }

  // .product__technicals.gallery {
  //   .product__gallery {
  //     grid-column: span 6;
  //     @include mq(m) {
  //       grid-column: span 2;
  //     }
  //   }
  // }
}

.add-to-cart {
  order: 2;
  position: sticky;
  bottom: var(--spacer-m);
  display: flex;
  flex-direction: column;
  gap: var(--spacer-m);

  @include mq(m) {
    order: 1;
    position: initial;
  }
}

.product-info {
  order: 1;

  @include mq(m) {
    order: 2;
  }
}

.grouped__name {
  opacity: 0.25;
  cursor: pointer;
  margin-right: 2px;
}

.active {
  pointer-events: none;
  opacity: 1;
}

.spacer.block.block--media.block--span-one {
  height: unset;

  > .picture {
    height: unset;

    picture {
      height: unset;
    }
  }

  @include mq(s) {
    height: 100%;

    > .picture {
      height: 100%;

      picture {
        height: 100%;
      }
    }
  }
}

.desktop-only {
  display: none;
  @include mq(m) {
    display: block;
  }
}
</style>
