var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.showCartCalc)?_c('div',{on:{"click":function($event){return _vm.$store.commit('CLOSE_CART')}}}):_vm._e()]),_c('div',{staticClass:"cart"},[_c('transition',{attrs:{"name":"slide"}},[(_vm.showCartCalc)?_c('div',[_c('Spacer',{staticClass:"cart__wrapper",attrs:{"all":"m"}},[_c('div',{staticClass:"cart__header"},[_c('Grid',{attrs:{"justify":"space-between","align":"start","col":"4"}},[_c('Column',{attrs:{"size":"2"}},[_c('div',{staticClass:"typo--m"},[_vm._v(" "+_vm._s(_vm.$labels.cart_added_message)+" ")])]),_c('WcMenu',{staticClass:"cart__count"})],1)],1),_c('div',{staticClass:"cart__content"},[(_vm.isCartEmpty)?_c('Spacer',{staticClass:"cart__empty-state",attrs:{"y":"m"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$labels.your_cart_empty)+" ")])]):_vm._e(),(_vm.addedProduct)?_c('ProductCartThumb',{attrs:{"cartItem":_vm.addedProduct,"theme":"side"}}):_vm._e()],1),(!_vm.isCartEmpty)?_c('Flex',{staticClass:"typo--m",attrs:{"dir":"column","row-gap":"m"}},[_c('Cta',{attrs:{"theme":"button-boxed-light","data":{
                  title: _vm.$labels.view_cart,
                  url: '/cart',
                  size: 's',
                }}}),_c('Cta',{attrs:{"theme":"button-boxed","data":{
                  title: _vm.$labels.checkout,
                  url: '/checkout',
                  size: 's',
                }}})],1):_vm._e()],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }