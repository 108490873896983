var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"min-height",attrs:{"y":"ml"}},[_c('nav',{staticClass:"menu-user"},[_c('Flex',{attrs:{"column-gap":"s","justify":"center"}},[_c('Cta',{attrs:{"theme":"button","data":{
          title: _vm.$labels.account_details,
          url: {name: 'EditAccount'},
        }}}),_c('Cta',{attrs:{"theme":"button","data":{
          title: _vm.$labels.orders,
          url: {name: 'Orders'},
        }}}),_c('Cta',{attrs:{"theme":"button","data":{
          title: _vm.$labels.logout,
          url: {name: 'Account'},
          fn: _vm.logout
        }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }