<template>
  <Wrapper size="s">
    <Spacer y="ml">
      <Grid col="12">
        <Spacer
          class="t-center typo--s"
        >
          {{ $labels.create_accunt }}
        </Spacer>
        <Form
          id="register"
          class="form--single"
        />
      </Grid>
    </Spacer>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';

export default {
  name: 'Register',
  components: {
    Form,
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Account' });
    }
  },
};
</script>
