<template>
  <div>
    <Spacer
      class="checkout__header"
      x="m"
    >
      <Flex
        justify="space-between"
        align="center"
      >
        <router-link
          class="checkout__back typo--label-m"
          to="/cart"
        >
          <Flex align="center">
            {{ $labels.back_to_cart }}
          </Flex>
        </router-link>
        {{ $labels.checkout }}
      </Flex>
    </Spacer>

    <Wrapper
      v-if="!firstLoading"
      class="checkout__content"
      boxed
    >
      <Grid
        align="start"
        :col="{
              default: 12,
              m: 3,
            }"
      >
      <Column size="2">
        <Grid
          col="12"
          class="checkout__left"
        >
          <div
            v-if="notice"
            class="notice"
            v-html="notice"
          />
          <div class="account">
            <AccordionWrapper>
              <!-- Account -->
              <AccordionItem class="block-theme--default">
                <template slot="accordion-trigger">
                  <div>
                    <div
                      v-if="user"
                      class="chevron"
                    >
                      {{ $labels.welcome_back }} {{ userNiceName }}.
                    </div>
                    <div
                      v-else
                      class="chevron"
                    >
                      {{ $labels.do_you_have_account }}
                    </div>
                  </div>
                </template>
                <template slot="accordion-content">
                  <div v-if="user">
                    <Spacer>
                      <!-- bottom="l" -->
                      {{ $labels.logged_in_with_mail }}
                      <span>{{
                        user.user_email
                      }}</span>.
                    </Spacer>
                    <!-- <Cta
                      :data="{
                        title: $labels.logout,
                        url: {name: 'Logout'},
                        fn: logout
                      }"
                    /> -->
                  </div>
                  <Grid
                    v-else
                    grid="1"
                    row-gap="m"
                    class="grid-login"
                  >
                    <Form
                      id="login"
                      theme="login-checkout"
                    />
                    <LoginSocial />
                    <Spacer>
                      <Grid
                        grid="1"
                        row-gap="m"
                        class="grid-login"
                      >
                        <Flex justify="center">
                          <Cta
                            class="full-button"
                            theme="button-boxed-light"
                            :data="{
                              title: $labels.lost_password,
                              fn: toggleRecoverPassword,
                            }"
                          />
                        </Flex>
                        <Form
                          v-if="showRecoverPassword"
                          id="recovery-password"
                        />
                      </Grid>
                    </Spacer>
                  </Grid>
                </template>
              </AccordionItem>
            </AccordionWrapper>
          </div>
          <Form
            id="checkout"
            ref="form"
            class="form--multi"
          />
        </Grid>
      </Column>

        <div class="checkout__right">
          <CheckoutOrder />
        </div>
      </Grid>
    </Wrapper>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import isEmpty from 'lodash.isempty';
import { mapGetters } from 'vuex';

import { getCartPaymentGateways } from '@/api';
import { stripeMountCard } from '@/assets/js/utils-wc';

import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import CheckoutOrder from '@/components/wc/checkout-order';
import LoginSocial from '@/components/wc/login-social';

// eslint-disable-next-line no-undef
const { options } = __VUE_WORDPRESS__.state;

export default {
  name: 'Checkout',
  components: {
    Form,
    Cta,
    AccordionWrapper,
    AccordionItem,
    CheckoutOrder,
    LoginSocial,
  },
  data() {
    return {
      firstLoading: true,
      paymentMethod: null,
      stripe: null,
      stripeCard: null,
      stripeClientSecret: null,
      cartPaymentGateways: null,
      showRecoverPassword: false,
      // hasPaymentIntent: false,
      // cartLoaded: this.cartLoading,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'cart',
      'customer',
      'paymentGateways',
      'paymentMetas',
      'cartLoading',
    ]),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    paymentGatewaysActive() {
      if (!this.cartPaymentGateways) {
        return [];
      }
      const inactivePaymentGateways = Object.keys(this.paymentGateways).filter(
        (paymentGateway) => this.cartPaymentGateways.indexOf(paymentGateway) < -1,
      );
      const paymentGateways = JSON.parse(JSON.stringify(this.paymentGateways));
      inactivePaymentGateways.forEach((inactivePaymentGateway) => {
        delete paymentGateways[inactivePaymentGateway];
      });
      return paymentGateways;
    },
    userNiceName() {
      if (this.customer && this.customer.first_name) {
        return this.customer.first_name;
      }
      return this.user.display_name;
    },
    notice() {
      if (options && options.extra && options && options.extra.checkout_notice) { return options.extra.checkout_notice.value; }
      return null;
    },
  },
  watch: {
    cartLoading() {
      // if (!val) this.cartLoaded = true;
      if (this.cartLoading === false) {
        this.firstLoading = false;
        if (this.isCartEmpty) {
          this.$router.push('/shop');
        }
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('getCart');

    // Marketing
    this.$bus.$emit('beginCheckout', this.cart);

    const { data } = await getCartPaymentGateways();
    this.cartPaymentGateways = Object.keys(data);
    if (
      this.paymentGateways.stripe
      && this.paymentGateways.stripe.publishable_key
    ) {
      const stripe = await loadStripe(
        this.paymentGateways.stripe.publishable_key,
        {
          locale: 'en', // TODO: get from WPML
        },
      );
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      // await createAndStorePaymentIntent(this.$store);
      stripeMountCard(this.$store, this.paymentMetas);
    }
    // this.hasPaymentIntent = true;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    async setPaymentGateway() {
      const body = {
        gateway_id: this.paymentMethod,
      };
      this.$store.dispatch('setPaymentGateway', body);
    },
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
  },
};
</script>

<style lang="scss">
.checkout__header {
  background: white;
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  // height: $unit * 6;
  // & > {
  //   position: relative;
  // }
}

.checkout__content {
  // border-top: 1px solid black;
  margin-top: 30px;
  // padding-top: calc(72px + var(--spacer-m));
}
.checkout__left {
  // background: red;
  border-top: 1px solid black;
  padding-top: 8px;
  padding-bottom: calc(var(--vh, 1vh) * 50);

  @include mq(m) {
    padding-bottom: unset;
  }

  .account {
    & > .accordion-wrapper > .accordion__item {
      padding-bottom: 8px;
    }
  }

  .grid-login {
    .form-group {
      border: 0;
    }
    .fields {
      padding-bottom: 0;
    }
  }

  .form--multi {
    .form-group {
      padding-bottom: 8px;
    }
  }

  .accordion__wrapper {
    border-bottom: 1px solid black;
  }
  .notice,
  .accordion__content > *:last-child, .fields {
    padding-bottom: 50px;

    @include mq(m){
      padding-bottom: 100px;
    }
  }
  .notice {
    border-bottom: 1px solid black;
  }
}

.checkout__right {
  position: fixed;
  bottom: 0;
  width: calc(100% - 2 * var(--spacer-m));
  grid-column-start: 1;
  left:  var(--spacer-m);
  right: unset;
  background-color: var(--white);

  @include mq(m) {
    grid-column-start: 4;
    right: var(--column-gap-m);
    left: unset;
    width: calc(25% - var(--column-gap-m));
  }
}

</style>
