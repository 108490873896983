var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{attrs:{"bottom":"m"}},[_c('Grid',{attrs:{"grid":"12"}},[_c('Column',{staticClass:"wc-order-line",attrs:{"size":{
        default: 6,
        m: 6
      }}},[_c('div',{staticClass:"typo--xxs"},[_vm._v(" "+_vm._s(_vm.$labels.order)+" ")]),_c('div',[_vm._v(" #"+_vm._s(_vm.order.id)+" ")])]),_c('Column',{staticClass:"wc-order-line",attrs:{"size":{
        default: 6,
        m: 6
      }}},[_c('div',{staticClass:"typo--xxs"},[_vm._v(" "+_vm._s(_vm.$labels.date)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.parseDate(_vm.order.created_date))+" ")])]),_c('Column',{staticClass:"wc-order-line",attrs:{"size":{
        default: 6,
        m: 6
      }}},[_c('div',{staticClass:"typo--xxs"},[_vm._v(" "+_vm._s(_vm.$labels.total)+" ")]),_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.total)}})])]),(_vm.paymentGateway)?_c('Column',{staticClass:"wc-order-line",attrs:{"size":{
        default: 6,
        m: 6
      }}},[_c('div',{staticClass:"typo--xxs"},[_vm._v(" "+_vm._s(_vm.$labels.payment_method)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.paymentGateway)+" ")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }