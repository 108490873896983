var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('Spacer',{staticClass:"product__text",attrs:{"bottom":"l","top":"s"}},[_c('Grid',[_c('Flex',{staticClass:"bottom-item product__text--title typo--m"},[_c('div',[_vm._v(_vm._s(_vm.parent.name))])]),_c('Richtext',{staticClass:"bottom-item product__text--description",attrs:{"data":{
          value: _vm.product.description ? _vm.product.description : _vm.post.description,
        }}}),_c('div',{staticClass:"bottom-item product__text--info"},[(_vm.post.acf.size)?_c('div',[_vm._v(" Size (cm): "+_vm._s(_vm.post.acf.size)+" ")]):_vm._e(),(_vm.post.weight)?_c('div',[_vm._v(" Weight (kg): "+_vm._s(_vm.post.weight)+" ")]):_vm._e(),(_vm.post.acf.material)?_c('div',[_vm._v(" Material: "+_vm._s(_vm.post.acf.material)+" ")]):_vm._e()]),(_vm.hasVariations)?_c('div',{staticClass:"bottom-item"},_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index},[_c('Flex',[_c('Select',{staticClass:"product__variation-selector",attrs:{"data":_vm.getAttribute(attribute),"model":attribute.model},on:{"update:model":function($event){return _vm.$set(attribute, "model", $event)}},model:{value:(attribute.model),callback:function ($$v) {_vm.$set(attribute, "model", $$v)},expression:"attribute.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(attribute.name))])]),(attribute.model)?_c('Icon',{attrs:{"name":"close","theme":"small","fn":() => _vm.resetAttribute(attribute)}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.product && _vm.post.categories[0].slug === 'shop')?_c('Flex',{staticClass:"bottom-item product__text--add"},[_c('Price',{staticClass:"price",attrs:{"product":_vm.product}}),_c('Cta',{attrs:{"data":{
            title: _vm.labelCart,
            url: false,
            fn: _vm.addToCart,
          },"disabled":!_vm.addableToCart,"theme":"button"}})],1):_vm._e()],1)],1),_c('Flex',{staticClass:"grouped__names"},_vm._l((_vm.groupedProducts),function(name,key){return _c('p',{key:key,staticClass:"grouped__name",class:[key === _vm.post.slug ? 'active' : null],on:{"click":function($event){return _vm.setCurrentProduct(key)}}},[_vm._v(" "+_vm._s(_vm.setGroupedName(name))+" ")])}),0),_c('Spacer',{attrs:{"bottom":"ml"}},[_c('Figure',{staticClass:"media product__gallery",attrs:{"data":{
        default: _vm.post.gallery[0],
        ratio: true,
        lazyload: true,
        object_fit: 'cover',
      }}})],1),(_vm.post.acf.technical_drawings)?_c('Spacer',{staticClass:"gallery product__technicals",attrs:{"bottom":"ml"}},[_c('Spacer',{attrs:{"y":"m"}},[_c('Title',{attrs:{"data":{ value: 'Technical Drawings' }}})],1),_c('Grid',_vm._l((_vm.post.acf.technical_drawings),function(item,index){return _c('div',{key:index,staticClass:"product__technicals--view"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.caption)+" ")]),_c('Figure',{staticClass:"media product__gallery",attrs:{"data":{
            default: item,
            ratio: true,
            lazyload: true,
            object_fit: 'contain',
          }}})],1)}),0)],1):_vm._e(),_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:`section--${parentIndex}`,attrs:{"layout":_vm.layout(section),"columns":section.layout,"theme":"single"}},_vm._l((_vm.blocks(section)),function(block,index){return _c('div',{key:index,class:[
        'block',
        `block--${block.acf_fc_layout}`,
        block.block_classes && block.block_classes !== ''
          ? block.block_classes.split(' ')
          : null,
        { [`block--span-${block.columns}`]: block.columns },
      ]},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",attrs:{"data":{ ...block, sectionLayout: section.layout }}})],1)}),0)}),_c('div',{staticClass:"product__files"},[(_vm.post.acf.product_sheet)?_c('a',{attrs:{"href":_vm.post.acf.product_sheet.url}},[_c('span',{staticClass:"product__files--underlined"},[_vm._v("Product Sheet")]),_c('span',{staticClass:"arrowdown"})]):_vm._e(),(_vm.post.acf.cad_file)?_c('a',{attrs:{"target":"_blank","href":_vm.post.acf.cad_file.url}},[_c('span',{staticClass:"product__files--underlined"},[_vm._v("CAD File")]),_c('span',{staticClass:"arrowdown"})]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }