<template>
  <Spacer
    :use-margin="true"
    class="cartpage"
  >
    <Wrapper
      v-if="!firstLoading"
      boxed
    >
      <Grid
        col="12"
        row-gap="m"
      >
        <!-- <div class="cart__label t-center typo--m">
          {{ $labels.your_cart }}
        </div> -->
        <!-- Cart -->
        <Wrapper
          size="s"
        >
          <Spacer
            y="l"
            class="block-theme--default"
          >
            <Spacer
              class="empty-face typo--l t-center"
              bottom="ml"
            >
              ༼つ ◕_◕ ༽つ
            </Spacer>
            <div class="t-center">
              <Spacer
                top="m"
                bottom="m"
              >
                {{ $labels.page_not_found }}
              </Spacer>
            </div>
            <Flex justify="center">
              <Cta
                theme="button"
                :data="{
                  title: $labels.go_to_home,
                  url: '/',
                }"
              />
            </Flex>
          </Spacer>
        </Wrapper>
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  name: 'NotFound',

  components: {
    Cta,
  },
};
</script>
