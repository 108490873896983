<template>
    <Grid
      col="12"
      class="block-theme--default block-theme--right"
      style="row-gap: 0;"
    >
      <Grid
        col="12"
        class="block-theme--default"
        align="start"
      >
        <CartTotals theme="checkout" />
      </Grid>
      <Flex justify="end" dir="column" row-gap="m">
        <Checkbox
        v-model="terms.model"
        :data="terms"
        :model.sync="terms.model"
      >
        <label
          slot="label"
          for="terms"
        >{{ $labels[terms.label] }} <sup
          v-html="'*'"
        /></label>
      </Checkbox>
        <Cta
          :data="{
            title: $labels.place_order,
            fn: placeOrder,
          }"
          theme="button-boxed"
        />
      </Flex>
    </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {
  parseCurrency,
  unparseCurrency,
  exclVatLabel,
} from '@/assets/js/utils-wc';

import Checkbox from '@/components/form/fields/checkbox';
import CartTotals from '@/components/wc/cart-totals';
import CartList from '@/components/wc/cart-list';
import Cta from '@/components/typo/cta';

export default {
  name: 'CheckoutOrder',
  components: {
    CartTotals,
    CartList,
    Checkbox,
    Cta,
  },
  data() {
    return {
      acceptTerms: false,
      invalid: true,
      invalidFromTerms: true,
      invalidFromForm: true,
      // products: [],
      showMobileModal: false,
      terms: {
        id: 'terms',
        type: 'checkbox',
        checked: false,
        model: false,
        label: 'terms',
        fn: this.setAcceptTerms,
      },
    };
  },
  computed: {
    ...mapGetters(['cart', 'wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
  },
  watch: {
    invalidFromTerms(val) {
      if (!val && !this.invalidFromForm) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    invalidFromForm(val) {
      if (!val && !this.invalidFromTerms) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    showMobileModal(value) {
      if (value) {
        disableBodyScroll(document.querySelector('html'));
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
  async mounted() {
    this.$bus.$on('validForm', this.formInvalid);
    // const request = {
    //   type: 'product',
    //   params: {
    //     // include: [this.cart.items.map((x) => x.product_id)],
    //     per_page: 100,
    //     offset: 0,
    //     lang: this.$store.state.lang,
    //   },
    // };
    // this.products = await this.$store.dispatch('getItems', request);
  },
  beforeDestroy() {
    this.$bus.$off('validForm', this.formInvalid);
  },
  methods: {
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal)
            + this.unparseCurrency(cartItem.line_subtotal_tax),
        );
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal),
        )}`;
      }
      return this.parseCurrency(
        this.unparseCurrency(cartItem.line_subtotal)
          + this.unparseCurrency(cartItem.line_subtotal_tax),
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    placeOrder() {
      this.$bus.$emit('submitForm', 'checkout');
    },
    setAcceptTerms() {
      this.acceptTerms = !this.acceptTerms;
      this.invalidFromTerms = !this.acceptTerms;
    },
    formInvalid(data) {
      if (data && data.id === 'checkout') {
        this.invalidFromForm = data.invalid;
      }
    },
    swipeHandlerMobileModal(dir) {
      if (dir === 'top') {
        this.showMobileModal = true;
      }
      if (dir === 'bottom') {
        this.showMobileModal = false;
      }
    },
  },
};
</script>
