<template>
  <div id="app">
    <div class="wrapper-app">
      <AppHeader />
      <transition :css="false" appear mode="out-in" @enter="enter" @leave="leave">
        <router-view :key="filteredPath" />
      </transition>
    </div>
    <AppFooter />

    <Modal />
    <Snackbar />
    <Loader />
    <Cart />
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';

import Cart from '@/components/wc/cart';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Modal,
    Cookie,
    Loader,
    Snackbar,
    Cart,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'options']),
    filteredPath() {
      let { path } = this.$route;
      if (this.$route.name === 'SingleGroupedProduct') {
        const paths = this.$route.path.split('/');
        paths.pop();
        path = `${paths.join('/')}/`;
      }

      if (
        this.$route.params.slug === 'works'
        || this.$route.meta.slug === 'works'
      ) {
        return 'works';
      }
      return path;
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

#loader {
  background: white;
  display: block;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.app-loaded {
  #loader {
    display: none;
  }
}

#app {
  background: var(--white);
}

.wrapper-app {
  background: var(--white);
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);

  main {
    flex: 1 0 0;
  }
}
</style>
