<template>
  <div class="field field--number">
    <slot
      name="label"
      class="field-label"
    />
    <div class="field-input">
      <Icon
        name="minus"
        theme="circle"
        size="xs"
        class="button-minus"
        :fn="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue-1]) : false"
      />
      <!-- <div
        name="minus"
        theme="circle"
        size="xs"
        :fn="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue-1]) : false"
      >
        –
      </div> -->

      <input
        :id="data.id || $vnode.key"
        v-model.trim="computedValue"
        :data-key="data.itemKey"
        :name="data.id || $vnode.key"
        type="number"
        :placeholder="data.placeholder"
        :readonly="data.readonly"
        @change="data.fn ? data.fn($event) : false"
      >
      <!-- + -->
      <Icon
        name="plus"
        theme="circle"
        size="xs"
        class="button-plus"

        :fn="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue+1]) : false"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Input',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    computedValue: {
      get() {
        return Number(this.value);
      },
      set(val) {
        this.$emit('update:value', Number(val));
      },
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">
.field--number{
  input{
  font-weight: 800;
  font-size: 14px!important;
  }
}
.button-minus, .button-plus{
  width: fit-content;
  // transform: translateY(-1px);
  svg{
    display: none;
  }
}
.button-plus{
    transform: translateY(-1px), translateX(-1px);

}
.button-minus:before{
    content: "–";
  }
  .button-plus:before{
    content: "+";
  }

</style>
