<template>
  <div
    :class="[
      'cta',
      `cta-theme--${theme}`,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'cta-disabled' : null,
      {'android': isAndroid }
    ]"
  >
    <template v-if="data.fn">
      <button @click="!disabled ? data.fn() : null">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="modal">
      <button @click="openModal">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button @click="goTo">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
        :download="download_name"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else-if="email">
      <a
        :href="`mailto:${data.url}`"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link :to="url">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    download_name: {
      type: String,
      default: 'NM3 download',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
        && typeof this.data.url === 'string'
        && process.env.VUE_APP_BASE_URL
        && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '');
      }
      return this.data.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    email() {
      return (
        this.url && typeof this.url === 'string' && this.url.includes('@')
      );
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
        && typeof this.url === 'string'
        && this.url.startsWith('#modal-')
      );
    },
    isAndroid() {
      const regex = /Android/i;
      return regex.test(navigator.userAgent);
    }
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: block;
}
.router-link-active {
  border-bottom: 1px solid black;
}
.site-name {
  .router-link-active {
    border-bottom: initial;
  }
}

.cta-disabled {
  // cursor: progress;
  opacity: 0.5;

  * {
    // cursor: progress !important;
    pointer-events: none !important;
  }
}

.cta {
  transition: opacity 0.2s ease;

  
  &:hover {
    opacity: 0.4;
  }
  &.cta-theme--button-boxed:hover {
    opacity: 1;
  }
  &.cta-theme--button-boxed-light:hover {
    opacity: 1;
  }
  
  a,
  button {
    font-family: $sans;
    display: block;
    line-height: 1;
    font-size: inherit;
    font-weight: bold;
  }
  &-theme {
    &--warning {
      color: var(--warning-color);
    }
    &--login {
      a,
      button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        width: 100%;
        column-gap: var(--spacer-m);
        border-radius: var(--border-radius);
        border: var(--line) solid var(--grey-d);
        padding: var(--spacer-xs) var(--spacer-m) var(--spacer-s) var(--spacer-m);

        line-height: normal;

        @include mq($and: $hover) {
          &:hover {
            background: var(--white);
          }
        }
      }
    }
    &--button-boxed {
      a,
      button {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        background-color: var(--black);
        color: var(--white);
        padding: var(--spacer-xs) var(--spacer-m) var(--spacer-s) var(--spacer-m);
        line-height: normal;

        @include mq($and: $hover) {
          &:hover {
            background: var(--grey);
          }
        }
      }
    }

    &--button-boxed-light {
      a,
      button {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        background-color: var(--white);
        color: var(--black);
        border: 1px solid var(--black);
        padding: var(--spacer-xs) var(--spacer-m) var(--spacer-s) var(--spacer-m);
        line-height: normal;

        @include mq($and: $hover) {
          &:hover {
            background-color: var(--grey);
            border-color: var(--grey);
            color: var(--white);
          }
        }
      }
    }


    &--difference {
      .router-link-active {
        border-bottom: 1px solid white;
      }
    }
    &--filter {
      .router-link-active {
        border-bottom: initial;
      }
      .router-link-exact-active {
        border-bottom: 1px solid black;
      }
    }
  }

  &.android.cta-theme--button-boxed, &.android.cta-theme--button-boxed-light {
    button, a {
    padding: 7px var(--spacer-m) var(--spacer-s) var(--spacer-m);
    }
  }

  &.full-button {
    width: 100%;

    > button, a {
      display: flex;
      justify-content: left;
    }
  }
}
</style>
