import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import {
  calculateShippingMethods,
} from '@/api';

import {
  handleStripePayWithCard,
  handlePaymentAfterOrder,
  createAndStorePaymentIntent,
  addCardToCustomerSavedMethods,
} from '@/assets/js/utils-wc';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'place_order',
        emitGlobal: true,
      },
      groups: {
        billing: {
          visible: true,
          title: this.$labels.billing_address,
          fields: {
            first_name: {
              type: 'text',
              model: '',
              placeholder: 'first_name',
              label: null,
              rules: 'required',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'last_name',
              rules: 'required',
              size: 6,
            },
            company: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'company',
            },
            address_1: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'address_1',
              rules: 'required',
            },
            address_2: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'address_2',
            },
            email: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'email',
              rules: 'required|email',
              size: 6,
            },
            phone: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'phone',
              rules: 'required',
              size: 6,
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              rules: 'required',
              options: {},
              size: 6,
              fn: () => {
                this.setBillingStates();
                this.getShippingMethods();
              },
            },
            state: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'state',
              rules: 'required',
              options: {},
              size: 6,
              fn: this.getShippingMethods,
            },
            city: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'city',
              rules: 'required',
              size: 6,
              fn: this.getShippingMethods,
            },
            postcode: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'postcode',
              rules: 'required',
              size: 6,
              fn: this.getShippingMethods,
            },
            create_account: {
              id: 'create_account',
              type: 'checkbox',
              model: false,
              placeholder: null,
              label: 'create_account',
              rules: '',
              fn: this.toggleCreateAccount,
              hidden: this.user,
            },
            password: {
              type: 'password',
              model: null,
              label: null,
              placeholder: 'password',
              vid: 'password',
              rules: 'required|min:6',
              hidden: !this.createAccount,
              size: 6,
            },
            repeatPassword: {
              type: 'password',
              model: null,
              label: null,
              placeholder: 'repeat_password',
              id: 'repeat_password',
              rules: 'required|confirmed:password',
              vid: 'repeat_password',
              hidden: !this.createAccount,
              size: 6,
            },
          },
        },
        shipping: {
          title: this.$labels.ship_different_address,
          visible: false,
          accordion: {
            open: false,
            toggle: true,
            fn: this.toggleShipToDifferentAddress,
          },
          fields: {
            first_name: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'first_name',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'last_name',
              size: 6,
            },
            company: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'company',
            },
            address_1: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'address_1',
            },
            address_2: {
              type: 'text',
              model: '',
              label: 'address_2_placeholder',
              placeholder: 'address_2',
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              options: {},
              size: 6,
              fn: () => {
                this.setShippingStates();
                this.getShippingMethods();
                // Aggiungere anche check su payment methods eventualmente
              },
            },
            state: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'state',
              size: 6,
              fn: this.getShippingMethods,
            },
            city: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'city',
              size: 6,
              fn: this.getShippingMethods,
            },
            postcode: {
              type: 'text',
              model: '',
              label: null,
              placeholder: 'postcode',
              size: 6,
              fn: this.getShippingMethods,
            },
          },
        },
        shippingMethods: {
          title: this.$labels.shipping_methods,
          accordion: {
            open: true,
            icon: false,
          },
          visible: true,
          fields: {
            shipping_methods: {
              type: 'radio',
              model: null,
              id: 'radio-shipping',
              rules: 'required',
              placeholder: null,
              options: this.shippingMethods,
              fn: this.setShippingMethod,
            },
          },
          emptyState: {
            conditionalField: 'shipping_methods',
            value: this.$labels.shipping_methods_empty_states,
          },
        },
        payments: {
          title: this.$labels.payment_methods,
          // accordion: {
          //   open: true,
          //   icon: false,
          // },
          visible: true,
          fields: {
            payment_method: {
              type: 'radio-payment',
              model: null,
              id: 'radio-payment',
              rules: 'required',
              placeholder: null,
              options: this.paymentGateways,
              fn: this.setPaymentGateway,
            },
          },
        },
        order: {
          title: this.$labels.additional_information,
          accordion: {
            open: false,
          },
          visible: true,
          fields: {
            notes: {
              type: 'textarea',
              model: null,
              placeholder: 'order_notes_placeholder',
            },
          },
        },
      },
      countries: [],
      states: [],

      createAccount: false,
      anUserWasCreated: false,
      shipToDifferentAddress: false,

      notes: null,
      // coupon: null,

      shippingMethods: {},
      setShippingMethodPending: false,
    };
  },
  computed: {
    ...mapGetters(['customer', 'user', 'cart', 'paymentGateways', 'paymentMetas', 'paymentGateway', 'geoData']),
  },
  watch: {
    cart: {
      immediate: true,
      handler(val) {
        if (val) {
          this.cartKey = val.cart_key;
        }
      },
    },
    customer: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.groups.billing.fields.first_name.model = val.billing.first_name ? val.billing.first_name : '';
          this.groups.billing.fields.last_name.model = val.billing.last_name ? val.billing.last_name : '';
          this.groups.billing.fields.company.model = val.billing.company ? val.billing.company : '';
          this.groups.billing.fields.address_1.model = val.billing.address_1 ? val.billing.address_1 : '';
          this.groups.billing.fields.address_2.model = val.billing.address_2 ? val.billing.address_2 : '';
          this.groups.billing.fields.email.model = val.billing.email ? val.billing.email : '';
          this.groups.billing.fields.phone.model = val.billing.phone ? val.billing.phone : '';
          this.groups.billing.fields.country.model = val.billing.country && val.billing.country !== '' ? val.billing.country : '';
          this.groups.billing.fields.city.model = val.billing.city ? val.billing.city : '';
          this.groups.billing.fields.state.model = val.billing.state && val.billing.state !== '' ? val.billing.state : '';
          this.groups.billing.fields.postcode.model = val.billing.postcode ? val.billing.postcode : '';

          // Open accordion when user login
          // Not reactive
          // if (this.customer.shipping) {
          //   this.shipToDifferentAddress = true;
          //   this.groups.shipping.visible = true;
          //   this.groups.shipping.accordion.open = true;
          // }
          setTimeout(() => {
            if (document.querySelector('.form-group[data-form-group="shipping"] .accordion__trigger') && !document.querySelector('.form-group[data-form-group="shipping"] .accordion__trigger').classList.contains('.accordion__trigger--active')) {
              document.querySelector('.form-group[data-form-group="shipping"] .accordion__trigger').click();
            }
          }, 300);

          this.groups.shipping.fields.first_name.model = val.shipping.first_name ? val.shipping.first_name : '';
          this.groups.shipping.fields.last_name.model = val.shipping.last_name ? val.shipping.last_name : '';
          this.groups.shipping.fields.company.model = val.shipping.company ? val.shipping.company : '';
          this.groups.shipping.fields.address_1.model = val.shipping.address_1 ? val.shipping.address_1 : '';
          this.groups.shipping.fields.address_2.model = val.shipping.address_2 ? val.shipping.address_2 : '';
          this.groups.shipping.fields.country.model = val.shipping.country ? val.shipping.country : '';
          this.groups.shipping.fields.city.model = val.shipping.city ? val.shipping.city : '';
          this.groups.shipping.fields.state.model = val.shipping.state ? val.shipping.state : '';
          this.groups.shipping.fields.postcode.model = val.shipping.postcode ? val.shipping.postcode : '';
        }
      },
    },
    user: {
      immediate: true,
      handler(val) {
        if (val) {
          this.groups.billing.fields.create_account.hidden = true;
        } else {
          this.groups.billing.fields.create_account.hidden = false;
        }
      },
    },
    paymentGateways: {
      immediate: true,
      handler(val) {
        if (val) {
          const options = Object.keys(val).map((key) => val[key]);
          Object.assign(this.groups.payments.fields.payment_method, { ...this.groups.payments.fields.payment_method, options });
        }
      },
    },
    // hasPaymentIntent: {
    //   immediate: true,
    //   handler(val) {
    //     if (val === true && this.setShippingMethodPending) {
    //       this.maybeSetChosenShippingMethod();
    //     }
    //   },
    // },
  },
  methods: {
    init() {
      this.countries = this.geoData.countries;
      this.states = this.geoData.states;

      this.groups.shipping.visible = this.shipToDifferentAddress;

      Object.assign(this.groups.billing.fields.country, { ...this.groups.billing.fields.country, options: this.countries, model: this.customer && this.customer.billing ? this.customer.billing.country && this.customer.billing.country !== '' ? this.customer.billing.country : '' : '' });
      Object.assign(this.groups.shipping.fields.country, { ...this.groups.shipping.fields.country, options: this.countries, model: this.customer && this.customer.shipping ? this.customer.shipping.country : '' });
      if (this.groups.billing.fields.country.model) {
        Object.assign(this.groups.billing.fields.state, { ...this.groups.billing.fields.state, options: this.states[this.groups.billing.fields.country.model], model: this.customer && this.customer.billing ? this.customer.billing.state && this.customer.billing.state !== '' ? this.customer.billing.state : '' : '' });
      }
      if (this.groups.shipping.fields.country.model) {
        Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customer && this.customer.shipping ? this.customer.shipping.state : '' });
      }
      Object.assign(this.groups.payments.fields.payment_method, { ...this.groups.payments.fields.payment_method, options: this.paymentGateways });
      this.getShippingMethods();
    },
    setBillingStates() {
      Object.assign(this.groups.billing.fields.state, { ...this.groups.billing.fields.state, options: this.states[this.groups.billing.fields.country.model], model: this.customer && this.customer.billing ? this.customer.billing.state : '' });
    },
    setShippingStates() {
      Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customer && this.customer.shipping ? this.customer.shipping.state : '' });
    },
    toggleCreateAccount() {
      this.createAccount = !this.createAccount;
      this.groups.billing.fields.password.hidden = !this.createAccount;
      this.groups.billing.fields.repeatPassword.hidden = !this.createAccount;
      // Enable save payment method
      if (this.$refs['radio-payment'][0] && this.$refs['radio-payment'][0].save_card) {
        if (this.createAccount) {
          this.$refs['radio-payment'][0].save_card.hidden = false;
        } else {
          this.$refs['radio-payment'][0].save_card.hidden = true;
        }
      }
    },
    async setPaymentGateway(method) {
      const body = {
        gateway_id: method,
      };
      this.$store.dispatch('setPaymentGateway', body);
    },
    maybeSetChosenShippingMethod() {
      if (this.groups.shippingMethods.fields.shipping_methods.options && this.groups.shippingMethods.fields.shipping_methods.options[0]) {
        if (this.groups.shippingMethods.fields.shipping_methods.options.length === 1) {
          const method = this.groups.shippingMethods.fields.shipping_methods.options[0];
          this.groups.shippingMethods.fields.shipping_methods.model = method.id;
          this.setShippingMethod(method.id);
        } else {
          this.groups.shippingMethods.fields.shipping_methods.options.forEach((method) => {
            if (method.chosen_method === true) {
              this.groups.shippingMethods.fields.shipping_methods.model = method.id;
              this.setShippingMethod(method.id);
            }
          });
        }
      }
    },
    async setShippingMethod(method) {
      const body = {
        key: method,
      };
      this.$store.dispatch('setShippingMethod', body);
      this.setShippingMethodPending = false;
    },
    toggleShipToDifferentAddress() {
      this.shipToDifferentAddress = !this.shipToDifferentAddress;
      this.groups.shipping.visible = this.shipToDifferentAddress;
      this.getShippingMethods();

      Object.keys(this.groups.shipping.fields).forEach((key) => {
        if (key !== 'company' && key !== 'address_2') {
          this.groups.shipping.fields[key].rules = this.requiredIf(this.shipToDifferentAddress);
        }
      });
    },
    requiredIf(flag) {
      return flag ? 'required' : null;
    },
    getShippingMethods: debounce(async function getShippingMethods() {
      const key = this.shipToDifferentAddress ? 'shipping' : 'billing';

      const valid = this.groups[key].fields.country.model !== '' && this.groups[key].fields.state.model !== '' && this.groups[key].fields.city.model !== '' && this.groups[key].fields.postcode.model !== '';

      if (valid) {
        const { data: dataShippingMethods, status } = await calculateShippingMethods({
          country: this.groups[key].fields.country.model,
          state: this.groups[key].fields.state.model,
          city: this.groups[key].fields.city.model,
          postcode: this.groups[key].fields.postcode.model,
          return_methods: true,
        });
        // Update cart tax values
        this.$store.dispatch('getCart');
        if (status === 200) {
          this.shippingMethods = dataShippingMethods;
          const options = [];
          const model = null;

          Object.keys(this.shippingMethods).forEach((method) => {
            options.push({
              id: method,
              title: this.shippingMethods[method].html,
              chosen_method: this.shippingMethods[method].chosen_method,
            });
          });

          Object.assign(this.groups.shippingMethods.fields.shipping_methods, { ...this.groups.shippingMethods.fields.shipping_methods, options, model });

          this.maybeSetChosenShippingMethod();
        } else {
          this.$store.commit('SET_SNACKBAR', { active: true, content: dataShippingMethods.message });
        }
      }
    }, 1000),
    async callback() {
      // 1 - Create user if selected
      if (this.createAccount && !this.anUserWasCreated) {
        const user = await this.$store.dispatch('simpleCreateUser', {
          email: this.groups.billing.fields.email.model,
          password: this.groups.billing.fields.password.model,
          first_name: this.groups.billing.fields.first_name.model,
          last_name: this.groups.billing.fields.last_name.model,
        });
        if (typeof user !== 'number') {
          return;
        }
        this.anUserWasCreated = true;
      }

      const body = {
        billing: {
          first_name: this.groups.billing.fields.first_name.model,
          last_name: this.groups.billing.fields.last_name.model,
          company: this.groups.billing.fields.company.model,
          address_1: this.groups.billing.fields.address_1.model,
          address_2: this.groups.billing.fields.address_2.model,
          email: this.groups.billing.fields.email.model,
          phone: this.groups.billing.fields.phone.model,
          country: this.groups.billing.fields.country.model,
          city: this.groups.billing.fields.city.model,
          state: this.groups.billing.fields.state.model,
          postcode: this.groups.billing.fields.postcode.model,
        },
        shipping: this.shipToDifferentAddress ? {
          first_name: this.groups.shipping.fields.first_name.model,
          last_name: this.groups.shipping.fields.last_name.model,
          company: this.groups.shipping.fields.company.model,
          address_1: this.groups.shipping.fields.address_1.model,
          address_2: this.groups.shipping.fields.address_2.model,
          country: this.groups.shipping.fields.country.model,
          city: this.groups.shipping.fields.city.model,
          state: this.groups.shipping.fields.state.model,
          postcode: this.groups.shipping.fields.postcode.model,
        } : null,
        create_account: this.createAccount,
        password: this.createAccount ? this.groups.billing.fields.password.model : undefined,
        // save_card: this.groups.payments.fields.save_card.model,
        order_notes: {
          note: this.groups.order.fields.notes.model,
        },
        metas: {},
      };

      let addCardResult = null;
      if (this.paymentGateway === 'stripe') {
        await createAndStorePaymentIntent(this.$store);

        const newAccountCredentials = this.createAccount ? {
          email: this.groups.billing.fields.email.model,
          password: this.groups.billing.fields.password.model,
        } : undefined;
        if (this.$refs['radio-payment'][0].save_card.model && ((this.$refs['radio-payment'] && this.$refs['radio-payment'][0] && this.$refs['radio-payment'][0].saved_cards.model === 'new') || this.$refs['radio-payment'][0].saved_cards.model === null)) {
          // 2 - Save card if request
          addCardResult = await addCardToCustomerSavedMethods(this.$store, {
            stripeInstance: this.paymentMetas.stripe,
            newAccountCredentials,
          });
        }

        const stripeSource = addCardResult ? addCardResult.token
          : this.$refs['radio-payment'][0].saved_cards.model !== 'new' ? this.$refs['radio-payment'][0].saved_cards.model : null;
        body.metas.stripe = {
          intent_id: this.paymentMetas.stripe.clientSecret.split('_secret_')[0],
          source: stripeSource != null ? stripeSource : undefined,
        };
      }

      // 3 - Create order
      const data = await this.$store.dispatch('createOrder', body);

      if (data) {
        if (this.paymentGateway === 'stripe') {
          const stripeSource = addCardResult ? addCardResult.token
            : this.$refs['radio-payment'][0].saved_cards.model !== 'new' ? this.$refs['radio-payment'][0].saved_cards.model : null;
          // 4 - Pay
          const paymentResult = await handleStripePayWithCard(this.$store, { paymentMetas: this.paymentMetas, source: stripeSource }, this.paymentGateway);
          if (!paymentResult) {
            // se fallisco il pagamento, ricreo un nuovo intent id per mantenere traccia del fallimento sul PI attuale
            // await createAndStorePaymentIntent(this.$store);
            return;
          }
        }
        // Payment to be processed after creating order
        const paymentResultAfterOrder = handlePaymentAfterOrder(this.$store, data, this.paymentGateway);
        if (paymentResultAfterOrder) {
          this.$store.dispatch('clearCart');
          this.$router.push({
            name: 'ThankYou',
            params: {
              orderId: data.order.id,
            },
            query: {
              key: data.order.order_key || data.order.key,
            },
          });
        }
      }
    },
  },
};
