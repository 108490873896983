<template>
  <Spacer v-if="!$route.meta.hideFooter" v-show="showFooter" id="footer" x="m" top="l" bottom="m">
    <footer class="page-footer footer">
      <div v-if="options && footer && footer.description" v-html="footer.description" />
      <!-- {{ footer }} -->
      <div v-if="options && footer && footer.information">
        <div v-for="(info, indexInfo) in footer.information" :key="indexInfo" :class="info.section_class">
          <Grid>
            <div v-for="(item, index) in info.section" :key="index" class="footer__item">
              <!-- {{ item.content }} -->
              <!--  <Label
              v-if="item.label"
              :data="item.label"
            />-->
              <Richtext v-if="item.content" :data="{ value: index === 0 ? parseContent(item.content) : item.content }" />

              <!-- <Cta
              v-if="item.content"
              :data="item.content"
            />-->
            </div>
          </Grid>
        </div>
      </div>
    </footer>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

// import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';
// import Cta from '@/components/typo/cta';

export default {
  name: 'Footer',
  components: {
    // Label,
    Richtext,
    // Cta,
  },
  computed: {
    ...mapGetters(['options', 'currentPost']),
    showFooter() {
      if (this.$route.meta.slug === 'works') {
        return true;
      }
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
  methods: {
    parseContent(content) {
      return content.replace('{currentYear}', new Date().getFullYear());
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  &__item {
    grid-column: span 6;
    align-self: end;
    height: 100%;

    @include mq(xs) {
      grid-column: span 6;
    }

    @include mq(m) {
      grid-column: span 2;
    }
  }

  ::v-deep {
    .wysiwyg p a {
      transition: opacity 0.2s ease;
      border: 0;

      &:hover {
        opacity: 0.4;
      }
    }
  }
}
</style>
