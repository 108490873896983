import Vue from "vue";

const labels = {
  account: "Account",
  account_created: "The account has been created successfully.<br>Now you can login.",
  account_details: "Account details",
  account_number: "Account number",
  actions: "Actions",
  add: "Add",
  add_coupon: "Apply",
  add_new_card: "Add new card",
  add_to_cart: "Add to cart",
  added: "Added",
  additional_information: "Additional information",
  address_1: "Address",
  address_2: "Address details",
  address_2_placeholder: "Apartment, suit, unit, etc.",
  addresses: "Addresses",
  all_cta: "All",
  available: "available",
  back_to_cart: "Back to cart",
  bank: "Bank",
  bic_swift: "BIC/SWIFT",
  billing_address: "Billing Address",
  cancel_card: "Delete card",
  cancel_card_dialog: "Are you sure you want to delete this card?",
  cancel_order: "Cancel order",
  cancel_order_dialog: "Are you sure you want to cancel this order?",
  cannot_pay_for_order: "You cannot pay for this order",
  cart: "Cart",
  view_cart: "View cart",
  cart_added_message: "The product was successfully added to your cart",
  checkout: "Checkout",
  choose_option: "Choose an option",
  city: "City",
  close: "Close",
  company: "Company",
  country: "Country",
  coupon: "Coupon",
  create_account: "Create account",
  create_accunt: "Create account",
  date: "Date",
  delete: "Delete",
  details: "Details",
  discount: "Discount",
  display_name: "Display name",
  do_you_have_account: "Do you have an account?",
  edit: "Edit",
  email: "Email",
  empty_cart: "Empty Cart",
  ending_in: "ending in",
  error_confirmed_after: "",
  error_confirmed_pre: "The field must be the same of",
  error_email: "The email is not valid",
  error_generic: "An error occurred",
  error_generic_customer: "Cannot get customer data.",
  error_generic_login: "Something went wrong during the login. You should login again.",
  error_min_after: "characters",
  error_min_pre: "The field must have at least",
  error_required: "The field is required",
  error_user_addresses_update: "A problem occurred while saving the data.",
  error_user_update:
    "A problem occurred while saving the data. <br>Please check them before retrying.",
  excluded_vat: "ex. VAT",
  excluded_vat_extended: "excluded VAT",
  expires: "expires",
  first_name: "First name",
  future_payments: "Future payments",
  go_to: "Go to",
  go_to_checkout: "Proceed to checkout",
  go_to_home: "Back to home",
  go_to_orders: "Go to your orders",
  go_to_payment: "Proceed to payment",
  go_to_shop: "Go back to shop",
  hide_order_summary: "Hide order summary",
  iban: "IBAN",
  id: "ID",
  insert_coupon: "Insert your coupon",
  last_name: "Last name",
  logged_in_with_mail: "You're logged with the e-mail",
  login: "Login",
  logout: "Logout",
  lost_password: "Forgot your password?",
  make_default: "Make default",
  may_be_interested_in: "You may  be interested in...",
  my_account: "My account",
  no_order: "No order has been placed yet.",
  no_saved_card: "You have no saved cards",
  old_password: "Old password",
  order: "Order",
  order_cancelled: "Your order was cancelled",
  order_details: "Order details",
  order_failed:
    "Unfortunately your order cannot be processed as the originating bank/merchant has declined your transaction. Please attempt your purchase again",
  order_infos: "Order information",
  order_notes: "Order notes",
  order_notes_placeholder: "Need a specific delivery day? Sending a gift? ",
  order_received: "Your order has been received",
  order_updated: "Your order was updated",
  order_updates: "Order updates",
  orders: "Orders",
  out_of_stock: "Out of stock",
  page_not_found: "Page not found",
  password: "Password",
  password_placeholder: "Minimum 6 characters",
  pay_for_order: "Pay for order",
  payment: "Payment",
  payment_method: "Payment method",
  payment_methods: "Payment methods",
  phone: "Phone",
  place_order: "Place Order",
  postcode: "Postcode / ZIP",
  product: "Product",
  products: "Products",
  profile: "Profile",
  refunded: "Refunded",
  register: "Create an account",
  remove: "Remove",
  repeat_password: "Repeat password",
  save: "Save",
  save_card: "Save card for future payments",
  saved_cards: "Saved cards",
  saved_changes: "Changes have been saved",
  select_option: "Select option",
  send: "Send",
  ship_different_address: "Ship to a different address?",
  shipping: "Shipping",
  shipping_address: "Shipping Address",
  shipping_exstimated: "Estimated shipping",
  shipping_method: "Shipping method",
  shipping_methods: "Shipping methods",
  shipping_methods_empty_states:
    "Please enter your address to know the available shipping methods.",
  shop: "shop",
  show_order_summary: "Show order summary",
  state: "State / Country",
  status: "Status",
  subtotal: "Subtotal",
  terms: "I agree with your terms and conditions.",
  thank_you: "Thank you for your order!",
  thank_you_error: "There is a problem with your order...",
  title_coupon: "Do you have a discount code?",
  total: "Total",
  totals: "Totals",
  use_new_card: "Use a new credit card",
  username_email: "E-mail",
  vat: "VAT",
  vat_extimated: "Estimated VAT",
  welcome_back: "Welcome back",
  wrong_login_data: "Incorrect data. <br>Please try again or reset your password.",
  wrong_mail: "This email is not registered in this website.",
  your_cart: "Your cart",
  your_cart_empty: "Your cart is currently empty",
  payment_shipping: "Payment and shipping",
  terms_conditions: "Terms and conditions",
  care_maintenance: "Care and Maintenance",
  contacts: "Contacts",
  view_catalog: "View Catalogue",
  view_product: "View Product",
  contact_us: "Contact us",
};

labels.install = () => {
  Vue.prototype.$labels = labels;
};

Vue.use(labels);

export default labels;
