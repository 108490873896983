var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['thumb']},[_c('router-link',{staticClass:"thumb__link",attrs:{"to":_vm.data.link || _vm.data.permalink}},[(_vm.data.gds_featured_image)?_c('Media',{staticClass:"media thumb__image",attrs:{"data":{
        type: 'image',
        image: _vm.data.gds_featured_image,
        ratio: _vm.ratio,
        lazyload: true,
        object_fit: 'cover',
      }}}):_vm._e()],1),_c('div',{staticClass:"thumb__text"},[_c('router-link',{staticClass:"thumb__link",attrs:{"to":_vm.data.link || _vm.data.permalink}},[_c('Flex',{attrs:{"dir":"row","column-gap":"m"}},[_c('div',{staticClass:"thumb__title-container"},[_c('Title',{attrs:{"data":{
            value: _vm.data.taxonomy || _vm.data.name ? _vm.data.name : _vm.data.title.rendered,
            size:'xs'
          }}})],1),_vm._l((_vm.data.gds_taxonomies.categories),function(category){return _c('div',[(category.name !== 'Uncategorized')?_c('div',{staticClass:"typo--xs"},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e()])})],2),_c('Spacer',{attrs:{"top":"xxs"}},[(_vm.data.excerpt)?_c('Richtext',{staticClass:"thumb__description",attrs:{"data":{ value: _vm.data.excerpt.rendered, size: 'l' }}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }