/* eslint-disable no-undef */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Page from "@/views/Page.vue";
import Search from "@/views/Search.vue";

import Single from "@/views/Single.vue";
import SingleWork from "@/views/SingleWork.vue";
import NotFound from "@/views/404.vue";
import Preview from "@/views/Preview.vue";
import SingleProject from "@/views/SingleProject.vue";
import wcRoutes from "./wc";

const importedRoutes = wcRoutes;

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set("post", Single);
singles.set("project", SingleProject);
singles.set("work", SingleWork || Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

let langs = null;

if (i18n) {
  store.commit("ADD_LANGUAGES", Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join("|");
} else {
  store.commit("ADD_LANGUAGES", ["default"]);
}

const baseRoutes = [
  {
    path: langs ? `/:lang(${langs})?/404` : "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: langs ? `/:lang(${langs})?/` : "/",
    name: "Index",
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? "pages" : "posts",
    },
  },
  {
    path: langs ? `/:lang(${langs})?/search` : "/search",
    name: "Search",
    component: Search,
  },
  {
    path: langs ? `/:lang(${langs})?/:slug` : "/:slug",
    name: "Page",
    component: Page,
  },
  {
    path: langs ? `/:lang(${langs})?/works/category/:slug` : "/works/category/:slug",
    name: "SubPage",
    component: Page,
    meta: {
      slug: "works",
    },
  },
  {
    path: langs ? `/:lang(${langs})?/preview/:type/:id` : "/preview/:type/:id",
    name: "Preview",
    component: Preview,
  },
];

const routes = importedRoutes.concat(baseRoutes);

Object.keys(postTypes).forEach((key) => {
  if (key !== "page" && key !== "product") {
    routes.push({
      path: langs ? `/:lang(${langs})?/${postTypes[key].rewrite.slug}/:slug` : `/${postTypes[key].rewrite.slug}/:slug`,
      name: singles.get(key).name,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (from.name === "SingleGroupedProduct" && to.name === "SingleGroupedProduct") {
      return false;
    }
    if (from.path !== to.path) {
      return { x: 0, y: 0 };
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.fetchCountry) {
    await store.dispatch("getCountries");
  }

  if (from.path !== to.path) {
    store.commit("SET_CURRENT_POST", null);
  }

  if (to.meta.customView || to.name === "404" || to.name === "Search" || to.name === "Preview") {
    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains("app-loaded")) {
      document.body.classList.add("app-loaded");
    }
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang ? to.params.lang : i18n ? i18n.default : "default";

  store.commit("SET_LANG", lang);
  const request = {
    type: to.meta.type || "pages",
    slug,
    lang,
  };

  if (to.name === "SingleGroupedProduct") {
    const paths = to.path.split("/");
    request.slug = paths[paths.length - 2];
  }

  const promises = [];
  promises.push(store.dispatch("getSingleBySlug", request));

  if (to.params.slug === "works" || to.meta.slug === "works") {
    promises.push(
      store.dispatch("getItems", {
        type: "categories",
        params: {
          lang,
          hide_empty: true,
        },
      })
    );
  }

  Promise.all(promises).then((res) => {
    if (!document.body.classList.contains("app-loaded")) {
      document.body.classList.add("app-loaded");
    }

    const page = res[0];

    if (page && slug) {
      if (to.path === "/") {
        document.title = `${siteTitle} — ${description}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement("p");
        p.innerHTML = page.title ? page.title.rendered : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace("_", "-");
      }

      next();
    } else {
      router.push({ name: "404" });
    }
  });
});

export default router;
